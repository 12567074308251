import { takeLatest } from "redux-saga/effects";
import {
  GetCardDetails,
  AddCardDetails,
  EditCardDetails,
  RemoveCardDetail,
  GetInvoiceList,
  GetSingleInvoiceData,
  GetMakepayment,
  GetPaymentHistory,
} from "../../StateManagement/Reducers/BillingState";

import {
  CardDetailsApi,
  AddCardDetailApi,
  EditCardDetailApi,
  RemoveCardDetailApi,
  InvoiceListApi,
  OneInvoiceListApi,
  MakePaymentApi,
  PaymentHistoryApi,
} from "./BillingSagaWorkers";

export function* CardDetails() {
  yield takeLatest(GetCardDetails.type, CardDetailsApi);
}

export function* AddCardDetail() {
  yield takeLatest(AddCardDetails.type, AddCardDetailApi);
}

export function* EditCardDetail() {
  yield takeLatest(EditCardDetails.type, EditCardDetailApi);
}

export function* RemoveCardDetails() {
  yield takeLatest(RemoveCardDetail.type, RemoveCardDetailApi);
}

export function* InvoiceList() {
  yield takeLatest(GetInvoiceList.type, InvoiceListApi);
}

export function* OneInvoiceList() {
  yield takeLatest(GetSingleInvoiceData.type, OneInvoiceListApi);
}

export function* MakePayment() {
  yield takeLatest(GetMakepayment.type, MakePaymentApi);
}

export function* PaymentHistory() {
  yield takeLatest(GetPaymentHistory.type, PaymentHistoryApi);
}
