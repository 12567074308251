import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetMedication } from "../../../StateManagement/Reducers/DashboardState";

import EmptyData from "../../StyledComponents/EmptyData";

import { Div, PageTitle, Button, Image, Text } from "../../StyledComponents";
import { CardBody, MedicineList, MedicineDosage } from "./styles";

import { IoMdArrowForward } from "react-icons/io";
import pillIcon from "../../../Assets/images/pill_Icon.png";

const Medication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MedicationDetails = useSelector((state) => state.Dashboard.Medication);

  useEffect(() => {
    dispatch(GetMedication("medication"));
  }, [dispatch]);

  return (
    <Div
      width="100%"
      height="auto"
      backColor="#ffffff"
      padding="20px 20px"
      borderRadius="8px"
      border="1px solid #d8e0f0"
      gridArea="Medication"
    >
      <Div display="flex" margin="0px 0px 20px 0px">
        <PageTitle fontSize="24px">Medication</PageTitle>
        <Button
          background="#fafafa"
          borderRadius="90px"
          height="33px"
          border="1px solid #fafafa"
          marginLeft="auto"
          padding="6px 16px 8px 16px"
          color="#667085"
          fontSize="14px"
          lineHeight="19px"
          onClick={() => {
            navigate("/HealthProfile", {
              state: { type: { type: "medication" } },
            });
          }}
        >
          View All
          <IoMdArrowForward
            viewBox="-120 -100 512 512"
            width="16"
            height="16"
          />
        </Button>
      </Div>

      <>
        <CardBody>
          {MedicationDetails.length !== 0 ? (
            <>
              {MedicationDetails.slice(0, 4).map((details, index) => (
                <MedicineList key={index}>
                  <Div
                    width="41px"
                    height="41px"
                    borderRadius="50%"
                    border="1px solid #d8e0f0"
                  >
                    <Image
                      loading="lazy"
                      width="19px"
                      height="19px"
                      margin="10px 0px 0px 10px"
                      src={pillIcon}
                    />
                  </Div>
                  <Text
                    color="#263446"
                    marginLeft="12px"
                    textAlign="left"
                    width="auto"
                    mt="-4px"
                    mb="3px"
                  >
                    {details.title}
                    <MedicineDosage color>
                      {details.Dispensequantity} Pill(s), Daliy
                    </MedicineDosage>
                  </Text>
                </MedicineList>
              ))}
            </>
          ) : (
            <>
              <Div flexCenterAll>
                <EmptyData />
              </Div>
            </>
          )}
        </CardBody>
      </>
    </Div>
  );
};

export default Medication;
