import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { RemoveCardDetail } from "../../../StateManagement/Reducers/BillingState";

import { Modal, ModalContainer, ModalBody } from "../../StyledComponents";
import { ModalDialog } from "../AddCard/Styles";
import { RmModButton, ModalCntDiv, Btnfields } from "./Styled";

const RemoveCard = (props) => {
  const dispatch = useDispatch();

  const [ModalAnimat] = useState(true);
  const [btnDisable, setBtnDis] = useState(false);
  const RemoveCardDetails = () => {
    setBtnDis(true);
    setTimeout(() => {
      setBtnDis(false);
    }, 1500);
    dispatch(RemoveCardDetail(props.card_id));
    props.ModalHide();
  };

  return (
    <Modal show={props.show}>
      <ModalContainer justifyContent="center">
        <ModalDialog width="367px" animation={ModalAnimat}>
          <ModalBody padding="20px">
            <ModalCntDiv>
              Duis aute velit aliquip laborum duis culpa occaecat aliquip sit
              irure esse sunt laboris.
            </ModalCntDiv>
            <ModalCntDiv>
              Duis aute velit aliquip laborum duis culpa occaecat aliquip sit
              irure esse sunt laboris.
            </ModalCntDiv>
            <ModalCntDiv>
              Duis aute velit aliquip laborum duis culpa occaecat aliquip sit
              irure esse sunt laboris.
            </ModalCntDiv>
            <Btnfields display="flex">
              <RmModButton
                color="#2C7BE5"
                background="#FFF"
                hoverBackColor="rgba(244, 246, 249, 0.75)"
                border="1px solid #2C7BE5"
                onClick={() => props.ModalHide()}
                className="close_active"
                id="crDet_rmCrd-btn"
              >
                Cancel
              </RmModButton>
              <RmModButton
                background="#2C7BE5"
                marginLeft="16px"
                className="save_active"
                hoverBackColor="#005FB2"
                disabled={btnDisable}
                onClick={() => RemoveCardDetails()}
              >
                Confirm
              </RmModButton>
            </Btnfields>
          </ModalBody>
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default RemoveCard;
