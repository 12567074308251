import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetPortalEnable } from "../../../StateManagement/Reducers/PreLoginState";

import WelcomeRight from "../WelcomeRight";
import LoginLeft from "../LoginLeft";
import AccessDenied from "../AccessDenied";

import { Div } from "../Styles";
import { Container } from "../StyledComponents";

const Welcome = () => {
  const dispatch = useDispatch();
  const PortalEnable = useSelector((state) => state.PreLogin.PortalEnable);

  useEffect(() => {
    dispatch(GetPortalEnable());
  }, []);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      {PortalEnable === 1 ? (
        <>
          <Div width="50%" bg_color="#F8FAFB" position="relative">
            <LoginLeft />
          </Div>
          <Div width="50%" bg_color="#FFFFFF">
            <WelcomeRight />
          </Div>
        </>
      ) : (
        PortalEnable === 0 && <AccessDenied />
      )}
    </Container>
  );
};

export default Welcome;
