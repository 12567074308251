import { takeLatest } from "redux-saga/effects";
import {
  GetMsgList,
  getMessagerList,
  getMessagerSend,
  getPinchat,
  getPreviewDownload,
  getUnreadCount,
} from "../../StateManagement/Reducers/MessengerState";

import {
  MessageList,
  MessageSend,
  Pinchatworker,
  PreviewDownloadApi,
  ProviderList,
  UnreadCountUpdate,
} from "./MessangerSagaWorkers";

export function* ProviderListSaga() {
  yield takeLatest(GetMsgList.type, ProviderList);
}

export function* MessagerListSaga() {
  yield takeLatest(getMessagerList.type, MessageList);
}

export function* MessageSendSaga() {
  yield takeLatest(getMessagerSend.type, MessageSend);
}

export function* PreviewDownloadSagaM() {
  yield takeLatest(getPreviewDownload.type, PreviewDownloadApi);
}

export function* UnreadCountUpdateSaga() {
  yield takeLatest(getUnreadCount.type, UnreadCountUpdate);
}

export function* PinchatSaga() {
  yield takeLatest(getPinchat.type, Pinchatworker);
}
