import axiosInst from "../../Config";

import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const ListsApi = async (Type) =>
  await axiosInst.get(
    "/vozoportal/patient/active?pid=" + Pat_ID + "&type=" + Type
  );
