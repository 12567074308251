import { takeLatest } from "redux-saga/effects";
import {
  GetHomeWorkLists,
  GetUpdateWatched,
} from "../../StateManagement/Reducers/HomeWorkState";
import { HomeWorkLists, UpdateWatched } from "./HomeWorkSagaWorkers";

export function* HomeWorkSaga() {
  yield takeLatest(GetHomeWorkLists.type, HomeWorkLists);
}
export function* UpdateWatchedSaga() {
  yield takeLatest(GetUpdateWatched.type, UpdateWatched);
}
