import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const upcomingAppointmentsApi = async (text) =>
  await axiosInst.get(
    `/vozoportal/upcomingAppointments?pid=${Pat_ID}&text=${text}`
  );

export const PastAppointment_Api = async () =>
  await axiosInst.get(`/vozoportal/vozopastappointment?pid=${Pat_ID}`);

export const CancelAppointment_Api = async (credential) =>
  await axiosInst.post(`/vozoportal/cancelappt`, credential);

export const providersApi = async (text) =>
  await axiosInst.post(`/vozoportal/providers/list`, text);

export const facilityApi = async (find) =>
  await axiosInst.post(`/vozoportal/facility/list`, find);

export const addAppointmentApi = async (data) =>
  await axiosInst.post(`/vozoportal/add/appointment`, data);

export const EditAppointmentApi = async (data) =>
  await axiosInst.post(
    `/vozoportal/edit/appointment?appid=${data.eid}`,
    data.article
  );

export const AvailablityApi = async (data) =>
  await axiosInst.post(`/vozoportal/get/availablity`, data);

export const PortalAppAvailablityApi = async (data) =>
  await axiosInst.get(`/vozoportal/get/PortalApp/availablity`);

export const OnsiteAppReqApi = async (data) =>
  await axiosInst.get(`/vozoportal/get/Onsite/AppReq?pid=${Pat_ID}`);

export const BillingFacilityApi = async (Data) =>
  await axiosInst.get("/vozo/getbillingfacility");
