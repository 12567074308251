import React, { useState } from "react";
import { useSelector } from "react-redux";

import Posts from "./PaymentHistory";
import Pagination from "../../../StyledComponents/Pagination";

const PaymentHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const PaymentHistory = useSelector((state) => state.Billing.PaymentHistory);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = PaymentHistory.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(PaymentHistory.length / postsPerPage);

  return (
    <>
      <Posts PaymentLists={currentPosts} />
      {currentPosts.length === 0 || howManyPages === 1 ? (
        ""
      ) : (
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      )}
    </>
  );
};

export default PaymentHistory;
