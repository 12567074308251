import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Div,
  Input,
  RedStar,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Span,
  Modal,
} from "../../StyledComponents";
import { ErrorMessage, ModalDialog, Option, Select } from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import CustomSuffixIcon from "./CustomSuffixIcon";
import {
  DeviceResponse,
  GetSingleInsurance,
  postSingleInsDetail,
} from "../../../StateManagement/Reducers/InsuranceState";
import { toast } from "react-toastify";
import Success from "../../StyledComponents/Toastify/Success";
import Error from "../../StyledComponents/Toastify/Error";

const AddInsurance = ({
  showModal,
  setShowModal,
  missingTypes,
  nonMissingTypes,
  openFromAdd,
  setOpenFromAdd,
  setMissingTypes,
  setNonMissingTypes,
  openfromEdit,
  setOpenfromEdit,
  insuranceType,
}) => {
  //--------------------------State and  Variables Start---------------------------- //

  const dispatch = useDispatch();

  const [otheractive, setotheractive] = useState(false);
  const [relationType, setrelationType] = useState("");
  const [inspayer, setinspayer] = useState("");
  const [memberid, setmemberid] = useState("");
  const [planid, setplanid] = useState("");
  const [groupid, setgroupid] = useState("");
  const [copay, setcopay] = useState("");
  const [superbills, setsuperbills] = useState("");
  const [deductible, setdeductible] = useState("");
  const [inspayerphone, setinspayerphone] = useState("");
  const [inspayerfax, setinspayerfax] = useState("");
  const [emplschool, setemplschool] = useState("");
  const [primaryins, setprimaryins] = useState("");
  const [relationship, setRelationship] = useState("");
  const [error, setError] = useState({
    insTypeErr: false,
    insPayerErr: false,
    insInsErr: false,
    insMemberErr: false,
    insPlanErr: false,
    insGroupErr: false,
    relationshipErr: false,
  });

  const InsuranceNames = useSelector((state) => state.Insurance.InsuranceNames);
  const Response = useSelector((state) => state.Insurance.DeviceRes);
  const relationTypes = useSelector((state) => state.Insurance.RelationTypes);

  const SingleInsuranceData = useSelector(
    (state) => state.Insurance.SingleInsuranceData
  );
  const InsType = [
    { id: 1, title: "Primary Insurance", value: "primary" },
    { id: 2, title: "Secondary Insurance", value: "secondary" },
    { id: 3, title: "Tertiary Insurance", value: "tertiary" },
  ];

  //-----------------------------State and  Variables End----------------------------- //
  //-----------------------------Function Start--------------------------------------- //

  const handleClose = () => {
    dispatch(DeviceResponse(""));
    setShowModal(false);
    setOpenFromAdd(false);
    dispatch(GetSingleInsurance({}));
    setOpenfromEdit(false);
    // setMissingTypes([]);
    // setNonMissingTypes([]);
  };
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const insuranceChange = (e, data) => {
    let type = e.target.value;
    // if (type === "Primary Insurance") {
    //   setrelationType("primary");
    // } else if (type === "Secondary Insurance") {
    //   setrelationType("secondary");
    // } else if (type === "Tertiary Insurance") {
    //   setrelationType("tertiary");
    // }
    setrelationType(type);
    setError((prevError) => ({ ...prevError, insTypeErr: false }));
  };

  const inspayChange = (e, data) => {
    setError((prevError) => ({ ...prevError, insPayerErr: false }));

    setinspayer(e.target.value);
  };

  const memberChange = (e) => {
    setError((prevError) => ({ ...prevError, insMemberErr: false }));

    setmemberid(e.target.value);
  };

  const planidChange = (e) => {
    setError((prevError) => ({ ...prevError, insPlanErr: false }));

    setplanid(e.target.value);
  };

  const groupChange = (e) => {
    setError((prevError) => ({ ...prevError, insGroupErr: false }));

    setgroupid(e.target.value);
  };

  const copayChange = (e) => setcopay(e.target.value);

  const supbillChange = (e) => setsuperbills(e.target.value);

  const dedictChange = (e) => setdeductible(e.target.value);

  const priminsChange = (e, data) => {
    setError((prevError) => ({ ...prevError, insInsErr: false }));
    setprimaryins(e.target.value);
    if (e.target.value === "other") {
      setotheractive(true);
    } else {
      setotheractive(false);
    }
  };

  const inspayphoneChange = (e) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      setinspayerphone(e.target.value);
    }
  };

  const inspayfaxChange = (event) => {
    if (!isNaN(event.target.value) && !event.target.value.includes(".")) {
      setinspayerfax(event.target.value);
    }
  };

  const empschlChange = (e) => setemplschool(e.target.value);

  const relationChange = (e) => {
    setError((prevError) => ({ ...prevError, relationshipErr: false }));
    setRelationship(e.target.value);
  };

  const handleClear = () => {
    dispatch(GetSingleInsurance({}));
    if (!openfromEdit) {
      setrelationType("");
    }
    setinspayer("");
    setmemberid("");
    setplanid("");
    setgroupid("");
    setcopay("");
    setsuperbills("");
    setdeductible("");
    setprimaryins("");
    setRelationship("");
    setinspayerfax("");
    setinspayerphone("");
    setotheractive(false);
    setemplschool("");
  };

  const handleSave = () => {
    dispatch(DeviceResponse(""));
    let err = 0;
    if (relationType === "" || relationType === null) {
      setError((prevError) => ({ ...prevError, insTypeErr: true }));
      err = 1;
    }
    if (primaryins === "" || primaryins === null) {
      setError((prevError) => ({ ...prevError, insInsErr: true }));
      err = 1;
    }
    if (inspayer === "" || inspayer === null) {
      setError((prevError) => ({ ...prevError, insPayerErr: true }));
      err = 1;
    }
    if (groupid === "" || groupid === null) {
      setError((prevError) => ({ ...prevError, insGroupErr: true }));
      err = 1;
    }
    if (planid === "" || planid === null) {
      setError((prevError) => ({ ...prevError, insPlanErr: true }));
      err = 1;
    }
    if (memberid === "" || memberid === null) {
      setError((prevError) => ({ ...prevError, insMemberErr: true }));
      err = 1;
    }
    if (
      (relationship === "" || relationship === null) &&
      otheractive === true
    ) {
      setError((prevError) => ({ ...prevError, relationshipErr: true }));
      err = 1;
    }

    if (err > 0) {
      return;
    } else {
      let tempValue;
      if (primaryins === "other" && otheractive === true) {
        tempValue = relationship;
      } else {
        tempValue = primaryins;
      }
      let openType = openFromAdd
        ? "openFromAdd"
        : openfromEdit
        ? "openFromEdit"
        : "";
      const datas = {
        type: relationType,
        provider: inspayer,
        subscriber_relationship: tempValue,
        group_number: groupid,
        member_id: memberid,
        policy_number: planid,
        copay: copay,
        super_bill_payment_to: superbills,
        deductible: deductible,
        payer_phone: inspayerphone,
        payer_fax: inspayerfax,
        subscriber_employer: emplschool,
        openType: openType,
      };
      dispatch(postSingleInsDetail(datas));
      setMissingTypes([]);
      setNonMissingTypes([]);
    }
  };

  //-----------------------------Function End----------------------------------- //

  //-----------------------------UseEffect Start--------------------------------- //
  useEffect(() => {
    if (Response !== "") {
      if (Response.length === 0) {
        toast(<Error msg={"Please do any changes"} />, {
          containerId: "B",
          className: "error_badge",
        });

        setTimeout(() => {
          dispatch(DeviceResponse(""));
        }, 1000);
      } else if (Response === "success") {
        toast(<Success msg={"Datas Sent to the Review Succeedly"} />, {
          containerId: "A",
          className: "success_badge",
        });
        setTimeout(() => {
          setShowModal(false);
          dispatch(DeviceResponse(""));
        }, 1000);
      } else if (Response !== "success") {
        toast(<Error msg={Response} />, {
          containerId: "B",
          className: "error_badge",
        });

        setTimeout(() => {
          dispatch(DeviceResponse(""));
        }, 1000);
      }
    }
  }, [Response]);
  useEffect(() => {
    if (SingleInsuranceData[0]) {
      setrelationType(SingleInsuranceData[0].type);
      setinspayer(SingleInsuranceData[0].provider);
      setmemberid(SingleInsuranceData[0].member_id);
      setplanid(SingleInsuranceData[0].policy_number);
      setgroupid(SingleInsuranceData[0].group_number);
      setcopay(SingleInsuranceData[0].copay);
      setsuperbills(SingleInsuranceData[0].super_bill_payment_to);
      setdeductible(SingleInsuranceData[0].deductible);
      setinspayerphone(SingleInsuranceData[0].payer_phone);
      setinspayerfax(SingleInsuranceData[0].payer_fax);
      if (
        SingleInsuranceData[0].subscriber_relationship !== "self" &&
        SingleInsuranceData[0].subscriber_relationship !== "spouse" &&
        SingleInsuranceData[0].subscriber_relationship !== "parent"
      ) {
        setprimaryins("other");
        setotheractive(true);
        setRelationship(SingleInsuranceData[0].subscriber_relationship);
      } else {
        setprimaryins(SingleInsuranceData[0].subscriber_relationship);
      }

      setemplschool(SingleInsuranceData[0].subscriber_employer);
    }
  }, [SingleInsuranceData]);

  //-----------------------------UseEffect End---------------------------- //

  return (
    <>
      <Modal
        show={showModal}
        justifyContent="center"
        overflowY="auto"
        onClick={handleClose}
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          overflowX="revert"
          overflowY="revert"
          onClick={handleContainerClick}
        >
          <ModalDialog borderRadius="8px" width="555px">
            <ModalHeader padding="19px 21px 22px 23px" borderBottom="none">
              <Div
                color="#000"
                fontFamily="Open Sans, sans-serif"
                fontSize="24px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Insurance Information
              </Div>
              <AiOutlineClose
                onClick={() => {
                  handleClose();
                }}
                style={{
                  marginLeft: "auto",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </ModalHeader>

            <ModalBody
              overflowY="auto"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                paddingTop: "0px",
                overflowX: "hidden",
              }}
            >
              {otheractive && (
                <Div display="flex" marginTop="20px" marginBottom="16px">
                  <Div
                    padding="2px 0px 0px 23px"
                    width="97%"
                    display="flex"
                    flexDirection="column"
                  >
                    <Div
                      fontFamily="'Open Sans',sans-serif"
                      fontWeight="600"
                      fontSize="14px"
                      marginBottom="4px !important"
                      textAlign="left"
                      color="#2E2E2E"
                      lineHeight="normal"
                      position="relative"
                    >
                      Relationship Type<RedStar>*</RedStar>
                    </Div>
                    <Select
                      style={
                        error.relationshipErr ? { border: "1px solid red" } : {}
                      }
                      suffixIcon={<CustomSuffixIcon />}
                      name="inspayer"
                      value={relationship}
                      onChange={(e) => relationChange(e)}
                    >
                      <Option disabled value="">
                        Select Type
                      </Option>
                      {relationTypes.map((s, ind) => (
                        <Option key={`ag${ind}`} value={s.title}>
                          {s.title}
                        </Option>
                      ))}
                    </Select>
                    {error.relationshipErr && (
                      <ErrorMessage>
                        Please Selet the Relationship type
                      </ErrorMessage>
                    )}
                  </Div>{" "}
                </Div>
              )}

              <Div display="flex" marginTop="20px" marginBottom="16px">
                <Div
                  padding="2px 0px 0px 23px"
                  width="49%"
                  display="flex"
                  flexDirection="column"
                  className="ins_form"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Insurance Type<RedStar>*</RedStar>
                  </Div>

                  <Select
                    style={error.insTypeErr ? { border: "1px solid red" } : {}}
                    suffixIcon={<CustomSuffixIcon />}
                    name="inspayer"
                    value={relationType}
                    onChange={(e, data) => insuranceChange(e, data)}
                  >
                    <Option disabled value="">
                      Select Type
                    </Option>
                    {InsType.map((s, ind) => (
                      <Option
                        style={{
                          cursor:
                            openfromEdit && relationType !== s.value
                              ? "not-allowed !important"
                              : "pointer",
                        }}
                        key={`ag${ind}`}
                        value={s.value}
                        disabled={openfromEdit && relationType !== s.value}
                      >
                        {s.title}
                      </Option>
                    ))}
                  </Select>
                  {error.insTypeErr && (
                    <ErrorMessage>Please Selet the Insurance type</ErrorMessage>
                  )}
                  {/* </Form.Group> */}
                </Div>
                {/* )} */}
                <Div
                  padding="2px 0px 0px 23px"
                  width="46%"
                  display="flex"
                  flexDirection="column"
                  className="ins_form"
                  marginLeft="10px"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Insurance Payer<RedStar>*</RedStar>
                  </Div>

                  <Select
                    // className={
                    //   payErr
                    //     ? "arrow-adj w-100 ant-sel-hei30 ant-sel-err-border t-a-l"
                    //     : "arrow-adj w-100 ant-sel-hei30 t-a-l"
                    // }
                    style={error.insPayerErr ? { border: "1px solid red" } : {}}
                    suffixIcon={<CustomSuffixIcon />}
                    name="inspayer"
                    value={inspayer}
                    onChange={(e, data) => inspayChange(e, data)}
                  >
                    <Option disabled value="">
                      Select payer
                    </Option>
                    {InsuranceNames.map((s, ind) => (
                      <Option key={`21${ind}`} value={s.id}>
                        {s.name}
                      </Option>
                    ))}
                  </Select>
                  {error.insPayerErr && (
                    <ErrorMessage>Please Selet the Payer</ErrorMessage>
                  )}
                  {/* </Form.Group> */}
                </Div>
              </Div>
              <Div display="flex" marginBottom="16px">
                <Div
                  padding="2px 0px 0px 23px"
                  width="50%"
                  display="flex"
                  flexDirection="column"
                  className="ins_form"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Primary Insured<RedStar>*</RedStar>
                  </Div>

                  <Select
                    style={error.insInsErr ? { border: "1px solid red" } : {}}
                    suffixIcon={<CustomSuffixIcon />}
                    name="primaryins"
                    value={primaryins}
                    onChange={(e, data) => priminsChange(e, data)}
                  >
                    <Option disabled value="">
                      Select Primary insured
                    </Option>
                    <Option value="self">Self</Option>
                    <Option value="spouse">Client's Spouse</Option>
                    <Option value="parent">Client's Parent</Option>
                    <Option value="other">Other</Option>
                  </Select>
                  {error.insInsErr && (
                    <ErrorMessage>
                      Please Select the Primary insured
                    </ErrorMessage>
                  )}
                  {/* </Form.Group> */}
                </Div>

                <Div
                  padding="2px 23px 0px 0px"
                  display="flex"
                  flexDirection="column"
                  paddingLeft="20px"
                  width="50%"
                  marginLeft="16px"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Member ID<RedStar>*</RedStar>
                  </Div>
                  <Input
                    height="32px"
                    id="memberid"
                    style={{
                      border: error.insMemberErr
                        ? "1px solid red"
                        : "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "101%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      float: "left",
                    }}
                    // border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    autoComplete="off"
                    value={memberid}
                    onChange={(e) => memberChange(e)}
                  />
                  {error.insMemberErr && (
                    <ErrorMessage>Please enter the Member Id</ErrorMessage>
                  )}
                </Div>
              </Div>
              <Div display="flex">
                <Div
                  padding="2px 0px 0px 22px"
                  width="50%"
                  display="flex"
                  flexDirection="column"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Group ID<RedStar>*</RedStar>
                  </Div>
                  <Input
                    height="32px"
                    id="groupid"
                    style={{
                      border: error.insGroupErr
                        ? "1px solid red"
                        : "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      marginLeft: "0",
                      float: "left",
                    }}
                    // border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    autoComplete="off"
                    value={groupid}
                    onChange={(e) => groupChange(e)}
                  />
                  {error.insGroupErr && (
                    <ErrorMessage>Please enter the Group id</ErrorMessage>
                  )}
                </Div>

                <Div
                  padding="2px 23px 0px 0px"
                  display="flex"
                  flexDirection="column"
                  paddingLeft="20px"
                  width="50%"
                  marginLeft="17px"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Plan ID<RedStar>*</RedStar>
                  </Div>
                  <Input
                    height="32px"
                    id="planid"
                    style={{
                      border: error.insPlanErr
                        ? "1px solid red"
                        : "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "101%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      float: "left",
                    }}
                    // border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    autoComplete="off"
                    value={planid}
                    onChange={(e) => planidChange(e)}
                  />
                  {error.insPlanErr && (
                    <ErrorMessage>Please enter the Plan id</ErrorMessage>
                  )}
                </Div>
              </Div>
              <Div display="flex">
                <Div
                  padding="16px 0px 0px 23px"
                  width="50%"
                  display="flex"
                  flexDirection="column"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                  >
                    Copay/Insurance
                  </Div>
                  <Input
                    height="32px"
                    id="copay"
                    style={{
                      fontSize: "14px",
                      border: "1px solid rgba(46, 46, 46, 0.25)",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      marginLeft: "0",
                      float: "left",
                    }}
                    fontSize="14px"
                    borderRadius="4px"
                    autoComplete="off"
                    value={copay}
                    onChange={(e) => copayChange(e)}
                  />{" "}
                </Div>

                <Div
                  padding="16px 23px 0px 0px"
                  display="flex"
                  flexDirection="column"
                  paddingLeft="20px"
                  width="50%"
                  marginLeft="16px"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    For Superbills, send Payment to
                  </Div>
                  <Input
                    height="32px"
                    name="superbills"
                    id="superbills"
                    style={{
                      border: "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "101%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      float: "left",
                    }}
                    autoComplete="off"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    value={superbills}
                    onChange={(e) => supbillChange(e)}
                  />
                </Div>
              </Div>
              <Div display="flex">
                <Div
                  padding="16px 0px 0px 23px"
                  width="50%"
                  display="flex"
                  flexDirection="column"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Deductible
                  </Div>
                  <Input
                    height="32px"
                    id="deductible"
                    style={{
                      border: "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      marginLeft: "0",
                      float: "left",
                    }}
                    autoComplete="off"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    value={deductible}
                    onChange={(e) => dedictChange(e)}
                  />
                </Div>

                <Div
                  padding="16px 23px 0px 0px"
                  display="flex"
                  flexDirection="column"
                  paddingLeft="20px"
                  width="50%"
                  marginLeft="16px"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Insurance Payer Phone
                  </Div>
                  <Input
                    height="32px"
                    id="inspayerphone"
                    style={{
                      border: "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "101%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      float: "left",
                    }}
                    autoComplete="off"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    value={inspayerphone}
                    onChange={(e) => inspayphoneChange(e)}
                  />
                </Div>
              </Div>
              <Div display="flex">
                <Div
                  padding="16px 0px 0px 23px"
                  width="50%"
                  display="flex"
                  flexDirection="column"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                  >
                    Insurance Payer Fax
                  </Div>
                  <Input
                    height="32px"
                    id="inspayerfax"
                    style={{
                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      marginLeft: "0",
                      float: "left",
                    }}
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    autoComplete="off"
                    fontSize="14px"
                    borderRadius="4px"
                    value={inspayerfax}
                    onChange={(e) => inspayfaxChange(e)}
                  />
                </Div>

                <Div
                  padding="16px 23px 0px 0px"
                  display="flex"
                  flexDirection="column"
                  paddingLeft="20px"
                  width="50%"
                  marginLeft="16px"
                >
                  <Div
                    fontFamily="'Open Sans',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    marginBottom="4px !important"
                    textAlign="left"
                    color="#2E2E2E"
                    lineHeight="normal"
                    position="relative"
                  >
                    Employer/Schooler
                  </Div>
                  <Input
                    height="32px"
                    id="emplschool"
                    style={{
                      border: "1px solid rgba(46, 46, 46, 0.25)",

                      fontSize: "14px",
                      borderRadius: "4px",
                      padding: "3px 20px 3px 12px",
                      width: "101%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      paddingRight: "20px",
                      float: "left",
                    }}
                    autoComplete="off"
                    border="1px solid rgba(46, 46, 46, 0.25)"
                    fontSize="14px"
                    borderRadius="4px"
                    value={emplschool}
                    onChange={(e) => empschlChange(e)}
                  />
                </Div>
              </Div>
            </ModalBody>

            <ModalFooter
              justifyContent="space-between"
              padding="24px 21px 20px 23px"
              borderTop="none"
              display="flex"
            >
              <Div>
                <Button
                  borderRadius="4px"
                  border="1px solid #2C7BE5"
                  width="100px"
                  height="32px"
                  background="#FFFFFF"
                  color="#2C7BE5"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  marginRight="24px"
                  marginLeft="0px"
                  cursor="pointer"
                  className="close_active"
                  onClick={() => handleClose()}
                >
                  <Span
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="'Open Sans', sans-serif"
                  >
                    Cancel
                  </Span>
                </Button>
                <Button
                  borderRadius="4px"
                  border="1px solid #2C7BE5"
                  width="100px"
                  height="32px"
                  background="#2C7BE5"
                  color="#FFFFFF"
                  hoverBackColor="#2C7BE5"
                  marginRight="16px"
                  cursor="pointer"
                  marginLeft="0px"
                  className="save_active"
                  onClick={handleSave}
                  disabled={
                    openfromEdit &&
                    SingleInsuranceData &&
                    SingleInsuranceData[1] &&
                    SingleInsuranceData[1].count &&
                    SingleInsuranceData[1].count > 0
                  }
                >
                  <Span
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="'Open Sans', sans-serif"
                  >
                    {openfromEdit &&
                    SingleInsuranceData &&
                    SingleInsuranceData[1] &&
                    SingleInsuranceData[1].count &&
                    SingleInsuranceData[1].count > 0
                      ? "Sent to Review"
                      : openfromEdit
                      ? "Update"
                      : "Save"}
                  </Span>
                </Button>
              </Div>
              {!(
                openfromEdit &&
                SingleInsuranceData &&
                SingleInsuranceData[1] &&
                SingleInsuranceData[1].count &&
                SingleInsuranceData[1].count > 0
              ) && (
                <Div float="right" marginLeft="auto">
                  <Button
                    borderRadius="4px"
                    border="1px solid #2C7BE5"
                    width="100px"
                    height="32px"
                    background="#FFFFFF"
                    color="#2C7BE5"
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    marginRight="0px"
                    cursor="pointer"
                    marginLeft="0px"
                    className="close_active"
                    onClick={() => handleClear()}
                  >
                    <Span
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="'Open Sans', sans-serif"
                    >
                      Clear
                    </Span>
                  </Button>
                </Div>
              )}
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};
export default AddInsurance;
