import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Div, Text } from "../../StyledComponents";
import { Container, Label, Value } from "./styles";
import AddInsurace from "./AddInsurance";
import { RiEdit2Line } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  GetDeleteInsurance,
  GetInsuranceDetail,
  GetInsuranceName,
  GetRelationTypes,
  GetSingleInsurance,
  SetDeleteInsurance,
} from "../../../StateManagement/Reducers/InsuranceState";
import { toast } from "react-toastify";
import Success from "../../StyledComponents/Toastify/Success";
import Error from "../../StyledComponents/Toastify/Error";
const InsuranceCard = () => {
  //--------------------------State and  Variables Start---------------------------- //

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [missingTypes, setMissingTypes] = useState([]);
  const [nonMissingTypes, setNonMissingTypes] = useState([]);
  const [openFromAdd, setOpenFromAdd] = useState(false);
  const [openfromEdit, setOpenfromEdit] = useState(false);
  const [insuranceCount, setInsuranceCount] = useState(0);
  const [insuranceType, setInsuranceType] = useState("");
  const insuranceDetails = useSelector(
    (state) => state.Insurance.InsuranceDetails
  );

  const DeleteResponses = useSelector(
    (state) => state.Insurance.DeleteResponses
  );

  //-----------------------------State and  Variables End----------------------------- //
  //-----------------------------Function Start--------------------------------------- //

  const modalChange = () => {
    dispatch(GetSingleInsurance({}));
    setOpenFromAdd(true);
    setShowModal(true);
  };
  const handleClick = (ClickAction, type) => {
    setInsuranceType(type);
    if (ClickAction === "edit") {
      setOpenfromEdit(true);
      dispatch(
        GetSingleInsurance({
          type: type,
        })
      );
      setShowModal(true);
    }

    if (ClickAction === "delete") {
      let confirmation = window.confirm("Are you sure you want to delete?");
      if (confirmation) {
        dispatch(
          GetDeleteInsurance({
            type: type,
          })
        );
      }
    }
  };
  //-----------------------------Function End----------------------------------- //

  //-----------------------------UseEffect Start--------------------------------- //
  useEffect(() => {
    dispatch(GetInsuranceDetail());
    dispatch(GetInsuranceName());
    dispatch(GetRelationTypes());
  }, []);

  useEffect(() => {
    if (DeleteResponses !== "") {
      if (DeleteResponses === "success") {
        toast(<Success msg={"Datas sent to the Review Succeedly"} />, {
          containerId: "A",
          className: "success_badge",
        });
      } else if (DeleteResponses !== "success") {
        toast(<Error msg={DeleteResponses} />, {
          containerId: "B",
          className: "error_badge",
        });
      }
      setTimeout(() => {
        dispatch(SetDeleteInsurance(""));
      }, 1000);
    }
  }, [DeleteResponses]);

  useEffect(() => {
    if (insuranceDetails && Object.keys(insuranceDetails).length > 0) {
      setInsuranceCount(Object.keys(insuranceDetails).length);
      const types = Object.keys(insuranceDetails);
      const missingTypesCopy = [...missingTypes];
      const nonMissingTypesCopy = [...nonMissingTypes];

      if (!types.includes("primary") && !missingTypes.includes("primary")) {
        missingTypesCopy.push("primary");
      } else if (
        !nonMissingTypesCopy.includes("Primary Insurance") &&
        !missingTypes.includes("primary")
      ) {
        nonMissingTypesCopy.push("Primary Insurance");
      }
      if (!types.includes("secondary") && !missingTypes.includes("secondary")) {
        missingTypesCopy.push("secondary");
      } else if (
        !nonMissingTypesCopy.includes("Secondary Insurance") &&
        !missingTypes.includes("secondary")
      ) {
        nonMissingTypesCopy.push("Secondary Insurance");
      }
      if (!types.includes("tertiary") && !missingTypes.includes("tertiary")) {
        missingTypesCopy.push("tertiary");
      } else if (
        !nonMissingTypesCopy.includes("Tertiary Insurance") &&
        !missingTypes.includes("tertiary")
      ) {
        nonMissingTypesCopy.push("Tertiary Insurance");
      }

      setMissingTypes(missingTypesCopy);
      setNonMissingTypes(nonMissingTypesCopy);
    }
  }, [insuranceDetails]);
  //-----------------------------UseEffect End---------------------------- //

  return (
    <>
      <Div padding="20px 20px 20px 30px">
        <Div fontWeight="600" fontSize="20px">
          Insurance Info
        </Div>
        <Button
          background="#FFFFFF"
          hoverBackColor="#FFFFFF"
          // className="save_active"
          display="flex"
          padding="0px 12px"
          alignItem="center"
          onClick={modalChange}
          border="1px solid #2C7BE5"
          marginTop="20px"
          disabled={nonMissingTypes.length === 3}
        >
          <Text
            color="#2C7BE5"
            fontWeight="400"
            fontSize="1.5rem"
            marginRight="5px"
            padding="10px 12px"
            width="0%"
          >
            +
          </Text>
          <Text
            color="#2C7BE5"
            fontWeight="600"
            fontSize="12px"
            padding="10px 12px 10px 0px"
            // id="apt_pst-sch"
          >
            Add Insurance
          </Text>
        </Button>
        <Div display="flex" flexWrap="wrap">
          {insuranceDetails && insuranceDetails["primary"] && (
            <Div
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="20px 12px 20px 0px"
              width={insuranceCount === 3 ? "33%" : "40%"}
              borderRadius="8px"
            >
              <Container>
                <Label>Primary Insurance:</Label>
                <Value>{insuranceDetails["primary"][0].payer_name}</Value>
                <Div width="20%" display="flex">
                  <RiEdit2Line
                    style={{
                      color: "#2C7BE5",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={() => handleClick("edit", "primary")}
                  />
                  <RiDeleteBinLine
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "4px",
                      fontSize: "20px",
                    }}
                    onClick={() => handleClick("delete", "primary")}
                  />
                </Div>
              </Container>
              <Container>
                <Label>Member ID:</Label>
                <Value>{insuranceDetails["primary"][0].member_id}</Value>
              </Container>
              <Container>
                <Label>Group ID:</Label>
                <Value>{insuranceDetails["primary"][0].group_number}</Value>
              </Container>
              <Container>
                <Label>Copay / Insurance:</Label>
                <Value>{insuranceDetails["primary"][0].copay}</Value>
              </Container>
              <Container>
                <Label>Deductible:</Label>
                <Value>{insuranceDetails["primary"][0].deductible}</Value>
              </Container>
              <Container>
                <Label>Phone Number:</Label>
                <Value>{insuranceDetails["primary"][0].payer_phone}</Value>
              </Container>
            </Div>
          )}
          {insuranceDetails && insuranceDetails["secondary"] && (
            <Div
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="20px 12px 20px 0px"
              width={insuranceCount === 3 ? "33%" : "40%"}
              borderRadius="8px"
            >
              <Container>
                <Label>Secondary Insurance:</Label>
                <Value>{insuranceDetails["secondary"][0].payer_name}</Value>
                <Div width="20%" display="flex">
                  <RiEdit2Line
                    style={{
                      color: "#2C7BE5",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={() => handleClick("edit", "secondary")}
                  />
                  <RiDeleteBinLine
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "4px",
                      fontSize: "20px",
                    }}
                    onClick={() => handleClick("delete", "secondary")}
                  />
                </Div>
              </Container>
              <Container>
                <Label>Member ID:</Label>
                <Value>{insuranceDetails["secondary"][0].member_id}</Value>
              </Container>
              <Container>
                <Label>Group ID:</Label>
                <Value>{insuranceDetails["secondary"][0].group_number}</Value>
              </Container>
              <Container>
                <Label>Copay / Insurance:</Label>
                <Value>{insuranceDetails["secondary"][0].copay}</Value>
              </Container>
              <Container>
                <Label>Deductible:</Label>
                <Value>{insuranceDetails["secondary"][0].deductible}</Value>
              </Container>
              <Container>
                <Label>Phone Number:</Label>
                <Value>{insuranceDetails["secondary"][0].payer_phone}</Value>
              </Container>
            </Div>
          )}
          {insuranceDetails && insuranceDetails["tertiary"] && (
            <Div
              border="1px solid rgba(46, 46, 46, 0.25)"
              margin="20px 12px 20px 0px"
              width={insuranceCount === 3 ? "33%" : "40%"}
              borderRadius="8px"
            >
              <Container>
                <Label>Tertiary Insurance:</Label>
                <Value>{insuranceDetails["tertiary"][0].payer_name}</Value>
                <Div width="20%" display="flex">
                  <RiEdit2Line
                    style={{
                      color: "#2C7BE5",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={() => handleClick("edit", "tertiary")}
                  />
                  <RiDeleteBinLine
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "4px",
                      fontSize: "20px",
                    }}
                    onClick={() => handleClick("delete", "tertiary")}
                  />
                </Div>
              </Container>
              <Container>
                <Label>Member ID:</Label>
                <Value>{insuranceDetails["tertiary"][0].member_id}</Value>
              </Container>
              <Container>
                <Label>Group ID:</Label>
                <Value>{insuranceDetails["tertiary"][0].group_number}</Value>
              </Container>
              <Container>
                <Label>Copay / Insurance:</Label>
                <Value>{insuranceDetails["tertiary"][0].copay}</Value>
              </Container>
              <Container>
                <Label>Deductible:</Label>
                <Value>{insuranceDetails["tertiary"][0].deductible}</Value>
              </Container>
              <Container>
                <Label>Phone Number:</Label>
                <Value>{insuranceDetails["tertiary"][0].payer_phone}</Value>
              </Container>
            </Div>
          )}
        </Div>
      </Div>

      {showModal && (
        <AddInsurace
          insuranceType={insuranceType}
          showModal={showModal}
          setShowModal={setShowModal}
          missingType={missingTypes}
          nonMissingTypes={nonMissingTypes}
          openFromAdd={openFromAdd}
          setOpenFromAdd={setOpenFromAdd}
          setMissingTypes={setMissingTypes}
          setNonMissingTypes={setNonMissingTypes}
          openfromEdit={openfromEdit}
          setOpenfromEdit={setOpenfromEdit}
        />
      )}
    </>
  );
};

export default InsuranceCard;
