import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const PID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const postInsuranceApi = async (Credential) =>
  await axiosInst.post(
    `/vozoportal/insertpatientInusrance?pid=${PID}`,
    Credential
  );

export const getInsuranceApi = async () =>
  await axiosInst.get(
    `/vozoportal/patient/allInsuranceType/details?pid=${PID}`
  );

export const getPayerName_Api = async () =>
  await axiosInst.get(`/vozoportal/insurance/payer_name`);

export const getSingleInsuranceDetail_Api = async (type) =>
  await axiosInst.get(
    `/vozoportal/insurance/getSingleInsuranceDetail?pid=${PID}&type=${type}`
  );

export const ChangedStatus_Api = async (type) =>
  await axiosInst.get(
    `/vozoportal/insurance/changed_status?pid=${PID}&type=${type}`
  );

export const DeleteInsurance_Api = async (type) =>
  await axiosInst.get(
    `/vozoportal/insurance/deleteRequest?pid=${PID}&type=${type}`
  );

export const Relation_Api = async (type) =>
  await axiosInst.get(`/vozonew/list?list_name=personal_relationship`);
