import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SetImageInd } from "../../../StateManagement/Reducers/LoginState";
import { GetLogo } from "../../../StateManagement/Reducers/PreLoginState";

import SlideImage from "./SlideImage";

import { Container } from "../StyledComponents";
import {
  Curcle,
  CurcleMiddle,
  CurcleStart,
  TextLabel,
  ActiveSlideContainer,
  ActiveSlide,
} from "./styles";

import Slider_1 from "../../../Assets/images/login_slide.png";
import Slider_2 from "../../../Assets/images/login_slide_2.png";
import Slider_3 from "../../../Assets/images/login_slide_3.png";

const LoginLeft = () => {
  const dispatch = useDispatch();

  const ImageInd = useSelector((state) => state.Login.ImageInd);

  const ImageVal = [Slider_1, Slider_2, Slider_3];
  const LabelVal = [
    "Proident cupidatat dolor est nisi sit cillum adipisicing nisi magna est. Ad fugiat Lorem proident deserunt aliquip ad pariatur cupidatat_1. ",
    "Proident cupidatat dolor est nisi sit cillum adipisicing nisi magna est. Ad fugiat Lorem proident deserunt aliquip ad pariatur cupidatat_2. ",
    "Proident cupidatat dolor est nisi sit cillum adipisicing nisi magna est. Ad fugiat Lorem proident deserunt aliquip ad pariatur cupidatat_3. ",
  ];

  useEffect(() => {
    dispatch(GetLogo());
  }, [dispatch]);

  useEffect(() => {
    const Timer = setTimeout(() => {
      dispatch(SetImageInd(ImageInd));
    }, 3000);
    return () => clearTimeout(Timer);
  });

  return (
    <>
      <Container>
        <Curcle></Curcle>
        <CurcleMiddle></CurcleMiddle>
        <CurcleStart></CurcleStart>
      </Container>
      <SlideImage ImageUrl={ImageVal[ImageInd]} />
      <TextLabel>{LabelVal[ImageInd]}</TextLabel>
      <ActiveSlideContainer>
        {ImageVal.map((dot, ind) => (
          <ActiveSlide
            onClick={() => dispatch(SetImageInd(ind - 1))}
            active={ind === ImageInd}
            key={ind}
          ></ActiveSlide>
        ))}
      </ActiveSlideContainer>
    </>
  );
};

export default LoginLeft;
