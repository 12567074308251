import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const PayBal_Api = async () =>
  await axiosInst.get(`/vozoportal/getbilloverview?pid=${Pat_ID}`);

export const MedicationApi = async () =>
  await axiosInst.get(
    `/vozoportal/patient/active?pid=${Pat_ID}&type=medication`
  );

export const Welcome_Api = async () =>
  await axiosInst.get(`/vozoportal/getsettingdoc?pid=${Pat_ID}`);

export const LoginUserExist_Api = async () =>
  await axiosInst.put(`/vozoportal/firstlogin?pid=${Pat_ID}&text=get`);

export const Login_Api = async () =>
  await axiosInst.put(`/vozoportal/firstlogin?pid=${Pat_ID}&text=put`);
