import styled from "styled-components";

export const CancelApptInput = styled.textarea`
  width: 327px;
  height: 91px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 12px !important;
  resize: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #7e7c7c !important;
    border: none;
  }
  outline: 0px;
`;
export const ImageIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-top: 6px;
  margin-right: 2px;
`;

export const Div1 = styled.div`
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 2px; /* Set your desired width */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set your desired color */
    border-radius: 4px; /* Set your desired border radius */
  }
`;

export const TextSpan = styled.span`
  line-height: ${(p) => (p.lineHight ? p.lineHight : "27px")};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  color: ${(p) => (p.color ? p.color : "#232934")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;

export const ModalDialog = styled.div`
  width: ${(p) => (p.width ? p.width : "544px")};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animattion ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;
