import styled from "styled-components";

export const Ptag = styled.p`
  font-size: 14px;
  font-weight: 600;
  font-family: "Urbanist", Sans-Serif;
`;

export const Label = styled.label`
  font-family: "Manrope", Sans-Serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #232934;
  margin-bottom: 10px;
  color: ${({ color }) => color && color};
`;

export const InputBox = styled.input`
  width: 320px;
  margin-top: 8px;
  padding-left: 10px;
  padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
  height: 42px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  outline: none;
  font-family: "Inter", Sans-Serif;
  position: relative;
`;

export const Break = styled.br``;
