import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  EditAppointment,
  changeAppointmentData,
  clearAppointmentData,
  getOnsiteAppReq,
} from "../../../StateManagement/Reducers/AppointmentsState";

import { toast } from "react-toastify";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  Div,
  Description,
  Image,
  Button,
  FlexJustCenter,
  Span,
  PageSubTitle,
} from "../../StyledComponents";
import {
  Container,
  CardContainer,
  DateContainer,
  Options,
  CardBody,
  List,
  BackgroundDiv,
  Address,
  CheckinButton,
  Div1,
  Status,
  RespSubTitle,
  RespDiv,
  Ptag,
} from "./styles";

import { AiFillClockCircle, AiOutlinePlus } from "react-icons/ai";
import {
  BiUserCircle,
  BiLocationPlus,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import { RiEditLine, RiDeleteBin7Line } from "react-icons/ri";
import { HiOutlineVideoCamera } from "react-icons/hi";
import AppointmentCancel from "../../Appointment/AppointmentCancel";
import NoAppointmentImage from "../../../Assets/images/no_appt_image.png";
import cardBackground from "../../../Assets/images/card_background.png";

const AppointmentsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [cardOpenId, setCardOpenId] = useState([]);
  const [cancelAppt, setCancelAppt] = useState(false);
  const [CancelDate, setCancelDate] = useState("");
  const [CancelData, setCancelData] = useState("");

  const AppointmentLists = useSelector(
    (state) => state.Appointments.upcomingAppointment
  );
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const OnsiteAppReq = useSelector((state) => state.Appointments.OnsiteAppReq);

  const setOpenButton = (value) => {
    if (cardOpenId === value) {
      setOpen(false);
      setCardOpenId([]);
    } else {
      setOpen(true);
      setCardOpenId(value);
    }
  };

  const StartVideo = (eventTime, start_url) => {
    let currentDateTime = new Date();
    let eventDateTime = new Date(eventTime);
    if (currentDateTime < eventDateTime) {
      alert(`Your Meeting is scheduled at ${eventTime}`);
      return false;
    }
    window.open(start_url, "_blank");
  };

  const handleClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const StartEditAppointment = (data) => {
    dispatch(EditAppointment(data));
    navigate("/appointment/add_appointment");
    setOpen(false);
  };

  const scheduleapp = () => {
    if (OnsiteAppReq["allow_portal"] === "1") {
      if (OnsiteAppReq["individual_patient"] === "0") {
        navigate("/appointment/add_appointment");
        dispatch(clearAppointmentData(""));
      } else if (
        OnsiteAppReq["individual_patient"] === "1" &&
        OnsiteAppReq["newpat_allow"] === "1"
      ) {
        navigate("/appointment/add_appointment");
        dispatch(clearAppointmentData(""));
      } else {
        toast(<Error msg="You're not allow to Make an Appointment" />, {
          containerId: "B",
          className: "error_badge",
        });
      }
    } else {
      toast(<Error msg="You're not allow to Make an Appointment......" />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClick);
    dispatch(getOnsiteAppReq());
    return () => {
      document.removeEventListener("mouseup", handleClick);
    };
  }, []);

  return (
    <RespDiv
      width="100%"
      height="100%"
      backColor="#FFFFFF"
      padding="13px 0px 0px 40px"
    >
      <RespSubTitle
        fontWeight="500"
        fontSize="20px"
        lineHeight="25px"
        color="#000000"
      >
        Upcoming Appointments
      </RespSubTitle>
      <Description
        fontWeight="400"
        fontSize="14px"
        lineHeight="19px"
        color="#7d8592"
        margin="9px 0px 0px 0px"
      >
        Manage Your Appointment
      </Description>
      {AppointmentLists.length === 0 ? (
        <>
          <Image
            loading="lazy"
            src={NoAppointmentImage}
            width="68%"
            height="24.5%"
            margin="30px auto 40px"
            display="block"
          />

          <Button
            border="1px solid #2C7BE5"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            borderRadius="4px"
            background="#ffffff"
            color="#2C7BE5"
            padding="12px 20px"
            fontSize="12px"
            lineHeight="18px"
            fontWeight="600"
            width="calc(100% - 36px)"
            className="close_active"
            onClick={scheduleapp}
          >
            <AiOutlinePlus
              viewBox="150 -60 900 900"
              width="12"
              height="12"
              margin="0 10px"
            />
            Schedule Appointment
          </Button>
        </>
      ) : (
        <Container open={open}>
          {AppointmentLists.length > 0 && (
            <>
              {AppointmentLists.map((lists, index) => (
                <CardContainer
                  accept={lists.accept}
                  index={index}
                  background={`url(${cardBackground})`}
                >
                  <FlexJustCenter>
                    <DateContainer
                      fontSize={lists.pc_recurrtype === "0" ? "20px" : "12px"}
                      index={index}
                      accept={lists.accept}
                    >
                      {lists.pc_recurrtype === "0"
                        ? lists.pc_eventDate
                        : moment(lists.ApptFromDate).format(
                            "dddd DD MMM YYYY"
                          ) +
                          " - " +
                          moment(lists.pc_endDate).format("dddd DD MMM YYYY")}
                    </DateContainer>
                    {lists.accept !== "2" && lists.accept !== "3" && (
                      <Options
                        index={index}
                        accept={lists.accept}
                        onClick={(e) => {
                          setOpenButton(lists.pc_eid);
                        }}
                      >
                        <BiDotsVerticalRounded />
                      </Options>
                    )}
                  </FlexJustCenter>
                  {cardOpenId === lists.pc_eid && (
                    <Div1 ref={inputRef} display={open} right="-6px">
                      {lists.pc_recurrtype === "0" && (
                        <Button
                          color="#414141"
                          display="block"
                          family="'Inter'"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16px"
                          padding="8px 52px 8px 16px"
                          background="#ffffff"
                          textAlign="left"
                          width="100%"
                          hoverBackColor="#FAFAFA"
                          disabled={
                            lists.accept === "2" ||
                            lists.accept === "3" ||
                            lists.pc_recurrtype !== "0"
                          }
                          onClick={() => StartEditAppointment(lists)}
                        >
                          <RiEditLine marginRight="14px" />
                          <Span marginLeft="14px">Edit</Span>
                        </Button>
                      )}
                      <Button
                        color="#414141"
                        display="flex"
                        family="'Inter'"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16px"
                        padding="8px 52px 8px 16px"
                        background="#ffffff"
                        hoverBackColor="#FAFAFA"
                        width="100%"
                        disabled={
                          appointmentData["eid"] === lists.pc_eid ||
                          lists.accept === "2" ||
                          lists.accept === "3"
                        }
                        onClick={() => {
                          setCancelAppt(true);
                          setCancelDate(
                            moment(lists.ApptFromDate).format("Y-MM-DD")
                          );
                          setCancelData(lists);
                          dispatch(
                            changeAppointmentData({
                              name: "cancelID",
                              value: lists.pc_eid,
                            })
                          );
                          setOpen(false);
                        }}
                      >
                        <RiDeleteBin7Line marginRight="14px" />
                        <Span marginLeft="14px">Cancel</Span>
                      </Button>
                    </Div1>
                  )}
                  <CardBody index={index} accept={lists.accept}>
                    <List>
                      <BackgroundDiv index={index} accept={lists.accept}>
                        <BiUserCircle />
                      </BackgroundDiv>
                      {lists.provider_name}
                    </List>
                    <List display="flex">
                      <BackgroundDiv index={index} accept={lists.accept}>
                        <AiFillClockCircle />
                      </BackgroundDiv>
                      <Ptag>{lists.duration}</Ptag>
                    </List>
                    <List>
                      <BackgroundDiv index={index} accept={lists.accept}>
                        <BiLocationPlus />
                      </BackgroundDiv>
                      {lists.tele_health === "1" ? (
                        "Tele Health"
                      ) : (
                        <>
                          {lists.facility_name}
                          <Address index={index} accept={lists.accept}>
                            {lists.street},{lists.city},{lists.state},
                            {lists.postal_code}
                          </Address>
                        </>
                      )}
                    </List>
                  </CardBody>

                  {lists.accept === "0" && (
                    <Status accept={lists.accept}>Appoiment request </Status>
                  )}

                  {lists.accept === "1" &&
                    lists.tele_health === "1" &&
                    index === 0 && (
                      <CheckinButton
                        index={index}
                        onClick={() =>
                          StartVideo(lists.eventDateTime, lists.start_url)
                        }
                      >
                        <BackgroundDiv index={index} tag="button">
                          <HiOutlineVideoCamera />
                        </BackgroundDiv>
                        <Span marginLeft="8px">Join Telehealth</Span>
                      </CheckinButton>
                    )}

                  {lists.accept === "1" &&
                    lists.tele_health === "1" &&
                    index > 0 && (
                      <Status accept={lists.accept}>Join Telehealth </Status>
                    )}

                  {lists.accept === "1" &&
                    lists.tele_health === "0" &&
                    index === 0 && (
                      <CheckinButton index={index}>
                        Appoiment Confirmed
                      </CheckinButton>
                    )}

                  {lists.accept === "1" &&
                    lists.tele_health === "0" &&
                    index >= 1 && (
                      <Status accept={lists.accept}>
                        Appoiment Confirmed{" "}
                      </Status>
                    )}

                  {lists.accept === "2" && (
                    <Status accept={lists.accept}>Cancelled </Status>
                  )}
                </CardContainer>
              ))}
            </>
          )}
        </Container>
      )}

      {cancelAppt && (
        <AppointmentCancel
          show={cancelAppt}
          CancelDate={CancelDate}
          CancelData={CancelData}
          HidePopup={() => {
            setCancelAppt(false);
          }}
        />
      )}
    </RespDiv>
  );
};

export default AppointmentsList;
