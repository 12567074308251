import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Div } from "../../StyledComponents";

const Success = (props) => {
  return (
    <Div display="flex" justifyContent="flex-start" alignItems="center">
      <BsFillCheckCircleFill
        style={{ color: "#6BFF7B", marginRight: 13, fontSize: 24 }}
      />
      <Div>
        <Div
          fontWeight="500"
          fontSize="16px"
          marginBottom="4px"
          color="#2E2C34"
        >
          Success
        </Div>
        <Div fontSize="12px" color="#ABABAB">
          {props.msg}
        </Div>
      </Div>
    </Div>
  );
};

export default Success;
