import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const UserDetails_Api = async () =>
  await axiosInst.get(`/vozoportal/PatientData?pid=${Pat_ID}`);

export const NotificationDetails_Api = async () =>
  await axiosInst.get(`/vozoportal/NotificationData?pid=${Pat_ID}`);

export const NotificationUpdate_Api = async (data) =>
  await axiosInst.post(`/vozoportal/NotificationUpdate`, data);

export const NotificationDelete_Api = async (data) =>
  await axiosInst.post(`/vozoportal/NotificationDelete`, data);
