import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Success from "../../Modules/StyledComponents/Toastify/Success";
import Error from "../../Modules/StyledComponents/Toastify/Error";

export const BillingSlice = createSlice({
  name: "Billing",
  initialState: {
    CardLoading: false,
    InvoiceLoading: false,
    Refresh: "",
    CardDetail: [],
    InvoList: [],
    singleInvoice: [],
    ext: [],
    base64: [],
    symbol: [],
    stateLogo: [],
    PaymentHistory: [],
    stripeAllowed: false,
    stripePubKey: "",
    stripeFailed: false,
  },
  reducers: {
    GetCardDetails: (state) => {
      state.CardLoading = true;
      state.Refresh = "";
    },
    SetCardDetails: (state, action) => {
      state.CardDetail = action.payload;
      state.CardLoading = false;
    },

    AddCardDetails: (data) => {
      return data;
    },
    EditCardDetails: (data) => {
      return data;
    },
    RemoveCardDetail: (data) => {
      return data;
    },

    GetInvoiceList: (state) => {
      state.InvoiceLoading = true;
    },
    SetInvoiceList: (state, action) => {
      state.InvoList = action.payload;
      state.InvoiceLoading = false;
    },

    GetSingleInvoiceData: (state) => {
      state.Loading = true;
    },
    setSingleInvoiceData: (state, action) => {
      state.singleInvoice = action.payload;
      state.Loading = false;
    },

    setExtension: (state, action) => {
      state.ext = action.payload;
    },
    setBase64: (state, action) => {
      state.base64 = action.payload;
    },
    setStateLogo: (state, action) => {
      state.stateLogo = action.payload;
    },
    setSymbol: (state, action) => {
      state.symbol = action.payload;
    },

    GetMakepayment: (amount) => {
      return amount;
    },

    GetPaymentHistory: (state) => {
      return state;
    },
    SetPaymentHistory: (state, action) => {
      state.PaymentHistory = action.payload;
    },

    setStripeAllowed: (state, action) => {
      state.stripeAllowed = action.payload;
    },
    setStripePubKey: (state, action) => {
      state.stripePubKey = action.payload;
    },
    setStripefailed: (state, action) => {
      state.stripeFailed = action.payload;
    },

    Refresh: (state, action) => {
      if (isNaN(action.payload)) {
        toast(<Error msg={action.payload} />, {
          containerId: "B",
          className: "error_badge",
        });
      } else {
        let message = "";
        if (action.payload === 1) message = "Card Details Added Successfully";
        if (action.payload === 2) message = "Something Went Wrong!.";
        if (action.payload === 3) message = "Card Details Removed Successfully";
        if (action.payload === 4) message = "Card Details Updated Successfully";
        if (action.payload === 5) message = "Invoice Paid Successfully";

        toast(<Success msg={message} />, {
          containerId: "A",
          // progressClassName: "Progressbg",
        });
      }

      state.Refresh = action.payload;
    },
  },
});

export const {
  GetCardDetails,
  SetCardDetails,
  AddCardDetails,
  EditCardDetails,
  RemoveCardDetail,

  GetInvoiceList,
  SetInvoiceList,
  GetSingleInvoiceData,
  setSingleInvoiceData,
  setBase64,
  setExtension,
  setStateLogo,
  setSymbol,
  GetMakepayment,

  GetPaymentHistory,
  SetPaymentHistory,

  Refresh,
  setStripeAllowed,
  setStripePubKey,
  setStripefailed,
} = BillingSlice.actions;

export default BillingSlice.reducer;
