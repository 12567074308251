import styled from "styled-components";

export const Div = styled.div`
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  width: ${(p) => p.width && p.width};
`;
