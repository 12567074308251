import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetCardDetails,
  GetInvoiceList,
  GetPaymentHistory,
} from "../../StateManagement/Reducers/BillingState";

import TopNav from "../TopNav";
import MyCards from "./MyCards";
import InvoiceList from "./InvoiceList";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";
import { Div } from "./Styles";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const Billing = () => {
  const dispatch = useDispatch();

  const CardLoading = useSelector((state) => state.Billing.CardLoading);
  const InvoiceLoading = useSelector((state) => state.Billing.InvoiceLoading);
  let accountType =
    localStorage.getItem("account_type") &&
    Decrypt_Value(localStorage.getItem("account_type"), "vozoportal");

  useEffect(() => {
    dispatch(GetCardDetails());
    dispatch(GetInvoiceList());
    dispatch(GetPaymentHistory());
  }, [dispatch]);

  return (
    <>
      <TopNav title="Billing" />
      {CardLoading && InvoiceLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <BodyContainer paddingLeft="40px" paddingTop="12px">
          {accountType !== -1 && (
            <Div marginBottom="50px">
              <MyCards />
            </Div>
          )}
          <Div paddingBottom="85px">
            <InvoiceList />
          </Div>
        </BodyContainer>
      )}
    </>
  );
};

export default Billing;
