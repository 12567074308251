import styled from "styled-components";

export const Card = styled.div`
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.borderradius && p.borderradius};
  padding: ${(p) => p.padding && p.padding};
  margin-top: ${(p) => p.margintop && p.margintop};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  grid-area: Appointment;
`;

export const Comdisp = styled.div`
  display: ${(p) => p.disp && p.disp};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  align-items: ${(p) => p.alignItem && p.alignItem};
  justify-content: ${(p) => p.JustifyContent && p.JustifyContent};

  & .clock {
    color: #7d8592;
    margin-right: 15px;
  }
  & .locate {
    height: 20px;
    width: 20px;
    color: #7d8592;
    margin-right: 12px;
  }
`;

export const Hrline = styled.hr`
  border: 1px solid #d8e0f0;
  margin-top: ${(props) => (props.mt ? props.mt : "20px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "20px")};
  border-bottom: none;
  margin: ${(props) => props.margin && props.margin};
`;
