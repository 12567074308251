import React, { useEffect, useState } from "react";
import TopNav from "../../TopNav";
import { Button, Div } from "../../StyledComponents";
import { Input, P, Span } from "../../StyledComponents/DatePicker/styles";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAccountDeletion,
  GetCheckPortalPassword,
  SetCheckPortalPassword,
} from "../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { toast } from "react-toastify";
import Error from "../../StyledComponents/Toastify/Error";
import Success from "../../StyledComponents/Toastify/Success";

const DeleteAccount = () => {
  const [password, setPassword] = useState("");
  const [isNewPass, setIsNewPass] = useState(true);
  const [verified, setVerified] = useState(true);
  const dispatch = useDispatch();
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");
  const CheckPassword = useSelector(
    (state) => state.Setting.CheckPortalPassword
  );
  const deleteresponse = useSelector((state) => state.Setting.DeletionResponse);
  const handleIsNewPass = (e) => {
    setIsNewPass(false);
  };
  const handleIsNewPass1 = (e) => {
    setIsNewPass(true);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setVerified(false);
    dispatch(SetCheckPortalPassword(""));
  };
  const handleDeletion = (e) => {
    e.stopPropagation();
    if (password === "") {
      toast(<Error msg="Please Enter  Password" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    }
    if (verified) {
      if (
        CheckPassword === "Both Passwords are not same" ||
        CheckPassword === ""
      ) {
        toast(<Error msg="Please Enter Valid Password" />, {
          containerId: "B",
          className: "error_badge",
        });
        return false;
      } else if (CheckPassword === "Both Passwords are same") {
        dispatch(GetAccountDeletion({ pid: Pat_ID }));
      }
    }
  };
  const iconStyle = {
    position: "absolute",
    top: "33%",
    left: "93%",
    cursor: "pointer",
  };
  useEffect(() => {
    if (password) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(GetCheckPortalPassword({ pid: Pat_ID, curPass: password }));
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [password]);

  useEffect(() => {
    if (CheckPassword !== "") {
      setVerified(true);
    }
  }, [CheckPassword]);

  useEffect(() => {
    if (deleteresponse !== "") {
      if (deleteresponse === "Account Deleted") {
        toast(<Success msg="Account Deleted Successfully" />, {
          containerId: "B",
          className: "success_badge",
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/";
        }, 1000);
      } else {
        toast(<Error msg="Something went wrong. Please try again" />, {
          containerId: "B",
          className: "error_badge",
        });
      }
    }
  }, [deleteresponse]);
  return (
    <>
      <TopNav title="Account Deletion" />
      <Div marginLeft="40px" marginTop="40px">
        <P
          color="rgba(46, 46, 46, 0.93)"
          size="14px"
          lheight="19px"
          marginBottom="16px"
        >
          When your patient portal account is deleted, this will remove your
          access to Patient Portal together with the account details we hold in
          relation to you. This does not affect your medical record which will
          remain with your provider. Please note that it may take up to 24 hours
          to remove any existing sessions you may have on any other devices
        </P>
        <P
          color="rgba(46, 46, 46, 0.93)"
          size="14px"
          lheight="19px"
          marginBottom="16px"
        >
          To confirm your account deletion kindly enter your patient portal
          account password here :
        </P>
        <P
          color="rgba(46, 46, 46, 0.93)"
          size="14px"
          lheight="19px"
          marginBottom="16px"
          weight="600"
        >
          Enter Password
          <Span style={{ color: "red" }}>*</Span>
        </P>
        <Div position="relative" width="40%">
          <Input
            height="34px"
            width="100%"
            type={isNewPass ? "password" : "text"}
            border="1px solid #cbd5e0"
            value={password}
            onChange={(e) => handlePassword(e)}
            padding="12px 8% 12px 12px"
          />
          {isNewPass ? (
            <FaRegEyeSlash style={iconStyle} onClick={handleIsNewPass} />
          ) : (
            <FaRegEye style={iconStyle} onClick={handleIsNewPass1} />
          )}
        </Div>
        <Div>
          <Button
            marginTop="16px"
            color="rgba(255, 255, 255, 1)"
            borderRadius="5px"
            background="rgba(242, 72, 34, 1)"
            width="160px"
            height="34px"
            onClick={(e) => handleDeletion(e)}
            title={!verified && CheckPassword === "" && "Please Wait"}
            cursor="pointer"
          >
            Delete Account
          </Button>
        </Div>
      </Div>
    </>
  );
};

export default DeleteAccount;
