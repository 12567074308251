import { takeLatest } from "redux-saga/effects";
import {
  GetPatGenderDetails,
  GetPatMaritalStatus,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPatientProfileUpdate,
  GetPatientProfileRemove,
  GetPatientDetailUpdate,
  GetEmailExist,
  Getprofileupdatestatus,
  GetPatientState,
  GetFeedback,
  GetResetPassword,
  GetCheckPortalPassword,
  GetAccountDeletion,
} from "../../StateManagement/Reducers/SettingState";
import {
  PatGenderDetailsApi,
  PatMaritalStatusApi,
  PatLangDetailsApi,
  PatRaceDetailsApi,
  PatientProfileUpdateApi,
  PatientProfileRemoveApi,
  PatientDetailUpdateApi,
  EmailExistApi,
  ProfileUpdateStatusApi,
  PatientStateApi,
  FeedbackApi,
  PasswordValidation,
  CheckPrevPassword,
  AccountDeleteWorker,
} from "./SettingsSagaWorkers";

export function* PatGenderDetails() {
  yield takeLatest(GetPatGenderDetails.type, PatGenderDetailsApi);
}
export function* PatMaritalStatus() {
  yield takeLatest(GetPatMaritalStatus.type, PatMaritalStatusApi);
}
export function* PatLangDetails() {
  yield takeLatest(GetPatLangDetails.type, PatLangDetailsApi);
}
export function* PatRaceDetails() {
  yield takeLatest(GetPatRaceDetails.type, PatRaceDetailsApi);
}
export function* PatientProfileUpdate() {
  yield takeLatest(GetPatientProfileUpdate.type, PatientProfileUpdateApi);
}
export function* PatientProfileRemove() {
  yield takeLatest(GetPatientProfileRemove.type, PatientProfileRemoveApi);
}
export function* PatientDetailUpdate() {
  yield takeLatest(GetPatientDetailUpdate.type, PatientDetailUpdateApi);
}
export function* EmailExist() {
  yield takeLatest(GetEmailExist.type, EmailExistApi);
}
export function* ProfileUpdateStatus() {
  yield takeLatest(Getprofileupdatestatus.type, ProfileUpdateStatusApi);
}
export function* PatientStatus() {
  yield takeLatest(GetPatientState.type, PatientStateApi);
}

export function* FeedbackInsert() {
  yield takeLatest(GetFeedback.type, FeedbackApi);
}
export function* ResetPassword() {
  yield takeLatest(GetResetPassword.type, PasswordValidation);
}

export function* CheckPatPortalPassword() {
  yield takeLatest(GetCheckPortalPassword.type, CheckPrevPassword);
}

export function* AccountDeleteWatcher() {
  console.log("1");
  yield takeLatest(GetAccountDeletion.type, AccountDeleteWorker);
}
