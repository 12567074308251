import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clearInput } from "../../../StateManagement/Reducers/AppointmentsState";

import { Div, NormalImage, Text } from "../../StyledComponents";
import { ChangeText, InitialsText } from "./Styles";
import { TextVariant } from "./StyledComponents";

const SelectedProvider = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  const dispatch = useDispatch();

  const [changeToText, SetChangeToText] = useState(false);

  const single = useSelector((state) => state.Appointments.appointmentData);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const proImageUrl = backurl + "/sites/" + DBhost + "/documentimg/";

  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //

  const getInitials = (x) => {
    let a = x.split(" ");
    let b = a[0].substr(0, 1).toUpperCase();
    return b;
  };

  const imageError = () => {
    SetChangeToText(!changeToText);
  };

  // ------------------------------ Functions End------------------------------------------------------------- //

  return (
    <Div
      border="1px solid #407BFF"
      borderRadius="6px"
      width="100%"
      padding="12px 16px"
    >
      <Div width="100%" textAlign="center">
        {single["singleProvider"]["img_url"] &&
        single["singleProvider"]["img_url"] !== null &&
        !changeToText ? (
          <NormalImage
            className="ProvImages_alt"
            loading="lazy"
            height="32px"
            width="32px"
            borderRadius="50%"
            src={
              single["singleProvider"]["img_url"] !== null
                ? proImageUrl + single["singleProvider"]["img_url"]
                : ""
            }
            onError={imageError}
          />
        ) : (
          <InitialsText margin="0 auto">
            {getInitials(single["singleProvider"]["name"])}
          </InitialsText>
        )}
      </Div>
      <ChangeText
        mediaWidth="1100px"
        absolute
        onClick={() => dispatch(clearInput("provider"))}
      >
        Change
      </ChangeText>
      <TextVariant width="100%" align="center">
        {single["singleProvider"]["name"]}
      </TextVariant>
      <Text
        fontWeight="400"
        fontSize="12px"
        lineHeight="20px"
        color="#7D8592"
        width="100%"
        align="center"
      >
        {single["singleProvider"]["designation"]}
      </Text>
    </Div>
  );
};

export default SelectedProvider;
