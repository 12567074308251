import { call, put } from "redux-saga/effects";
import { Decrypt_Value } from "../EncryptDecrypt";

import {
  SetPayBal,
  SetMedication,
  SetWelcome,
  SetLogin,
  SetLoginExist,
} from "../../StateManagement/Reducers/DashboardState";

import {
  PayBal_Api,
  MedicationApi,
  Welcome_Api,
  Login_Api,
  LoginUserExist_Api,
} from "../Apis/DashBoardApi";

export function* PayBalApi() {
  try {
    const res = yield call(PayBal_Api);
    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozoportal");
      yield put(SetPayBal(Decrypt_Response));
    }
  } catch (e) {
    yield put(SetPayBal([]));
    console.log(e.message);
  }
}
export function* Medication() {
  try {
    const res = yield call(MedicationApi);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetMedication(decrptedResponse));
    }
  } catch (e) {
    yield put(SetMedication([]));
    console.log(e.message);
  }
}

export function* Welcome() {
  try {
    const res = yield call(Welcome_Api);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetWelcome(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* Login() {
  try {
    const res = yield call(Login_Api);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetLogin(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* LoginExist() {
  try {
    const res = yield call(LoginUserExist_Api);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetLoginExist(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}
