import { createSlice } from "@reduxjs/toolkit";

const HomeWorkSlice = createSlice({
  name: "HomeWork",
  initialState: {
    HomeWorkList: [],
    Loading: false,
  },
  reducers: {
    GetHomeWorkLists: (state) => {
      state.Loading = true;
      return state;
    },
    SetHomeWorkLists: (state, action) => {
      state.HomeWorkList = action.payload;
      state.Loading = false;
    },
    GetUpdateWatched: (cancelreason) => {
      return cancelreason;
    },
  },
});

export const { GetHomeWorkLists, SetHomeWorkLists, GetUpdateWatched } =
  HomeWorkSlice.actions;

export default HomeWorkSlice.reducer;
