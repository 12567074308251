import styled from "styled-components";

export const Image = styled.img`
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  object-fit: ${(props) => (props.objectFit ? props.objectFit : "contain")};
  transition: translate1s;
`;

export const ImageContainer = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
`;

export const Container = styled.div`
  display: ${(props) => (props.Flex ? "flex" : "")};
  height: ${(props) => (props.height ? props.height : "")};
  width: ${(props) => props.width && props.width};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
`;

export const Span = styled.p`
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const Input = styled.input`
  background: #ffffff;
  color: ${(p) => p.color && p.color};
  border: 1px solid #d8e0f0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 8px;
  height: ${(p) => (p.height ? p.height : "48px")};
  width: ${(p) => p.width && p.width};
  padding: ${(p) => (p.padding ? p.padding : "0px 18px")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "24px")};
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #f9f8f8;
    &:hover {
      cursor: not-allowed;
    }
  }
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;

export const TitleImage = styled.img`
  display: block;
  text-align: center;
  margin: auto;
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "32px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const Title = styled.h2`
  color: ${(p) => p.color && p.color};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const Atag = styled.a`
  color: ${(p) => p.color && p.color};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  font-family: "inter";
  font-weight: 400;
  font-size: 14px;
  margin: ${(p) => p.margin && p.margin};
  cursor: pointer;
`;

export const ResendButton = styled.span`
  cursor: pointer;
  font-family: "Urbanist";
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1f28eb;
`;
