import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { BsChevronDown } from "react-icons/bs";
import { GetCancelAppt } from "../../../StateManagement/Reducers/AppointmentsState";

import cal_icon from "../../../Assets/images/calendar.png";
import cal_time from "../../../Assets/images/calendar_time.png";

import {
  Modal,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBody,
  Button,
  Div,
} from "../../StyledComponents";

import {
  CancelApptInput,
  ModalDialog,
  TextSpan,
  ImageIcon,
  Div1,
} from "./styles";

const AppointmentCancel = (props) => {
  const [Text, SetText] = useState("");
  const [SelectDateErr, setSelectDateErr] = useState(false);
  const [CancelDateSelect, setCancelDateSelect] = useState("");
  const [savebtndisabled, setsavebtndisabled] = useState(false);
  const [OpenList, setOpenList] = useState(false);
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const dispatch = useDispatch();

  const SelectListOpen = () => {
    setOpenList(!OpenList);
  };
  const selectDate = (val) => {
    setCancelDateSelect(val);
    setOpenList(false);
    setSelectDateErr(false);
  };

  const Confirmation = (e) => {
    var err = 0;
    if (props.CancelData?.pc_recurrtype !== "0") {
      if (
        CancelDateSelect === "" ||
        CancelDateSelect === null ||
        CancelDateSelect === undefined
      ) {
        setSelectDateErr(true);
        err = 2;
      } else {
        setSelectDateErr(false);
      }
    }
    if (err > 0) {
      return false;
    }
    setsavebtndisabled(true);
    setTimeout(() => {
      setsavebtndisabled(false);
    }, 1200);
    props.HidePopup();
    e.preventDefault();
    SetText("");
    dispatch(
      GetCancelAppt({
        Cancel_reason: Text,
        pc_eid: appointmentData["cancelID"],
        slotDate:
          props.CancelData?.pc_recurrtype === "0"
            ? props.CancelDate
            : CancelDateSelect,
      })
    );
  };

  return (
    <Modal width="100%" height="100%" show={props.show}>
      <ModalContainer justifyContent="center">
        <ModalDialog style={{ padding: 20, width: 367 }}>
          <ModalHeader padding="0px" borderLess marginBottom="12px">
            <ModalTitle
              lineHight="27px"
              fontSize="20px"
              fontWeight="500"
              color="#232934"
            >
              Appointment Cancel
            </ModalTitle>
          </ModalHeader>
          <ModalBody
            marginBottom="16px"
            fontWeight="400"
            fontSize="14px"
            color="#263238;"
          >
            Kindly Specify the reason for cancelling the appointment with us?
          </ModalBody>
          {props.CancelData?.pc_recurrtype !== "0" && (
            <>
              {SelectDateErr && (
                <TextSpan
                  lineHight="27px"
                  fontSize="12px"
                  fontWeight="500"
                  color="red"
                >
                  Plaese Select Date
                </TextSpan>
              )}
              <Div
                userSelect="none"
                cursor="pointer"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="12px"
                marginBottom="20px"
                borderRadius="6px"
                border={SelectDateErr ? "1px solid red" : "none"}
                backColor="#F8FBFF"
                height="40px"
                width="328px"
                onClick={SelectListOpen}
              >
                {CancelDateSelect === "" ? (
                  <TextSpan
                    lineHight="27px"
                    fontSize="12px"
                    fontWeight="500"
                    color="#2E2E2E"
                  >
                    Choose appointment date
                  </TextSpan>
                ) : (
                  <>
                    <Div backColor="#F8FBFF" display="flex" width="55%">
                      <ImageIcon src={cal_icon}></ImageIcon>
                      <TextSpan
                        lineHight="27px"
                        fontSize="12px"
                        fontWeight="500"
                        color="rgba(64, 123, 255, 1)"
                      >
                        {moment(CancelDateSelect).format("dddd DD, MMM YYYY")}
                      </TextSpan>
                    </Div>
                    <Div backColor="#F8FBFF" display="flex" width="45%">
                      <ImageIcon src={cal_time}></ImageIcon>
                      <TextSpan
                        lineHight="27px"
                        fontSize="12px"
                        fontWeight="500"
                        color="rgba(64, 123, 255, 1)"
                      >
                        {props.CancelData.Time}
                      </TextSpan>
                    </Div>
                  </>
                )}

                <BsChevronDown id="doc_al-svg_log" />
              </Div>
            </>
          )}
          {OpenList ? (
            <Div
              borderRadius="6px"
              border="1px solid rgba(46, 46, 46, 0.25)"
              height="141px"
              width="328px"
              display="grid"
              padding="6px 6px 6px 6px"
            >
              <Div1 overflow="auto" padding="6px 0px 0px 6px">
                {props.CancelData.AppDate.map((row, index) => (
                  <Div1
                    cursor="pointer"
                    display="flex"
                    onClick={() => selectDate(row)}
                  >
                    <Div display="flex" width="55%">
                      <ImageIcon src={cal_icon}></ImageIcon>
                      <TextSpan
                        lineHight="27px"
                        fontSize="12px"
                        fontWeight="500"
                        color="rgba(64, 123, 255, 1)"
                      >
                        {moment(row).format("dddd DD, MMM YYYY")}
                      </TextSpan>
                    </Div>
                    <Div display="flex" width="45%">
                      <ImageIcon src={cal_time}></ImageIcon>
                      <TextSpan
                        lineHight="27px"
                        fontSize="12px"
                        fontWeight="500"
                        color="rgba(64, 123, 255, 1)"
                      >
                        {props.CancelData.Time}
                      </TextSpan>
                    </Div>
                  </Div1>
                ))}
              </Div1>
            </Div>
          ) : (
            <>
              <CancelApptInput
                placeholder="Enter the Reason"
                onChange={(e) => SetText(e.target.value)}
              ></CancelApptInput>
              <Div display="flex">
                <Button
                  height="32px"
                  background="#FFF"
                  borderRadius="4px"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  border=" 1px solid #2C7BE5"
                  font-family="Work Sans, sans-serif"
                  cursor="pointer"
                  marginLeft="auto"
                  marginRight="20px"
                  width="110px"
                  color="#2C7BE5"
                  padding="6px 16px"
                  onClick={props.HidePopup}
                  id="cnl_apt-btn"
                >
                  Cancel
                </Button>
                <Button
                  height="32px"
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  borderRadius="4px"
                  font-family="Work Sans, sans-serif"
                  cursor="pointer"
                  width="110px"
                  color="#ffffff"
                  padding="6px 16px"
                  className="save_active"
                  disabled={savebtndisabled}
                  onClick={Confirmation}
                >
                  Confirm
                </Button>
              </Div>
            </>
          )}
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default AppointmentCancel;
