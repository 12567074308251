import { takeLatest } from "redux-saga/effects";
import {
  GetUpcomingAppointment,
  getProvidersList,
  getFacilityList,
  addAppointment,
  AppointmentEdited,
  getProviderAvailablity,
  GetPastAppt,
  GetCancelAppt,
  getPortalAppAvailablity,
  getOnsiteAppReq,
  GetBillingFacility,
} from "../../StateManagement/Reducers/AppointmentsState";
import {
  upcomingAppointments,
  providersWorker,
  facilityWorker,
  AddAppointmentWorker,
  EditAppointmentWorker,
  SetAvailablityWorker,
  PastApptAPi,
  CancelApptApi,
  SetPortalAppAvailablityWorker,
  SetOnsiteAppReqWorker,
  BillingFacilityWorker,
} from "./AppointmentsSagaWorkers";

export function* upcomingAppointmentsSaga() {
  yield takeLatest(GetUpcomingAppointment.type, upcomingAppointments);
}
export function* PastApptSaga() {
  yield takeLatest(GetPastAppt.type, PastApptAPi);
}
export function* CancelApptSaga() {
  yield takeLatest(GetCancelAppt.type, CancelApptApi);
}

export function* GetProviderSaga() {
  yield takeLatest(getProvidersList.type, providersWorker);
}
export function* GetFacilitySaga() {
  yield takeLatest(getFacilityList.type, facilityWorker);
}
export function* SaveAppointmentSaga() {
  yield takeLatest(addAppointment.type, AddAppointmentWorker);
}
export function* EditAppointmentSaga() {
  yield takeLatest(AppointmentEdited.type, EditAppointmentWorker);
}
export function* getProviderAvailablitySaga() {
  yield takeLatest(getProviderAvailablity.type, SetAvailablityWorker);
}
export function* getPortalAppAvailablitySaga() {
  yield takeLatest(getPortalAppAvailablity.type, SetPortalAppAvailablityWorker);
}
export function* getOnsiteAppReqSaga() {
  yield takeLatest(getOnsiteAppReq.type, SetOnsiteAppReqWorker);
}

export function* BillingFacilitySaga() {
  yield takeLatest(GetBillingFacility.type, BillingFacilityWorker);
}
