import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

import {
  setPortalEnable,
  setClient,
  setOtp,
  setEmailRes,
  setReEmailRes,
  setPassRes,
  setSignUp,
  SetLogo,
  SetPatientUrl,
  SetLoginStatus,
  SetProvStatus,
} from "../../StateManagement/Reducers/PreLoginState";

import { Extension_Api } from "../Apis/BillingApi";
import {
  PortalEnable_Api,
  Client_Api,
  LoginEmailCheck_Api,
  LoginEmailCheckNew_Api,
  PasswordResetEmailCheck_Api,
  LoginPasswordSet_Api,
  SignUp_Api,
  VzPortalApi,
  CheckProvStatusApi,
} from "../Apis/PreLoginApi";

export function* PortalEnableApi({ payload }) {
  try {
    if (!payload) {
      const res = yield call(Client_Api);
      if (res.status === 200) {
        const Data = res.data;
        if (Data === 1) {
          yield put(setClient("pass"));
        } else {
          yield put(setClient("fail"));
          return;
        }
      }
    }
    const res = yield call(PortalEnable_Api);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoportal");

      localStorage.setItem(
        "account_type",
        Encrypt_Value(Number(Data.account_type), "vozoportal")
      );
      localStorage.setItem(
        "trial_period",
        Encrypt_Value(
          String(Data.trial_period).toLowerCase() === "true",
          "vozoportal"
        )
      );

      if (Data.gl_value === "1") {
        yield put(setPortalEnable(1));
      } else {
        yield put(setPortalEnable(0));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* ClientApi() {
  try {
    const res = yield call(Client_Api);
    if (res.status === 200) {
      const Data = res.data;
      if (Data === 1) {
        yield put(setClient("pass"));
      } else {
        yield put(setClient("fail"));
      }
    }
  } catch (e) {
    yield put(setClient("fail"));
    console.log(e.message);
  }
}
export function* LoginEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "vozoportal");

  const mode = payload.mode;
  try {
    const res = yield call(
      mode === "old" ? LoginEmailCheck_Api : LoginEmailCheckNew_Api,
      email
    );
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoportal");
      yield put(setOtp(Data.output));
      localStorage.setItem("OTP", Encrypt_Value(Data.output, "vozoportal"));
      yield put(setEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* LoginPasswordSetApi({ payload }) {
  const data = Encrypt_Value(payload.type, "vozoportal");

  try {
    const res = yield call(LoginPasswordSet_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoportal");
      yield put(setPassRes(Data.status));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PasswordResetEmailCheckApi({ payload }) {
  const email = Encrypt_Value(payload.type, "vozoportal");

  try {
    const res = yield call(PasswordResetEmailCheck_Api, email);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoportal");
      yield put(setOtp(Data.output));
      localStorage.setItem("OTP", Encrypt_Value(Data.output, "vozoportal"));
      yield put(setReEmailRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SignUpApi({ payload }) {
  const data = Encrypt_Value(payload, "vozoportal");
  try {
    const res = yield call(SignUp_Api, data);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoportal");
      yield put(setSignUp(Data.status));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* LogoApi() {
  try {
    const res = yield call(Extension_Api);
    if (res.status === 200) {
      let Decrypt_Response = Decrypt_Value(res.data, "vozo");
      yield put(SetLogo(Decrypt_Response[4]["gl_value"]));
      yield put(SetPatientUrl(Decrypt_Response[11]["gl_value"]));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetLogo("failed"));
    yield put(SetPatientUrl(window.location.hostname));
  }
}

export function* LogChecking({ payload }) {
  try {
    const res = yield call(VzPortalApi, payload);
    if (res.status === 200) {
      //let Decrypt_Response = Decrypt_Value(res.data, "vozo");
      yield put(SetLoginStatus(res.data));
    }
  } catch (e) {
    yield put(SetLoginStatus(""));
    console.log(e.message);
  }
}

export function* ProvStatusCheck({ payload }) {
  try {
    const res = yield call(CheckProvStatusApi, payload);
    if (res.status === 200) {
      //let Decrypt_Response = Decrypt_Value(res.data, "vozo");
      yield put(SetProvStatus(res.data));
    }
  } catch (e) {
    yield put(SetProvStatus(""));
    console.log(e.message);
  }
}
