import React from "react";
import styled from "styled-components";

import { AiOutlineSearch } from "react-icons/ai";

import { CloseIcon, Div, Image, Description } from "../StyledComponents";
import { SearchInputText, ThumbImage, ThumbDoc } from "./Styles";
import MicroPhone_Start from "../../Assets/images/Microphone_Start.png";

const SearchProvider = ({ searchVal }) => {
  const Onsearch = (e) => {
    searchVal(e.target.value);
  };
  return (
    <Div
      width="100%"
      display="flex"
      padding="0px 0px 0px 10px"
      height="36px"
      borderRadius="8px"
      border="1px solid #e4e4e4"
    >
      <AiOutlineSearch
        opacity="0.6"
        font-size="22px"
        fill="#666666"
        font-weight="100"
        viewBox="0 -200 1024 1024"
      />
      <SearchInputText
        id="msg_inp-srch"
        onChange={Onsearch}
        placeholder="Search Provider"
      />
    </Div>
  );
};

const TnumbComponent = ({ message, variant }) => {
  return (
    <CloseIcon>
      {variant === "image" && (
        <>
          <ThumbImage />
          <Description
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Description>
        </>
      )}
      {variant === "doc" && (
        <>
          <ThumbDoc />
          <Description
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 12px"
          >
            {message}
          </Description>
        </>
      )}
      {variant === "audio" && (
        <>
          <Image
            src={MicroPhone_Start}
            height="20px"
            width="16px"
            margin="0px 6px 0px 0px"
          />
          <Description
            fontSize="13px"
            lineHeight="18px"
            color="#91929e"
            margin="0px 0px 0px 0px"
          >
            {message}
          </Description>
        </>
      )}
    </CloseIcon>
  );
};
export { SearchProvider, TnumbComponent };

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: ${(p) => (p.display ? p.display : "inline-block")};
  margin-right: 12px;
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  text-align: ${(p) => p.textAlign && p.textAlign};
  border: ${(p) => p.border && p.border};
  position: ${(p) => p.position && p.position};
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: ${(p) => (p.position ? p.position : "relative")};
`;

export const AvatarLetter = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: ${(p) => (p.position ? p.position : "relative")};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  right: ${(p) => p.right && p.right};
`;

export const AvatarImage = styled(Image)`
  display: ${(p) => (p.display ? p.display : "inline-block")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  min-width: ${(p) => p.minWidth && p.minWidth};
  border: ${(p) => p.border && p.border};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  margin-top: 3px;
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-align: ${(p) => p.textAlign && p.textAlign};
  position: ${(p) => p.position && p.position};
`;
export const Images = styled.img`
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  cursor: ${({ cursor }) => cursor && cursor};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};

  &.imageWarp {
    @media (max-width: 820px) {
      max-width: 370px !important;
    }
  }
`;
