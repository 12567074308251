import { takeLatest } from "redux-saga/effects";
import {
  // GetSingleInsDetail,
  postSingleInsDetail,
  GetInsuranceDetail,
  GetInsuranceName,
  GetSingleInsurance,
  GetChangedStatus,
  GetDeleteInsurance,
  GetRelationTypes,
} from "../../StateManagement/Reducers/InsuranceState";
import {
  GetAllInsuranceDetails,
  // GetInsurancedata,
  GetAllInsuranceName,
  postInsurancedata,
  GetSingleInsurancedata,
  InsuranceChangedStatus,
  DeleteResponseWork,
  Relationships,
} from "./InsuranceSagaWorkers";

export function* GetInsuranceDetails() {
  yield takeLatest(GetInsuranceDetail.type, GetAllInsuranceDetails);
}

export function* SingleInsuranceDetail() {
  yield takeLatest(postSingleInsDetail.type, postInsurancedata);
}
// export function* GetInsuranceDetail() {
//   yield takeLatest(GetSingleInsDetail.type, GetInsurancedata);
// }

export function* PayerName() {
  yield takeLatest(GetInsuranceName.type, GetAllInsuranceName);
}

export function* GetSingleInsuranceDetail() {
  yield takeLatest(GetSingleInsurance.type, GetSingleInsurancedata);
}

export function* InsuranceStatusChanged() {
  yield takeLatest(GetChangedStatus.type, InsuranceChangedStatus);
}

export function* DeleteInsuranceRequest() {
  yield takeLatest(GetDeleteInsurance.type, DeleteResponseWork);
}

export function* RelationShipTypes() {
  yield takeLatest(GetRelationTypes.type, Relationships);
}
