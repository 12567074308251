import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { changeRemoveID } from "../../../../StateManagement/Reducers/DashboardState";

import { Div } from "../../../StyledComponents";
import { Inner_carouse, Indic_Btn, CarouselContainer } from "../Styles";

export const CarouselItem = ({ children, width }) => {
  return (
    <>
      <CarouselContainer style={{ width: width }}>{children}</CarouselContainer>
    </>
  );
};

const Carousel = ({ children }) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
    dispatch(changeRemoveID({ name: "buttonIndex", value: newIndex }));
  };

  return (
    <>
      <Inner_carouse actIndex={activeIndex}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </Inner_carouse>
      <Div display="flex" justifyContent="center" marginTop="15px">
        {React.Children.map(children, (child, index) => {
          return (
            <>
              <Indic_Btn
                borderRadius="20px"
                marginRight="4px"
                active={`${index === activeIndex && "active"}`}
                val={index}
                onClick={() => {
                  updateIndex(index);
                }}
              />
            </>
          );
        })}
      </Div>
    </>
  );
};

export default Carousel;
