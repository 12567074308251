import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Div } from "../../StyledComponents";
const Error = (props) => {
  return (
    <Div display="flex" justifyContent="flex-start" alignItems="center">
      <FaInfoCircle
        style={{ color: "#D00E0E", marginRight: 13, fontSize: 32 }}
      />
      <Div marginRight="20px">
        <Div
          fontWeight="500"
          fontSize="16px"
          marginBottom="4px"
          color="#2E2C34"
        >
          Error
        </Div>
        <Div fontSize="12px" color="#ABABAB">
          {props.msg}
        </Div>
      </Div>
    </Div>
  );
};

export default Error;
