import styled from "styled-components";

export const Div = styled.div`
  display: ${(props) => (props.display ? props.display : "block")};
  width: ${(props) => (props.width ? props.width : "")};
  background-color: ${(props) => (props.bg_color ? props.bg_color : "")};
  height: ${(props) => (props.height ? props.height : "")};
  align-items: center;
  justify-content: center;
  position: ${(props) => props.position && props.position};
  margin-top: ${(props) => props.marginTop && props.marginTop};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
  margin-right: ${(props) => props.marginRight && props.marginRight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
`;
