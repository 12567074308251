import { call, put } from "redux-saga/effects";
import {
  SetGetList,
  SetHealthGorila,
} from "../../StateManagement/Reducers/HealthProfileState";
import { ListsApi } from "../Apis/HealthProfileApi";
import { Decrypt_Value } from "../EncryptDecrypt";

export function* Lists({ payload }) {
  let type = payload;
  try {
    const res = yield call(ListsApi, type);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetGetList(decryptResponse));
    }
  } catch (e) {
    yield put(SetGetList([]));
    console.log(e.message);
  }
}
