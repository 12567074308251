import styled from "styled-components";
import { Image } from "../../StyledComponents";

export const Container = styled.div`
  position: relative;
  border-top: 1px solid #e4e4e4;
  margin-top: 13px;
  margin-left: -40px;
  padding-top: 3px;
  min-width: ${(p) => p.minWidth && p.minWidth};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarLetter = styled.span`
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

export const AvatarImage = styled(Image)`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;
