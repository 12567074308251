import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import CustomDatePicker from "../../StyledComponents/DatePicker/CustomDatePicker";
import Error from "../../../Modules/StyledComponents/Toastify/Error";
import calendar_icon_custom from "../../../Assets/images/calendar_icon.png";
import CustomDropdown from "./Dropdown";
import { DropDown as Dropdownstyle } from "../../HealthProfile/styles";
import arrowIcon from "../../../Assets/images/arrowIcon.png"; // Import your arrow icon

import {
  GetPatientDetailUpdate,
  GetPatientProfileUpdate,
  GetPatientProfileRemove,
  GetEmailExist,
  Getprofileupdatestatus,
  SetEmailExist,
} from "../../../StateManagement/Reducers/SettingState";

import {
  Text,
  Div,
  NormalImage,
  Button,
  Form,
  Modal,
  ModalContainer,
  ModalHeader,
  ModalBody,
  FormGroup,
  FormLabel,
  ModalFooter,
  ModalTitle,
  Image,
} from "../../StyledComponents";

import {
  Display,
  Displayerror,
  DisplayerrorText,
  Dropdown,
  Inputbox,
  CurveImg,
  ModalDialog,
  Hrline,
  DragDrop,
  FileInput,
  SelectInput,
  DropdownImage,
  DropDownDiv,
  DropdownOptions,
  TooltipText,
  Tooltip,
  TooltipComponent,
} from "./styles";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import BlueCurve from "../../../Assets/images/Ellipse_2.png";
import arrow from "../../../Assets/images/arrowdown.png";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { BsXLg } from "react-icons/bs";
import UploadFile1 from "../../../Assets/images/uploadDoc.png";
import { debounce } from "../../HomeWork";
import { Imagecalendar } from "../../StyledComponents/DatePicker/styles";

const EditProfile = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [revertReason, setRevertReason] = useState("");
  const [previewimg, setpreviewimg] = useState("");
  const [select, setSelect] = useState(false);
  const [upload, setUpload] = useState(true);
  const [showpop1, Setshowpop1] = useState(false);
  const [fname, setfname] = useState("");
  const [mname, setmname] = useState([]);
  const [lname, setlname] = useState([]);
  const [patimg, setpatimg] = useState([]);
  const [title, settitle] = useState([]);
  const [ss, setss] = useState([]);
  const [street, setstreet] = useState([]);
  const [city, setcity] = useState([]);
  const [state, setstate] = useState("");
  const [status1, setstatus1] = useState("");
  const [status, setstatus] = useState("");
  const [providerID, setproviderID] = useState("");
  const [ethnicity1, setethnicity1] = useState("");
  const [ethnicity2, setethnicity2] = useState("");
  const [language1, setlanguage1] = useState("");
  const [country_code, setcountry_code] = useState([]);
  const [sex1, setsex1] = useState("");
  const [inputList, setInputList] = useState([{ phone: "", type: "" }]);
  const [inputList2, setInputList2] = useState([{ email: "", type: "" }]);
  const [validation, setValidation] = useState([
    { emailErr: false, typeErr: false, msg: "", typemsg: "" },
  ]);
  const [phoneValidation, setPhoneValidation] = useState([
    { numberErr: false, typeErr: false, msg: "", typemsg: "" },
  ]);
  const [workMailErr, setWorkMailErr] = useState(false);
  const [active, setActive] = useState("");
  const [imgfile, setimgfile] = useState("");
  const [err, seterr] = useState(false);
  const [err1, seterr1] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [mailcheck, setMailcheck] = useState("");

  const [MobilePhone, setMobilePhone] = useState("");
  const [WorkPhone, setWorkPhone] = useState("");
  const [HomePhone, setHomePhone] = useState("");
  const [ContactPhone, setContactPhone] = useState("");

  const [WorkEmail, setWorkEmail] = useState("");
  const [HomeEmail, setHomeEmail] = useState("");

  const [showsave, setshowsave] = useState(true);
  const [UpdateStatus, setupdateprofilestatus] = useState(0);

  const [labeltext, setlabeltext] = useState("add file");

  const [crop, SetCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 150,
    height: 150,
  });
  const [croppedImageUrl, setcroppedImageUrl] = useState("");
  const [normalImg, setNormalImg] = useState(false);
  const [jpegFile, setjpegFile] = useState("");
  const [Cropblob, setCropblob] = useState("");
  const [imageRef, setImageRef] = useState();
  const [show, setShow] = useState(false);
  const [ModalAnimat] = useState(true);
  const [uploadbtndisabled, setuploadbtndisabled] = useState(false);
  const [savepicbtndisabled, setsavepicbtndisabled] = useState(false);
  const [fromdate, setFromDate] = useState("");
  const [validDate, setValidDate] = useState("");
  const [showpicker, setShowPicker] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [dropDown3, setDropDown3] = useState(false);
  const [dropDown4, setDropDown4] = useState(false);
  const [dropDown5, setDropDown5] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const inputNotRef = useRef(null);
  const maritalNotRef = useRef(null);
  const languageNotRef = useRef(null);
  const raceNotRef = useRef(null);
  const ethinicity2NotRef = useRef(null);
  const stateNotRef = useRef(null);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const closePicker = () => {
    setShowPicker(false);
  };
  const handlepicker = (e) => {
    e.preventDefault();
    setShowPicker(true);
  };

  const dateSelect = (value) => {
    if (Object.prototype.toString.call(value) === "[object Date]") {
      setFromDate(moment(value).format("YYYY-MM-DD"));
      setValidDate(new Date(value));
    } else {
      setFromDate("");
      setValidDate("");
    }
    closePicker();
  };

  const keyDownExpiry = (event) => {
    const inp = document.querySelector("#date");

    const key = event.keyCode || event.charCode;

    if (key !== 8 && key !== 46) {
      if (inp.value.length === 4) {
        inp.value = inp.value + "-";
      }
    }
    if (key !== 8 && key !== 46) {
      if (inp.value.length === 7) {
        inp.value = inp.value + "-";
      }
    }
    if ((key === 8 || key === 46) && inp.value.length > 7) {
      inp.value = inp.value.replace(/\/$/, "");
    }
  };

  const closepopup = () => {
    setShow(!show);
    setSelect(false);
    setUpload(true);
    setpreviewimg("");
    setcroppedImageUrl(null);
    setImageRef();
    setimgfile("");
  };

  const GenderDetails = useSelector((state) => state.Setting.PatGenderDetails);
  const MaritalStatus = useSelector((state) => state.Setting.PatMariDetails);
  const LanguageLists = useSelector((state) => state.Setting.PatLangDetails);
  const RaceDetails = useSelector((state) => state.Setting.PatRaceDetails);
  const PatDetails = useSelector((state) => state.TopNavbar.userDetails);
  const mailChecking = useSelector((state) => state.Setting.MailCheck);
  const Refresh = useSelector((state) => state.Setting.Refresh);
  const constate = useSelector((state) => state.Setting.PatientState);
  const [holdSave, setHoldSave] = useState(false);
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const FileHandleChange = (e) => {
    setpreviewimg("");

    let iSize = e.target.files[0].size / 1024;
    iSize = Math.round(iSize * 100) / 100;

    if (iSize > 2048) {
      toast(<Error msg="Please select a file less than 2mb" />, {
        containerId: "B",
        className: "error_badge",
      });

      e.target.value = "";
      return false;
    }

    var fileName = e.target.value.split("\\").pop();
    var extension = fileName.split(".").pop();
    const allowedExtensions = ["jpg", "jpeg", "png"];
    if (!allowedExtensions.includes(extension?.toLowerCase())) {
      toast(<Error msg="Please select a valid file" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    }
    setimgfile((prev) => {
      return (prev = e.target.files[0]);
    });
    if (fileName) {
      if (e.target.files && e.target.files.length > 40) {
        e.preventDefault();
        e.stopPropagation();
        setlabeltext(fileName.slice(0, 4) + "..." + extension);
      } else {
        setlabeltext(fileName);
      }
    } else {
      setlabeltext("add file");
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => setpreviewimg(reader.result));
    reader.readAsDataURL(e.target.files[0]);

    //setpreviewimg(URL.createObjectURL(e.target.files[0]));
  };

  const UploadFile = () => {
    setuploadbtndisabled(true);
    setTimeout(() => {
      setuploadbtndisabled(false);
    }, 1000);
    setSelect(true);
    setUpload(false);
    setlabeltext("add file");
    setpreviewimg("");
    setShow(true);
  };

  const CloseFile = () => {
    setSelect(false);
    setUpload(true);
    setpreviewimg("");
    setcroppedImageUrl(null);
    setImageRef();
    setimgfile("");
  };

  const UploadProfilePicture = () => {
    setsavepicbtndisabled(true);
    setTimeout(() => {
      setsavepicbtndisabled(false);
    }, 1500);

    if (imgfile === "" || imgfile === null) {
      toast(<Error msg="Please Select the File!." />, {
        containerId: "B",
        className: "error_badge",
      });

      return false;
    }

    setSelect(false);
    setUpload(true);

    const dataArray = new FormData();
    var file11 = dataURLtoFile(jpegFile, labeltext);
    dataArray.append("imgfile", file11);

    dispatch(GetPatientProfileUpdate(dataArray));
  };

  const RemoveProfilePicture = () => {
    dispatch(GetPatientProfileRemove());
    Setshowpop1(false);
    setpatimg(null);
    setimgfile(null);
  };

  const CancelPage = () => {
    Navigate("/");
  };

  const getcropimage = (e) => {
    if (imgfile === "") {
      closepopup();
    } else {
      setShow(!show);
    }
    makeClientCrop(crop);
    if (normalImg === false) {
      setNormalImg(true);
      makeClientCrop(crop);
      setpreviewimg("");
    }
  };

  const popclsbtn = () => {
    setlabeltext("add file");
    setpreviewimg("");
    setcroppedImageUrl(null);

    closepopup();
  };

  const OnSubmit = (e) => {
    e.preventDefault();
    let error = [];

    if (fname === "") {
      seterr(true);
      error = 2;
    }
    if (lname === "") {
      seterr1(true);
      error = 2;
    }

    const Errors = JSON.parse(JSON.stringify(validation));
    for (let i = 0; i < inputList2.length; i++) {
      if (inputList2[i].email === "") {
        Errors[i]["emailErr"] = true;
        Errors[i]["msg"] = "Enter the Mail ID";
        setValidation(Errors);

        error = 2;
      }
    }
    const Errors1 = JSON.parse(JSON.stringify(Errors));
    for (let i = 0; i < inputList2.length; i++) {
      if (inputList2[i].type === "") {
        Errors1[i]["typeErr"] = true;
        Errors1[i]["typemsg"] = "Select the Mail Type";
        setValidation(Errors1);
        error = 2;
      }
    }

    const allEmailErrorsFalse = validation.every(
      (obj) => obj.emailErr === false && obj.typeErr === false
    );

    if (!allEmailErrorsFalse) {
      error = 2;
    }
    let worKMailCheck = false;
    for (let i = 0; i < inputList2.length; i++) {
      if (inputList2[i].type === "work") {
        worKMailCheck = true;
        break;
      }
    }
    if (worKMailCheck === false) {
      setWorkMailErr(true);
      error = 6;
    } else {
      setWorkMailErr(false);
    }

    const PhoneErrs = JSON.parse(JSON.stringify(phoneValidation));
    for (let i = 0; i < inputList.length; i++) {
      if (inputList[i].phone === "") {
        PhoneErrs[i]["numberErr"] = true;
        PhoneErrs[i]["msg"] = "Enter the Phone Number";
        setPhoneValidation(PhoneErrs);
        error = 2;
      }
    }
    const PhoneErrs1 = JSON.parse(JSON.stringify(PhoneErrs));

    for (let i = 0; i < inputList.length; i++) {
      if (inputList[i].type === "") {
        PhoneErrs1[i]["typeErr"] = true;
        PhoneErrs1[i]["typemsg"] = "Select the Phone Type";
        setPhoneValidation(PhoneErrs1);
        error = 2;
      }
    }

    const allPhoneErrorsFalse = phoneValidation.every(
      (obj) => obj.numberErr === false && obj.typeErr === false
    );

    if (!allPhoneErrorsFalse) {
      error = 2;
    }
    if (error > 0) {
      return false;
    }

    dispatch(
      GetPatientDetailUpdate({
        fname: fname,
        mname: mname,
        lname: lname,
        title: title,
        ss: ss,
        email: inputList2,
        phone: inputList,
        street: street,
        city: city,
        active: active,
        state: state,
        postal_code: country_code,
        sex: sex1,
        providerID: providerID,
        status: status,
        language: language1,
        race: ethnicity1,
        ethnicity: ethnicity2,
        dob: fromdate,
      })
    );
  };

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      setNormalImg(false);
      const ImageUrl = await getCroppedImg(imageRef, crop, "newFile.jpeg");
      setcroppedImageUrl(ImageUrl);
    } else {
      setNormalImg(true);
    }
  }

  const getCroppedImg = (sourceImage, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      sourceImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    setjpegFile(canvas.toDataURL("image/jpeg"));
    return new Promise((resolve, reject) => {
      var fileUrl;
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          setCropblob(blob);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  };

  const HandleInputMobile = (e, index) => {
    if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
      const { name, value } = e.target;
      const list = [...inputList];
      const phoneErrs = JSON.parse(JSON.stringify(phoneValidation));
      if (list.filter((f) => f.phone === value).length > 0) {
        phoneErrs[index]["numberErr"] = true;
        phoneErrs[index]["msg"] = "You are Enter the Duplicate Number";
      } else {
        phoneErrs[index]["numberErr"] = false;
        phoneErrs[index]["msg"] = "";
      }

      list[index][name] = value;
      setInputList(list);
      setSelectedOptions(list);
      setPhoneValidation(phoneErrs);
    }
  };

  const HandleInputMobileType = (e, index, prevType) => {
    const phoneErrs = [...phoneValidation];
    phoneErrs[index]["typeErr"] = false;
    phoneErrs[index]["typemsg"] = "";
    setPhoneValidation(phoneErrs);

    const { value } = e.target; // Extract the value from the event
    setSelectedOptions([...selectedOptions, value]);
    const list = [...inputList];
    list[index]["type"] = value; // Assign the selected value to the list
    setInputList(list);
    setSelectedOptions(list);
  };

  const DropdownOptionList = [
    {
      title: "Hispanic or Latino",
      id: "1",
      Key: "Hispanic or Latino",
      value: "Hispanic or Latino",
    },
    {
      title: "Not Hispanic or Latino",
      id: "2",
      Key: "Not Hispanic or Latino",
      value: "Not Hispanic or Latino ",
    },
    {
      title: "Declined to specify",
      id: "3",
      Key: "Declined to specify",
      value: "Declined to specify ",
    },
  ];
  const Toggling = () => setDropDown((prevState) => !prevState);
  const Toggling1 = () => setDropDown1((prevState) => !prevState);
  const Toggling2 = () => setDropDown2((prevState) => !prevState);
  const Toggling3 = () => setDropDown3((prevState) => !prevState);
  const Toggling4 = () => setDropDown4((prevState) => !prevState);
  const Toggling5 = () => {
    setDropDown5((prevState) => !prevState);
    setTooltipVisible(false);
  };
  const setsexselect = (r) => {
    setsex1(r.title);
    setDropDown(false);
  };
  const setmaritalselect = (r) => {
    setstatus1(r.title);
    setstatus(r.option_id);
    setDropDown1(false);
  };
  const setlanguageselect = (r) => {
    setlanguage1(r.title);
    setDropDown2(false);
  };

  const setraceselect = (r) => {
    setethnicity1(r.title);
    setDropDown3(false);
  };

  const setethinicityselect = (r) => {
    setethnicity2(r.title);
    setDropDown4(false);
  };
  const setstateselect = (s) => {
    setstate(s.title);
    setDropDown5(false);
    setTooltipVisible(false);
  };

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };
  const HandleRemoveMobile = (index, PrevType) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setSelectedOptions(list);

    const errors = JSON.parse(JSON.stringify(phoneValidation));
    errors.splice(index, 1);

    setPhoneValidation(errors);
    if (PrevType === "mobile") setMobilePhone("");
    if (PrevType === "work") setWorkPhone("");
    if (PrevType === "home") setHomePhone("");
    if (PrevType === "contact") setContactPhone("");
  };
  const handleClick = (e) => {
    if (inputNotRef.current && !inputNotRef.current.contains(e.target)) {
      setDropDown(false);
    }
  };
  const handleClick1 = (e) => {
    if (maritalNotRef.current && !maritalNotRef.current.contains(e.target)) {
      setDropDown1(false);
    }
  };
  const handleClick2 = (e) => {
    if (languageNotRef.current && !languageNotRef.current.contains(e.target)) {
      setDropDown2(false);
    }
  };
  const handleClick3 = (e) => {
    if (raceNotRef.current && !raceNotRef.current.contains(e.target)) {
      setDropDown3(false);
    }
  };
  const handleClick4 = (e) => {
    if (
      ethinicity2NotRef.current &&
      !ethinicity2NotRef.current.contains(e.target)
    ) {
      setDropDown4(false);
    }
  };
  const handleClick5 = (e) => {
    if (stateNotRef.current && !stateNotRef.current.contains(e.target)) {
      setDropDown5(false);
    }
  };
  const HandleInputEmail = (e, index) => {
    setMailcheck(e.target.value);
    setHoldSave(true);
    let values, msg;
    const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (mailRegex.test(e.target.value)) {
      if (
        inputList2[0]?.email === e.target.value ||
        inputList2[1]?.email === e.target.value
      ) {
        msg = "You are Entering the Duplicate Email";

        values = true;
      } else if (
        inputList2[0]?.email !== e.target.value ||
        inputList2[1]?.email !== e.target.value
      ) {
        values = false;

        optimizedFn(e.target.value);
      }
    } else {
      values = true;
      msg = "Enter the Valid Mail ID";
    }
    setRowId(index);
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    setInputList2(list);
    setSelectedOptions2(list);

    const Errors = JSON.parse(JSON.stringify(validation));
    Errors[index]["emailErr"] = values;
    Errors[index]["msg"] = msg;
    setValidation(Errors);
  };
  const HandleInputEmailType = (e, index, PrevType) => {
    const { value } = e.target;
    setSelectedOptions2([...selectedOptions, value]);
    const list = [...inputList2];
    list[index]["type"] = value;

    setInputList2(list);
    setSelectedOptions2(list);

    if (value === "work") {
      setWorkMailErr(false);
    }

    const Errors = JSON.parse(JSON.stringify(validation));
    Errors[index]["typemsg"] = "";
    Errors[index]["typeErr"] = false;

    setValidation(Errors);
    setInputList2(list);
    setSelectedOptions2(list);
    if (PrevType === "work") setWorkEmail("");
    if (PrevType === "home") setHomeEmail("");
    if (value === "work") setWorkEmail("work");
    if (value === "home") setHomeEmail("home");
  };

  const HandleRemoveEmail = (index, PrevType) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
    setSelectedOptions2(list);
    const errors = JSON.parse(JSON.stringify(validation));
    errors.splice(index, 1);

    setValidation(errors);

    if (PrevType === "work") setWorkEmail("");
    if (PrevType === "home") setHomeEmail("");
  };

  const setPhoneNumbers = (work, home, contact, mobile) => {
    if (work !== "" && home !== "" && contact !== "" && mobile !== "") {
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "work" },
        { phone: "", type: "home" },
        { phone: "", type: "contact" },
      ];

      PhoneNumList[0]["phone"] = mobile;

      PhoneNumList[1]["phone"] = work;

      PhoneNumList[2]["phone"] = home;

      PhoneNumList[3]["phone"] = contact;
      let val = [];

      for (let i = 0; i < 3; i++) {
        val.push({ numberErr: false, typeErr: false, msg: "", typemsg: "" });
      }
      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("work");
      setHomePhone("home");
      setContactPhone("contact");
    }
    // === 1 === //
    else if (work === "" && home === "" && contact === "" && mobile !== "") {
      const PhoneNumList = [{ phone: "", type: "mobile" }];
      PhoneNumList[0]["phone"] = mobile;
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("");
      setHomePhone("");
      setContactPhone("");
    } else if (work === "" && home === "" && contact !== "" && mobile === "") {
      const PhoneNumList = [{ phone: "", type: "contact" }];
      PhoneNumList[0]["phone"] = contact;

      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("");
      setHomePhone("");
      setContactPhone("contact");
    } else if (work === "" && home !== "" && contact === "" && mobile === "") {
      const PhoneNumList = [...inputList];
      PhoneNumList[0]["phone"] = home;
      PhoneNumList[0]["type"] = "home";
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("");
      setHomePhone("home");
      setContactPhone("");
    } else if (work !== "" && home === "" && contact === "" && mobile === "") {
      const PhoneNumList = [{ phone: "", type: "work" }];
      PhoneNumList[0]["phone"] = work;

      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("work");
      setHomePhone("");
      setContactPhone("");
    }
    // === 2 === //
    else if (mobile !== "" && work !== "" && home === "" && contact === "") {
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "work" },
      ];
      PhoneNumList[0]["phone"] = mobile;
      PhoneNumList[1]["phone"] = work;
      let val = [];
      let obj = { numberErr: false, typeErr: false, msg: "", typemsg: "" };
      val.push(obj);

      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("work");
      setHomePhone("");
      setContactPhone("");
    } else if (mobile !== "" && work === "" && home !== "" && contact === "") {
      let val = [];
      let obj = { numberErr: false, typeErr: false, msg: "", typemsg: "" };
      val.push(obj);

      setPhoneValidation([...phoneValidation, ...val]);
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "home" },
      ];
      PhoneNumList[0]["phone"] = mobile;
      PhoneNumList[1]["phone"] = home;

      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("");
      setHomePhone("home");
      setContactPhone("");
    } else if (mobile !== "" && work === "" && home === "" && contact !== "") {
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "contact" },
      ];
      PhoneNumList[0]["phone"] = mobile;
      PhoneNumList[1]["phone"] = contact;
      let val = [];
      let obj = { numberErr: false, typeErr: false, msg: "", typemsg: "" };
      val.push(obj);

      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("");
      setHomePhone("");
      setContactPhone("contact");
    } else if (mobile === "" && work !== "" && home !== "" && contact === "") {
      const PhoneNumList = [
        { phone: "", type: "work" },
        { phone: "", type: "home" },
      ];
      PhoneNumList[0]["phone"] = work;
      PhoneNumList[1]["phone"] = home;
      let val = [];
      let obj = { numberErr: false, typeErr: false, msg: "", typemsg: "" };
      val.push(obj);

      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("work");
      setHomePhone("home");
      setContactPhone("");
    } else if (mobile === "" && work !== "" && home === "" && contact !== "") {
      const PhoneNumList = [
        { phone: "", type: "work" },
        { phone: "", type: "contact" },
      ];
      PhoneNumList[0]["phone"] = work;
      PhoneNumList[1]["phone"] = contact;
      let val = [];
      let obj = { numberErr: false, typeErr: false, msg: "", typemsg: "" };
      val.push(obj);

      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("work");
      setHomePhone("");
      setContactPhone("contact");
    } else if (mobile === "" && work === "" && home !== "" && contact !== "") {
      const PhoneNumList = [
        { phone: "", type: "home" },
        { phone: "", type: "contact" },
      ];
      PhoneNumList[0]["phone"] = home;
      PhoneNumList[1]["phone"] = contact;
      let val = [];
      let obj = { numberErr: false, typeErr: false, msg: "", typemsg: "" };
      val.push(obj);

      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("");
      setHomePhone("home");
      setContactPhone("contact");
    }
    // === 3 === //
    else if (mobile !== "" && work !== "" && home !== "" && contact === "") {
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "work" },
        { phone: "", type: "home" },
      ];
      PhoneNumList[0]["phone"] = mobile;
      PhoneNumList[1]["phone"] = work;
      PhoneNumList[2]["phone"] = home;
      let val = [];

      for (let i = 0; i < 2; i++) {
        val.push({ numberErr: false, typeErr: false, msg: "", typemsg: "" });
      }
      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("work");
      setHomePhone("home");
      setContactPhone("");
    } else if (mobile !== "" && work === "" && home !== "" && contact !== "") {
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "home" },
        { phone: "", type: "contact" },
      ];
      PhoneNumList[0]["phone"] = mobile;
      PhoneNumList[1]["phone"] = home;
      PhoneNumList[2]["phone"] = contact;
      let val = [];
      for (let i = 0; i < 2; i++) {
        val.push({ numberErr: false, typeErr: false, msg: "", typemsg: "" });
      }
      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("mobile");
      setWorkPhone("");
      setHomePhone("home");
      setContactPhone("contact");
    } else if (mobile !== "" && work !== "" && home === "" && contact !== "") {
      const PhoneNumList = [
        { phone: "", type: "mobile" },
        { phone: "", type: "work" },
        { phone: "", type: "contact" },
      ];
      PhoneNumList[0]["phone"] = mobile;
      PhoneNumList[1]["phone"] = work;
      PhoneNumList[2]["phone"] = contact;
      let val = [];
      for (let i = 0; i < 2; i++) {
        val.push({ numberErr: false, typeErr: false, msg: "", typemsg: "" });
      }
      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);

      setMobilePhone("mobile");
      setWorkPhone("work");
      setHomePhone("");
      setContactPhone("contact");
    } else if (mobile === "" && work !== "" && home !== "" && contact !== "") {
      const PhoneNumList = [
        { phone: "", type: "work" },
        { phone: "", type: "home" },
        { phone: "", type: "contact" },
      ];
      PhoneNumList[0]["phone"] = work;
      PhoneNumList[1]["phone"] = home;
      PhoneNumList[2]["phone"] = contact;
      let val = [];
      for (let i = 0; i < 2; i++) {
        val.push({ numberErr: false, typeErr: false, msg: "", typemsg: "" });
      }
      setPhoneValidation([...phoneValidation, ...val]);
      setInputList(PhoneNumList);
      setSelectedOptions(PhoneNumList);
      setMobilePhone("");
      setWorkPhone("work");
      setHomePhone("home");
      setContactPhone("contact");
    } else if (work === "" && home === "" && contact === "" && mobile === "") {
      setInputList(inputList);
      setSelectedOptions(inputList);
    }
  };

  const updateprofilestatustimeout = (alertData) => {
    if (alertData !== "0") {
      setupdateprofilestatus(0);
      dispatch(Getprofileupdatestatus());
    }
  };

  const setEmails = (work, home) => {
    if (
      work !== "" &&
      work !== undefined &&
      home !== "" &&
      home !== undefined
    ) {
      const EmailList = [
        { email: "", type: "work" },
        { email: "", type: "home" },
      ];
      EmailList[0]["email"] = work;
      EmailList[1]["email"] = home;
      setInputList2(EmailList);
      setSelectedOptions2(EmailList);
      let val = { emailErr: false, typeErr: false, msg: "", typemsg: "" };
      setValidation([...validation, val]);
      setWorkEmail("work");
      setHomeEmail("home");
    } else if (work !== "" && home === "") {
      const EmailList = [{ email: "", type: "work" }];

      EmailList[0]["email"] = work;
      setInputList2(EmailList);
      setSelectedOptions2(EmailList);
      setWorkEmail("work");
      setHomeEmail("");
    } else if (work === "" && home !== "") {
      const EmailList = [{ email: "", type: "home" }];

      EmailList[0]["email"] = home;
      setInputList2(EmailList);
      setSelectedOptions2(EmailList);
      setHomeEmail("home");
      setWorkEmail("");
    } else if (work === "" && home === "") {
      setInputList2(inputList2);
      setSelectedOptions2(inputList2);
    }
  };

  const handleKeyPress = (e) => {
    const regex = /^[a-zA-Z]+$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyPress1 = (e) => {
    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const formattedSSN = formatSSN(inputValue.slice(0, 9));
    setss(formattedSSN);
    if (ssnRegex.test(formattedSSN)) {
    } else {
    }
  };

  const formatSSN = (ssn) => {
    const cleanedSSN = ssn.replace(/\D/g, "");

    let formatted = cleanedSSN.slice(0, 3);

    if (cleanedSSN.length > 3) {
      formatted += "-" + cleanedSSN.slice(3, 5);
    }

    if (cleanedSSN.length > 5) {
      formatted += "-" + cleanedSSN.slice(5, 9);
    }

    return formatted;
  };
  useEffect(() => {
    if (Refresh === 2) {
      Setshowpop1(false);
      setpreviewimg(null);
    }
  }, [Refresh]);

  useEffect(() => {
    let index = parseInt(rowId);
    if (mailChecking === "allowed") {
      const Errors = JSON.parse(JSON.stringify(validation));
      const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (mailRegex.test(inputList2[index]["email"])) {
        Errors[index]["emailErr"] = false;
        Errors[index]["msg"] = "";
      } else {
        Errors[index]["emailErr"] = true;
        Errors[index]["msg"] = "Enter the Valid Mail ID";
      }
      setValidation(Errors);
      dispatch(SetEmailExist(""));
      setHoldSave(false);
    } else if (mailChecking === "exist") {
      const Errors = JSON.parse(JSON.stringify(validation));
      Errors[index]["emailErr"] = true;
      Errors[index]["msg"] = "Mail Id already Exists";
      setValidation(Errors);
      dispatch(SetEmailExist(""));
      setHoldSave(false);
    }
  }, [mailChecking]);

  const handleChange = (mailcheck) => {
    dispatch(GetEmailExist({ type: mailcheck }));
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  useEffect(() => {
    setfname(PatDetails.fname);
    setmname(PatDetails.mname);
    setlname(PatDetails.lname);
    settitle(PatDetails.title);
    setActive(PatDetails.active);
    setss(PatDetails.ss);
    setstreet(PatDetails.street);
    setcity(PatDetails.city);
    setstate(PatDetails.state);
    if (setstate) {
      constate.forEach((s) => {
        if (s.option_id === PatDetails.state || s.title === PatDetails.state) {
          setstate(s.title);
        }
      });
    }
    setcountry_code(PatDetails.postal_code);
    setFromDate(PatDetails.DOB);
    setsex1(PatDetails.sex);
    setethnicity1(PatDetails.race);
    setethnicity2(PatDetails.ethnicity);
    setlanguage1(PatDetails.language);
    setproviderID(PatDetails.providerID);
    setstatus1(PatDetails.status);
    setstatus(PatDetails.status);
    if (PatDetails.status) {
      MaritalStatus.forEach((s) => {
        if (s.option_id === PatDetails.status) {
          setstatus1(s.title);
        }
      });
    }
    setpatimg(PatDetails.img_url);
    setupdateprofilestatus(PatDetails.update_profile_status);
    setRevertReason(PatDetails.revert_reason);
    setPhoneNumbers(
      PatDetails.phone_biz,
      PatDetails.phone_home,
      PatDetails.phone_contact,
      PatDetails.phone_cell
    );
    setshowsave(PatDetails.showsave);

    setEmails(PatDetails.email, PatDetails.email_direct);
    dispatch(SetEmailExist(""));
    setTimeout(
      () => updateprofilestatustimeout(PatDetails.update_profile_status),
      5000
    );
  }, [PatDetails, constate]);
  useEffect(() => {
    document.addEventListener("mouseup", handleClick);
    document.addEventListener("mouseup", handleClick1);
    document.addEventListener("mouseup", handleClick2);
    document.addEventListener("mouseup", handleClick3);
    document.addEventListener("mouseup", handleClick4);
    document.addEventListener("mouseup", handleClick5);
    return () => {
      document.removeEventListener("mouseup", handleClick);
      document.removeEventListener("mouseup", handleClick1);
      document.removeEventListener("mouseup", handleClick2);
      document.removeEventListener("mouseup", handleClick3);
      document.removeEventListener("mouseup", handleClick4);
      document.removeEventListener("mouseup", handleClick5);
    };
  }, []);
  return (
    <>
      <Display display="flex" alignItems="center" mb="24px">
        {PatDetails.img_url === null ? (
          <Div
            border="1.5px solid #e9eef9"
            position="relative"
            height="70px"
            width="70px"
            minWidth="70px"
            minHeight="70px"
            borderRadius="50%"
            marginRight="20px"
            padding="4px"
          >
            <CurveImg
              position="absolute"
              top="-0.5px"
              left="38px"
              width="30px"
              height="30px"
              src={BlueCurve}
            />
            <Div
              background="#A8AEBE"
              height="100%"
              width="100%"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#FFFFFF"
              fontSize="24px"
              fontWeight="600"
            >
              {PatDetails.initials}
            </Div>
          </Div>
        ) : (
          <Div
            border="1.5px solid #e9eef9"
            position="relative"
            height="70px"
            width="70px"
            minWidth="70px"
            minHeight="70px"
            borderRadius="50%"
            marginRight="20px"
            padding="4px"
          >
            <CurveImg
              position="absolute"
              top="-0.5px"
              left="38px"
              width="30px"
              height="30px"
              src={BlueCurve}
            />
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={File_URL + patimg}
            />
          </Div>
        )}
        <Display display="inline">
          <Text
            fontSize="24px"
            fontWeight="700"
            color="#000000"
            mb="12px"
            lineHeight="33px"
            textAlign="left"
          >
            {fname} {lname}
          </Text>
          <Text
            fontFamily="Inter"
            fontWeight="400"
            fontSize="14px"
            color="#000000"
            lineHeight="23px"
            textAlign="left"
            mb="0px"
          >
            Set up your Patient Portal
          </Text>
        </Display>
      </Display>

      {UpdateStatus === "1" ? (
        <Displayerror
          display="flex"
          background="#77E0B5"
          width="100%"
          alignItems="center"
          pt="10px"
          pb="10px"
          justifyContent="center"
          color="#FFFFFF"
          mb="24px"
        >
          Profile updated successfully
        </Displayerror>
      ) : (
        <>
          {UpdateStatus === "2" ? (
            <Displayerror
              background="#FF7676"
              width="100%"
              alignItems="center"
              pt="10px"
              pb="10px"
              justifyContent="center"
              mb="24px"
            >
              <DisplayerrorText
                fontFamily="'Inter'"
                fontWeight="600"
                fontSize="16px"
                lineHeight="19px"
                textAlign="center"
                color="#ffffff"
              >
                Profile updated was Revert
              </DisplayerrorText>
              <DisplayerrorText
                fontFamily="Work Sans"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center"
                color="#ffffff"
                mt="4px"
              >
                {revertReason}
              </DisplayerrorText>
            </Displayerror>
          ) : (
            <></>
          )}
        </>
      )}
      <Display display="flex" mb="24px">
        {croppedImageUrl ? (
          <Div
            height="60px"
            width="60px"
            marginRight="20px"
            marginLeft="3px"
            border="2px solid #D3D3D3"
            borderRadius="50%"
          >
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={croppedImageUrl}
            />
          </Div>
        ) : patimg === null ? (
          <Div
            background="#A8AEBE"
            height="60px"
            width="60px"
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginRight="20px"
            border="2px solid #D3D3D3"
            color="#FFFFFF"
            fontSize="24px"
            fontWeight="600"
          >
            {PatDetails.initials}
          </Div>
        ) : (
          <Div
            height="60px"
            width="60px"
            marginRight="20px"
            marginLeft="3px"
            border="2px solid #D3D3D3"
            borderRadius="50%"
          >
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={File_URL + patimg}
            />
          </Div>
        )}
        <Div display="flex" alignItems="center" justifyContent="center">
          {select && (
            <>
              <Display>
                {/* {patimg !== null && (
                  <Button
                    padding="12px 18px"
                    background="#e7e7e9"
                    borderRadius="8px"
                    fontFamily="'Inter',sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    color="#000000"
                    marginBottom="11px"
                    marginTop="5px"
                    onClick={() => {
                      showpop1(true);
                    }}
                  >
                    Delete
                  </Button>
                )} 
                <br />
                <Inputbox
                  fontFamily="'Inter',sans-serif"
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="17px"
                  color="#0d0c22"
                  mb="12px"
                  type="file"
                  accept=".png,.jpg, .jpeg"
                  max-size="800000"
                  onChange={(e) => FileHandleChange(e)}
                />
                <Div
                  fontSize="14px"
                  color="#A3A3A3"
                  lineHeight="17px"
                  marginBottom="16px"
                >
                  JPG,JPEG or PNG. Max size of 800KB
                  </Div> */}
                <Display display="flex">
                  <Button
                    padding="12px 20px"
                    background="#2C7BE5"
                    hoverBackColor="#005FB2"
                    borderRadius="8px"
                    fontWeight="500"
                    fontSize="16px"
                    color=" #FFFFFF"
                    lineHeight="16px"
                    marginRight="16px"
                    className="save_active"
                    disabled={savepicbtndisabled}
                    onClick={UploadProfilePicture}
                  >
                    Save Picture
                  </Button>
                  <Button
                    color="#2C7BE5"
                    background="#FFF"
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    border="1px solid #2C7BE5"
                    borderRadius="4px"
                    fontFamily="'Inter',sans-serif"
                    padding="12px 16px"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="17px"
                    onClick={CloseFile}
                    disabled={uploadbtndisabled}
                  >
                    Cancel
                  </Button>
                </Display>
              </Display>
            </>
          )}

          {upload && (
            <>
              <Button
                padding="12px 20px"
                borderRadius="8px"
                background="#2C7BE5"
                hoverBackColor="#005FB2"
                fontWeight="500"
                lineHeight="16px"
                fontSize="16px"
                color="#FFFFFF"
                marginRight="23px"
                onClick={UploadFile}
                className="save_active"
                disabled={uploadbtndisabled}
              >
                Upload New Picture
              </Button>
              {patimg !== null && (
                <Button
                  padding="12px 18px"
                  background="#e7e7e9"
                  borderRadius="8px"
                  fontFamily="'Inter',sans-serif"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="17px"
                  color="#000000"
                  onClick={() => {
                    Setshowpop1(true);
                  }}
                >
                  Delete
                </Button>
              )}
            </>
          )}
        </Div>
      </Display>

      <Form>
        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="20%" marginRight="23px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              First Name
            </FormLabel>

            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              placeholder="Enter First Name"
              value={fname}
              onChange={(e) => {
                setfname(e.target.value);
                seterr(false);
              }}
              onKeyPress={handleKeyPress}
              id="edPf_inp-fnm"
            />
            {err && (
              <Text
                textAlign="left"
                color="red"
                fontSize="10px"
                fontWeight="400"
                mb="0px"
                mt="0px"
              >
                Enter the First Name
              </Text>
            )}
          </FormGroup>

          <FormGroup display="grid" width="20%" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Middle Name
            </FormLabel>
            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              placeholder="Enter Middle Name"
              value={mname}
              onChange={(e) => setmname(e.target.value)}
              onKeyPress={handleKeyPress}
              id="edPf_inp-mdnm"
            />
          </FormGroup>

          <FormGroup display="grid" width="20%">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Last Name
            </FormLabel>
            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              value={lname}
              onChange={(e) => {
                setlname(e.target.value);
                seterr1(false);
              }}
              onKeyPress={handleKeyPress}
              id="edPf_inp-lstnm"
            />
            {err1 ? (
              <Text
                textAlign="Left"
                color="red"
                fontSize="10px"
                fontWeight="400"
                mb="0px"
                mt="0px"
              >
                Enter the Last Name
              </Text>
            ) : (
              ""
            )}
          </FormGroup>
        </Display>

        <Display display="flex" mb="20px">
          <FormGroup display="grid" width="20%" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Suffix
            </FormLabel>
            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              value={title}
              onChange={(e) => settitle(e.target.value)}
              onKeyPress={handleKeyPress}
              id="edPf_inp-sfx"
            />
          </FormGroup>

          <FormGroup display="grid" width="20%" marginRight="23px">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              S.S
            </FormLabel>
            <Inputbox
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              paddingLeft="12px"
              value={ss}
              onChange={(e) => setss(e.target.value)}
              onKeyPress={handleKeyPress1}
              id="edPf_inp-ssvl"
            />
          </FormGroup>
        </Display>

        <Hrline ml="-40px" mb="23px" />

        <Text
          fontWeight="500"
          fontSize="16px"
          marginBottom="8px"
          textAlign="left"
          color="#2D3748"
          fontFamily="'Inter',san-serif"
        >
          Phone
        </Text>
        <Div
          fontFamily="'Inter',san-serif"
          lineHeight="15px"
          color="#718096"
          marginBottom="12px"
          fontSize="12px"
        >
          If you want to send this client text or voice reminders, add their
          phone number(s).
        </Div>
        {inputList.map((x, ind) => (
          <>
            <FormGroup display="grid" key={ind}>
              <FormLabel
                fontFamily="'Inter',san-serif"
                fontSize="14px"
                lineHeight="17px"
                color="#2d3748"
                marginBottom="8px"
                textAlign="left"
              >
                Phone Number
              </FormLabel>

              <Display display="flex" mb="12px">
                <Div
                  display="flex"
                  flexDirection="column"
                  width="20%"
                  marginRight="12px"
                >
                  <Inputbox
                    width="100%"
                    height="32px"
                    background="#ffffff"
                    border="1px solid #cbd5e0"
                    borderRadius="4px"
                    mr="12px"
                    paddingLeft="12px"
                    name="phone"
                    value={x.phone}
                    onChange={(e) => HandleInputMobile(e, ind)}
                    id={`edPf_inp-phNm${ind}`}
                  />
                  {phoneValidation[ind]?.numberErr === true ? (
                    <Text
                      textAlign="left"
                      color="red"
                      fontSize="10px"
                      fontWeight="400"
                      mb="0px"
                      mt="0px"
                    >
                      {phoneValidation[ind]?.msg}
                    </Text>
                  ) : (
                    ""
                  )}
                </Div>
                <Div
                  display="flex"
                  flexDirection="column"
                  width="10%"
                  marginRight="12px"
                >
                  <CustomDropdown
                    value={x.type}
                    onSelect={(value) =>
                      HandleInputMobileType({ target: { value } }, ind, x.type)
                    }
                    selectedOptions={selectedOptions}
                    options={[
                      { value: "", label: "Select", disabled: true },
                      {
                        value: "mobile",
                        label: "Mobile",
                      },
                      {
                        value: "work",
                        label: "Work",
                      },
                      {
                        value: "home",
                        label: "Home",
                      },
                      {
                        value: "contact",
                        label: "Contact",
                      },
                    ]}
                  />
                  {phoneValidation[ind]?.typeErr === true ? (
                    <Text
                      textAlign="left"
                      color="red"
                      fontSize="10px"
                      fontWeight="400"
                      mb="0px"
                      mt="0px"
                    >
                      {phoneValidation[ind]?.typemsg}
                    </Text>
                  ) : (
                    ""
                  )}
                </Div>

                {inputList.length !== 1 && (
                  <AiOutlineDelete
                    style={{
                      marginLeft: "45px",
                      color: "red",
                      height: "20px",
                      width: "20px",
                      paddingTop: "3px",
                      cursor: "pointer",
                      marginTop: "2px",
                    }}
                    onClick={() => HandleRemoveMobile(ind, x.type)}
                  />
                )}
              </Display>
            </FormGroup>
            {inputList.length - 1 === ind && (
              <>
                {ind < 3 && (
                  <Button
                    height="32px"
                    fontFamily="'Inter',san-serif"
                    fontSize="14px"
                    lineHeight="17px"
                    color="#2066c2"
                    background="#ffffff"
                    border="1px solid #2C7BE5"
                    className="close_active"
                    hoverBackColor="rgba(244, 246, 249, 0.75)"
                    borderRadius="4px"
                    marginBottom="16px"
                    alignItems="center"
                    justifyContent="space-evenly"
                    display="flex"
                    padding="8px 12px"
                    onClick={(e) => {
                      e.preventDefault();
                      setInputList([...inputList, { phone: "", type: "" }]);
                      setSelectedOptions([
                        ...inputList,
                        { phone: "", type: "" },
                      ]);
                      setPhoneValidation([
                        ...phoneValidation,
                        {
                          numberErr: false,
                          typeErr: false,
                          msg: "",
                          typemsg: "",
                        },
                      ]);
                    }}
                    id="edPf_btn-adPh"
                  >
                    <AiOutlinePlus />
                    Add Phone
                  </Button>
                )}
              </>
            )}
          </>
        ))}

        <Hrline ml="-40px" mt="0px" mb="16px" />
        <Text
          fontWeight="500"
          fontSize="16px"
          marginBottom="9px"
          textAlign="left"
        >
          Email
        </Text>

        <Div
          fontFamily="'Inter',san-serif"
          fontSize="12px"
          lineHeight="15px"
          color="#718096"
          marginBottom="12px"
        >
          If you want to send this client email reminders or billing documents,
          and to grant them Client Portal access, add their email address.
        </Div>
        {inputList2.map((x, i) => {
          return (
            <>
              <Display display="inline" mb="20px" key={i}>
                <Text
                  fontFamily="'Inter',san-serif"
                  fontWeight="400"
                  fontSize="14px"
                  color="#2d3748"
                  marginBottom="8px"
                  textAlign="left"
                >
                  Email ID
                </Text>
                <Display display="flex" mb="12px">
                  <Div
                    display="flex"
                    flexDirection="column"
                    width="20%"
                    marginRight="12px"
                  >
                    <Tooltip>
                      <TooltipText className="TooltipText">
                        {x.email}
                      </TooltipText>
                      <Inputbox
                        name="email"
                        id={`email edPf_inp-mail_id${i}`}
                        height="32px"
                        width="100%"
                        background="#ffffff"
                        border="1px solid #cbd5e0"
                        borderRadius="4px"
                        paddingLeft="12px"
                        textOverflow="ellipsis"
                        value={x.email}
                        onChange={(e) => HandleInputEmail(e, i)}
                      />
                    </Tooltip>
                    {(validation[i]?.emailErr && !workMailErr) ||
                    (workMailErr && inputList2.length - 1 !== i) ? (
                      <Text
                        textAlign="left"
                        color="red"
                        fontSize="10px"
                        fontWeight="400"
                        mb="0px"
                        mt="0px"
                      >
                        {validation[i]?.msg}
                      </Text>
                    ) : (
                      ""
                    )}
                  </Div>
                  <Div
                    display="flex"
                    flexDirection="column"
                    width="10%"
                    marginRight="12px"
                  >
                    <CustomDropdown
                      value={x.type}
                      onSelect={(value) =>
                        HandleInputEmailType({ target: { value } }, i, x.type)
                      }
                      selectedOptions={selectedOptions2}
                      options={[
                        { value: "", label: "Select", disabled: true },
                        {
                          value: "work",
                          label: "Work",
                        },
                        {
                          value: "home",
                          label: "Home",
                        },
                      ]}
                    />
                    {(validation[i]?.typeErr === true && !workMailErr) ||
                    (workMailErr && inputList2.length - 1 !== i) ? (
                      <Text
                        textAlign="left"
                        color="red"
                        fontSize="10px"
                        fontWeight="400"
                        mb="0px"
                        mt="0px"
                      >
                        {validation[i]?.typemsg}
                      </Text>
                    ) : (
                      ""
                    )}
                  </Div>

                  {inputList2.length !== 1 && (
                    <AiOutlineDelete
                      style={{
                        marginLeft: "45px",
                        color: "red",
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginTop: "4px",
                      }}
                      onClick={() => HandleRemoveEmail(i, x.type)}
                    />
                  )}
                </Display>
              </Display>
              {workMailErr && inputList2.length - 1 === i && (
                <Display display="flex" mb="12px">
                  <Text
                    textAlign="left"
                    color="red"
                    fontSize="10px"
                    fontWeight="400"
                    mb="0px"
                    mt="0px"
                  >
                    {"Please add email of type work"}
                  </Text>
                </Display>
              )}

              {inputList2.length - 1 === i && (
                <>
                  {i < 1 && (
                    <Button
                      padding="8px 19px"
                      height="32px"
                      fontFamily="'Inter',san-serif"
                      fontWeight="400"
                      fontSize="14px"
                      lineHeight="17px"
                      color="#2066c2"
                      background="#ffffff"
                      border="1px solid #2C7BE5"
                      hoverBackColor="rgba(244, 246, 249, 0.75)"
                      borderRadius="4px"
                      marginBottom="20px"
                      alignItems="center"
                      justifyContent="space-evenly"
                      className="close_active"
                      display="flex"
                      onClick={(e) => {
                        e.preventDefault();
                        setInputList2([...inputList2, { email: "", type: "" }]);
                        setSelectedOptions2([
                          ...inputList2,
                          { email: "", type: "" },
                        ]);
                        setValidation([
                          ...validation,
                          {
                            emailErr: false,
                            typeErr: false,
                            msg: "",
                            typemsg: "",
                          },
                        ]);
                      }}
                      id="edPf_btn-add_emil"
                    >
                      <AiOutlinePlus />
                      Add Email
                    </Button>
                  )}
                </>
              )}
            </>
          );
        })}

        <Hrline ml="-40px" mt="0px" mb="16px" />

        <Text
          fontFamily="'Inter',san-serif"
          fontWeight="500"
          fontSize="16px"
          marginBottom="8px"
          textAlign="left"
        >
          Address
        </Text>

        <Div
          fontFamily="'Inter',san-serif"
          fontSize="12px"
          lineHeight="15px"
          color="#718096"
          marginBottom="12px"
        >
          If you want to send this client email reminders or billing documents,
          and to grant them Client Portal access, add their email address.
        </Div>

        <FormGroup display="grid" marginBottom="12px">
          <FormLabel
            fontFamily="'Inter',san-serif"
            fontSize="14px"
            lineHeight="17px"
            color="#2d3748"
            marginBottom="8px"
            textAlign="left"
          >
            Street
          </FormLabel>
          <Inputbox
            width="376px"
            height="32px"
            background="#ffffff"
            border="1px solid #cbd5e0"
            borderRadius="4px"
            paddingLeft="12px"
            value={street}
            onChange={(e) => setstreet(e.target.value)}
          />
        </FormGroup>
        <Display display="flex">
          <FormGroup display="flex" flexDirection="column" marginRight="5px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              City
            </FormLabel>
            <Inputbox
              paddingLeft="12px"
              width="160px"
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              value={city}
              onChange={(e) => {
                let a = /[0-9 ]/i.test(e.target.value);
                if ((isNaN(e.target.value) && !a) || e.target.value === "") {
                  setcity(e.target.value);
                }
              }}
              id="my_pf-inp_cty"
            />
          </FormGroup>

          <FormGroup display="flex" flexDirection="column" marginRight="5px">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              State
            </FormLabel>

            <Dropdownstyle marginTop="0px" ref={stateNotRef}>
              <Div
                onClick={Toggling5}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <SelectInput
                  width="100px"
                  height="32px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="sex"
                  fontFamily="Inter"
                  fontWeight="400"
                  letterSpacing="0.0025em"
                  color="#747474"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  padding="0px 12px"
                  value={state}
                  id="my_pf-inp_sta"
                >
                  <Div
                    width="72px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    color="#747474"
                  >
                    {state || "Select"}
                  </Div>
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="6px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {tooltipVisible ? (
                <TooltipComponent
                  marginTop="4px"
                  background="#f5f5f5"
                  color="rgb(45,55,72)"
                  height="auto"
                  width="auto"
                  fontSize="12px"
                  fontWeight="400"
                  border="1px solid rgba(0,0,0,0.125)"
                  borderRadius="4px"
                  padding="2px 10px 3px 10px"
                  backgroundClip="border-box"
                >
                  {state ? state : "Select"}
                </TooltipComponent>
              ) : null}
              {dropDown5 && constate.length > 0 ? (
                <DropDownDiv
                  width="100px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {state !== "" && state !== null
                    ? constate.map((s, i) =>
                        s.option_id.toLowerCase() === state.toLowerCase() ? (
                          <DropdownOptions
                            padding="2px 1px 2px 1px"
                            fontSize="12px"
                            key={`3g${i}`}
                            value={s.title}
                            id={`adCon_sta${i}`}
                            onClick={() => setstateselect(s)}
                          >
                            {s.title}
                          </DropdownOptions>
                        ) : (
                          <DropdownOptions
                            padding="2px 1px 2px 1px"
                            fontSize="12px"
                            key={`3g${i}`}
                            value={s.title}
                            id={`adCon_sta${i}`}
                            onClick={() => setstateselect(s)}
                          >
                            {s.title}
                          </DropdownOptions>
                        )
                      )
                    : constate.map((s, i) => (
                        <DropdownOptions
                          padding="2px 1px 2px 1px"
                          fontSize="12px"
                          key={`3g1${i}`}
                          value={s.title}
                          id={`adCon_sta${i}`}
                          onClick={() => setstateselect(s)}
                        >
                          {s.title}
                        </DropdownOptions>
                      ))}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Dropdownstyle>
          </FormGroup>

          <FormGroup display="flex" flexDirection="column">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Zip
            </FormLabel>
            <Inputbox
              width="100px"
              paddingLeft="12px"
              height="32px"
              background="#ffffff"
              border="1px solid #cbd5e0"
              borderRadius="4px"
              value={country_code}
              onChange={(e) => {
                if (!isNaN(e.target.value) && !e.target.value.includes(".")) {
                  setcountry_code(e.target.value);
                }
              }}
              id="my_pf-inp_zp"
            />
          </FormGroup>
        </Display>

        <Hrline ml="-40px" mt="16px" mb="16px" />
        <Text
          fontWeight="500"
          fontSize="16px"
          marginBottom="12px"
          textAlign="left"
          color="#2D3748"
        >
          About Patient
        </Text>
        <Display display="flex" mb="20px">
          <FormGroup display="grid">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Date of Birth
            </FormLabel>
            <Div
              display="flex"
              position="relative"
              width="220px"
              marginRight="24px"
              alignItems="center"
            >
              <CustomDatePicker
                id="CustomDatePicker"
                width="220px"
                height="32px"
                backgroundColour="#f7f7f7"
                border="1px solid rgba(34,36,38,.15)"
                placeholder="DD-MM-YYYY"
                value={
                  fromdate !== "" && fromdate
                    ? moment(fromdate).format("DD-MM-YYYY")
                    : ""
                } //used in application
                onChange={(e) => {
                  let d = moment(e.target.value, "YYYY-MM-DD", true).isValid();
                  setFromDate(e.target.value);
                  if (e.target.value === "") {
                    setValidDate("");
                  } else if (d) {
                    setValidDate(e.target.value);
                  }
                }}
                onClick={(e) => handlepicker(e)}
                onKeyPress={(event) => {
                  if (
                    !/[0-9]/.test(event.key) ||
                    event.target.value.length > 9
                  ) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(e) => keyDownExpiry(e)}
                onResultSelect={dateSelect} // state updation for picking calendar dates
                showPicker={showpicker}
                validDate={validDate} // value in date object for calendar manipulation
                closePicker={closePicker}
                max={moment(new Date(), "DD/MM/YYYY")}
              ></CustomDatePicker>
              <Imagecalendar
                id="CustomDatePicker"
                onClick={(e) => handlepicker(e)}
                src={calendar_icon_custom}
              ></Imagecalendar>
            </Div>
          </FormGroup>
          <FormGroup display="grid">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              Sex
            </FormLabel>
            <Dropdownstyle marginTop="0px" ref={inputNotRef}>
              <Div onClick={Toggling}>
                <SelectInput
                  width="220px"
                  height="30px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="sex"
                  fontFamily="Inter"
                  fontWeight="400"
                  letterSpacing="0.0025em"
                  color="#747474"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={sex1}
                  id="my_pf-pat_sx"
                >
                  {sex1 || "Select Gender"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {dropDown && GenderDetails.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                >
                  {GenderDetails.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={i}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setsexselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Dropdownstyle>
          </FormGroup>
          <FormGroup display="grid">
            <FormLabel
              fontFamily="'Inter',san-serif"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="0px"
              textAlign="left"
            >
              Marital Status
            </FormLabel>
            <Dropdownstyle marginTop="0px" ref={maritalNotRef}>
              <Div onClick={Toggling1}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#FFFFFF"
                  name="marital"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={status1}
                  id="mypf_pat-mri_sta"
                >
                  {status1 || "Select Marital"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {dropDown1 && MaritalStatus.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                >
                  {MaritalStatus.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={i}
                        value={r.title}
                        padding="7px 12px 7px 12px"
                        onClick={() => setmaritalselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Dropdownstyle>
          </FormGroup>
        </Display>
        <Display display="flex" mb="20px">
          <FormGroup display="grid">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Language
            </FormLabel>
            <Dropdownstyle marginTop="0px" ref={languageNotRef}>
              <Div onClick={Toggling2}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#ffffff"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  name="language"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={language1}
                  id="mypf_pat-lng"
                >
                  {language1 ? language1 : "Select Language"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {dropDown2 && LanguageLists.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {LanguageLists.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={`${i}lang`}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setlanguageselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Dropdownstyle>
          </FormGroup>
          <FormGroup display="grid">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Race
            </FormLabel>
            <Dropdownstyle marginTop="0px" ref={raceNotRef}>
              <Div onClick={Toggling3}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#ffffff"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  name="race"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={ethnicity1}
                  id="mypf_pat-race"
                >
                  {ethnicity1 || "Select Race"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {dropDown3 && RaceDetails.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {RaceDetails.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={`${i}ethnicity`}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setraceselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Dropdownstyle>
          </FormGroup>

          <FormGroup display="grid">
            <FormLabel
              fontFamily="Inter"
              fontWeight="400"
              fontSize="14px"
              lineHeight="17px"
              color="#2d3748"
              marginBottom="8px"
              textAlign="left"
            >
              Ethnicity
            </FormLabel>
            <Dropdownstyle marginTop="0px" ref={ethinicity2NotRef}>
              <Div onClick={Toggling4}>
                <SelectInput
                  width="220px"
                  height="30px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="17px"
                  letterSpacing="0.0025em"
                  color="#747474"
                  background="#ffffff"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  name="ethnicity"
                  margin="0px 24px 0px 0px"
                  padding="0px 12px"
                  value={ethnicity2}
                  id="mypf_pat-ethn"
                >
                  {ethnicity2 || "Select Ethnicity"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    top="11px"
                    right="10px"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>
              {dropDown4 && DropdownOptionList.length > 0 ? (
                <DropDownDiv
                  width="220px"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                  bottom="31px"
                >
                  {DropdownOptionList.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={`${i}ethnicity`}
                        value={r.title}
                        padding="10px 12px 8px 12px"
                        onClick={() => setethinicityselect(r)}
                      >
                        {r.title}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </Dropdownstyle>
          </FormGroup>
        </Display>

        <Hrline ml="-40px" mt="0px" mb="20px" />
        <Display display="flex" pb="20px">
          <Button
            padding="8px 16px"
            height="32px"
            border=" 1px solid #2C7BE5"
            background=" #FFF"
            hoverBackColor="rgba(244, 246, 249, 0.75)"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            color="#2C7BE5"
            marginRight="20px"
            className="close_active"
            onClick={CancelPage}
          >
            Cancel
          </Button>
          <Button
            padding="7px 32px 9px 32px"
            height="32px"
            background="#2C7BE5"
            hoverBackColor="#005FB2"
            borderRadius="4px"
            fontFamily="'Inter',san-serif"
            fontWeight="500"
            lineHeight="17px"
            disabled={(showsave || holdSave) && true}
            color="#ffffff"
            border="none"
            className="save_active"
            title={holdSave ? "Please Wait while validating your mail" : ""}
            onClick={OnSubmit}
            id="edPr_btn-sv"
          >
            {showsave ? "Waiting For Review" : "Save"}
          </Button>
        </Display>
      </Form>

      <Modal width="100%" height="100%" show={showpop1}>
        <ModalContainer justifyContent="center" alignItems="flex-start">
          <ModalDialog width="377px">
            <ModalHeader
              padding="20px 20px"
              borderLess
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="16px"
                fontSize="18px"
                fontWeight="400"
                color="#2E2C34"
                fontFamily="'Inter',Sans-Serif"
              >
                Deleting avatar. Continue?
              </ModalTitle>
            </ModalHeader>
            <ModalBody
              padding="0px 20px 20px 20px"
              lineHight="16px"
              fontSize="16px"
              fontWeight="400"
              color="#6A6A6A"
              fontFamily="'Inter',Sans-Serif"
            >
              Deleting avatar. Continue?
            </ModalBody>
            <ModalFooter padding="0px 20px 20px 20px" borderTop="none">
              <Div display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  padding="8px 16px"
                  height="32px"
                  background="#FFFFFF"
                  border="1px solid #2C7BE5"
                  borderRadius="4px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  color="#2C7BE5"
                  marginRight="20px"
                  fontSize="16px"
                  className="close_active"
                  onClick={() => {
                    Setshowpop1(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  padding="7px 32px 9px 32px"
                  height="32px"
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  borderRadius="4px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  color="#FFFFFF"
                  border="none"
                  fontSize="16px"
                  className="save_active"
                  onClick={RemoveProfilePicture}
                >
                  Ok
                </Button>
              </Div>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>

      <Modal width="100%" height="100%" show={show}>
        <ModalContainer justifyContent="center" alignItems="center">
          <ModalDialog animation={ModalAnimat} padding="32px" width="414px">
            <Div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="20px"
                fontWeight="600"
                color="#0a1629"
              >
                Upload Documents
              </ModalTitle>

              <BsXLg
                style={{
                  color: "#407BFF",
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={closepopup}
              />
            </Div>

            <DragDrop>
              {!previewimg && (
                <Text
                  fontSize="16px"
                  lineHeight=" 22px"
                  color="#263446"
                  marginBottom="20px"
                  mt="20px"
                  id="dropContainer"
                  style={{ border: "1px solid black;height:100px" }}
                >
                  Drag &amp; Drop
                </Text>
              )}
              {previewimg ? (
                <ReactCrop
                  src={previewimg}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={(imageRef) => setImageRef(imageRef)}
                  onComplete={(crop) => makeClientCrop(crop)}
                  onChange={(crop) => SetCrop(crop)}
                  crossorigin="anonymous"
                />
              ) : (
                <Image
                  loading="lazy"
                  width="45px"
                  margin="0px auto 0px auto"
                  src={UploadFile1}
                />
              )}
              {!previewimg && (
                <>
                  <FileInput
                    type="file"
                    id="fileInput"
                    multiple
                    onChange={(e) => FileHandleChange(e)}
                    accept=".png, .jpg, .jpeg"
                  />{" "}
                  <Text
                    fontSize="12px"
                    color="#7d8592"
                    marginBottom="20px"
                    mt="30px"
                  >
                    JPG,PNG or JPEG Smaller than 2 MB or browse for a file to
                    upload
                  </Text>
                </>
              )}
              {/* {!croppedImageUrl && previewimg && (
                  <Text
                    fontSize="12px"
                    color="#7d8592"
                    marginBottom="20px"
                    mt="20px"
                  >
                    {labeltext}
                  </Text>
                )} */}
            </DragDrop>

            <ModalFooter marginTop="20px" borderTop="none">
              <Div display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  padding="8px 16px"
                  height="32px"
                  borderRadius="4px"
                  color=" #2C7BE5"
                  border=" 1px solid #2C7BE5"
                  background=" #FFF"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  marginRight="20px"
                  fontSize="16px"
                  className="close_active"
                  onClick={popclsbtn}
                >
                  Cancel
                </Button>
                <Button
                  padding="7px 32px 9px 32px"
                  height="32px"
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  borderRadius="4px"
                  fontFamily="'Work Sans',sans-serif"
                  fontWeight="500"
                  lineHeight="17px"
                  color="#FFFFFF"
                  border="none"
                  fontSize="16px"
                  className="save_active"
                  onClick={getcropimage}
                >
                  Ok
                </Button>
              </Div>
            </ModalFooter>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default EditProfile;
