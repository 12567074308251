import { call, put } from "redux-saga/effects";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

import { LoginPass } from "../../StateManagement/Reducers/LoginState";

import { LoginApi } from "../Apis/LoginApi";

export function* LoginCheck({ payload }) {
  const credential = Encrypt_Value(payload, "vozoportal");
  try {
    const res = yield call(LoginApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozoportal");
      yield put(LoginPass(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
