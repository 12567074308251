import React from "react";

import SignUpRight from "../SignUpRight";
import LoginLeft from "../LoginLeft";

import { Container } from "../StyledComponents";
import { Div } from "../Styles";

const SignUp = () => {
  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" bg_color="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" bg_color="#FFFFFF" overflowY="auto">
        <SignUpRight />
      </Div>
    </Container>
  );
};

export default SignUp;
