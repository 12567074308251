import { createSlice } from "@reduxjs/toolkit";

export const InsuranceSlice = createSlice({
  name: "Insurance",
  initialState: {
    insuranceDetails: [],
    DeviceRes: "",
    InsertProfile: "",
    RemoveProfile: "",
    InsuranceNames: [],
    SingleInsuranceData: [],
    ChangedStatus: "",
    DeleteResponses: "",
    // SavedInsuranceDetails:{}
  },
  reducers: {
    SetInsuranceDetail: (state, action) => {
      state.InsuranceDetails = action.payload;
    },
    GetInsuranceDetail: (data) => {
      return data;
    },

    postSingleInsDetail: (data) => {
      return data;
    },
    // SetSingleInsDetail: (state, action) => {
    //   state.SavedInsuranceDetails = action.payload;
    // },
    // GetSingleInsDetail: (data) => {
    //   return data;
    // },
    DeviceResponse: (state, action) => {
      state.DeviceRes = action.payload;
    },

    userImageUpload: (data) => {
      return data;
    },
    SetUpdateProfile: (state, action) => {
      state.InsertProfile = action.payload;
    },
    GetUpdateProfile: (data) => {
      return data;
    },
    SetRemoveProfile: (state, action) => {
      state.RemoveProfile = action.payload;
    },
    GetRemoveProfile: (data) => {
      return data;
    },

    SetInsuranceName: (state, action) => {
      state.InsuranceNames = action.payload;
    },
    GetInsuranceName: (data) => {
      return data;
    },

    SetSingleInsurance: (state, action) => {
      state.SingleInsuranceData = action.payload;
    },
    GetSingleInsurance: (data) => {
      return data;
    },

    SetChangedStatus: (state, action) => {
      state.ChangedStatus = action.payload;
    },
    GetChangedStatus: (data) => {
      return data;
    },
    SetDeleteInsurance: (state, action) => {
      state.DeleteResponses = action.payload;
    },
    GetDeleteInsurance: (data) => {
      return data;
    },

    SetRelationTypes: (state, action) => {
      state.RelationTypes = action.payload;
    },
    GetRelationTypes: (data) => {
      return data;
    },
  },
});

export const {
  SetInsuranceDetail,
  GetInsuranceDetail,
  postSingleInsDetail,
  // SetSingleInsDetail,
  // GetSingleInsDetail,
  DeviceResponse,
  SetUpdateProfile,
  GetUpdateProfile,
  SetRemoveProfile,
  GetRemoveProfile,
  SetInsuranceName,
  GetInsuranceName,
  SetSingleInsurance,
  GetSingleInsurance,
  SetChangedStatus,
  GetChangedStatus,
  SetDeleteInsurance,
  GetDeleteInsurance,
  SetRelationTypes,
  GetRelationTypes,
} = InsuranceSlice.actions;

export default InsuranceSlice.reducer;
