import { createSlice } from "@reduxjs/toolkit";

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    BillLoading: false,
    MedicationLoading: false,
    PayementBalance: [],
    Medication: [],
    Welcome: [],
    Login: [],
    LoginExist: [],
    removeObject: {
      buttonIndex: 0,
      cardIndex: 0,
    },
  },
  reducers: {
    GetPayBal: (state) => {
      state.BillLoading = false;
    },
    SetPayBal: (state, action) => {
      state.PayementBalance = action.payload;
      state.BillLoading = false;
    },
    GetMedication: (state) => {
      state.MedicationLoading = false;
    },
    SetMedication: (state, action) => {
      state.Medication = action.payload;
      state.MedicationLoading = false;
    },

    GetWelcome: (state) => {
      state.WelcomeLoading = false;
    },
    SetWelcome: (state, action) => {
      state.Welcome = action.payload;
      state.WelcomeLoading = false;
    },
    GetLogin: (state) => {
      state.LoginLoading = false;
    },
    SetLogin: (state, action) => {
      state.Login = action.payload;
      state.LoginLoading = false;
    },
    GetLoginExist: (state) => {
      state.LoginExistLoading = false;
    },
    SetLoginExist: (state, action) => {
      state.LoginExist = action.payload;
      state.LoginExistLoading = false;
    },

    changeRemoveID: (state, action) => {
      state.removeObject[action.payload.name] = action.payload.value;
    },
  },
});

export const {
  GetUserDetails,
  setGetUserDetails,
  GetPayBal,
  SetPayBal,
  GetMedication,
  SetMedication,
  GetWelcome,
  SetWelcome,
  GetLogin,
  SetLogin,
  GetLoginExist,
  SetLoginExist,

  changeRemoveID,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
