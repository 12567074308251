import React, { useCallback, useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetHomeWorkLists } from "../../StateManagement/Reducers/HomeWorkState";

import TopNav from "../TopNav";
import DocumentView from "./DocumentView";
import VideoView from "./VideoView";
import Pagination from "../StyledComponents/Pagination";
import Loading from "../StyledComponents/Loading";
import ListView from "./ListView";
import GridView from "./GridView";

import { BodyContainer, Modal } from "../StyledComponents";
import {
  Flexbetween,
  FlexCenter,
  IconStyle,
  InputBox,
  Label,
  ViewFlexBox,
} from "./Styles";
import { BsListUl, BsGrid } from "react-icons/bs";

const HomeWork = () => {
  const dispatch = useDispatch();
  //--------------------------------- State Start ---------------------------------//

  const [myState, setMyState] = useState({
    view: "Grid",
    listIs: false,
    gridIs: true,
    show: false,
    showdoc: false,
    url: "",
    docTitle: "",
    search: "",
    watchedId: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [url, setUrl] = useState("");
  const [docTitle, setDocTitle] = useState("");
  const [watchedId, setWatchedId] = useState(null);

  const DocumentsList = useSelector((state) => state.HomeWork.HomeWorkList);
  const LoadingIS = useSelector((state) => state.HomeWork.Loading);

  var width = window.innerWidth;
  var height = window.innerHeight;

  var RoundHeight = parseInt((height - 150) / 443);

  var calc = ((width - 330) / 360) * RoundHeight;

  var RoundCalc = parseInt(calc);

  // var RoundCalc = width >= 3200 ? parseInt(calc) : calc;

  var indexOfLastPost = currentPage * RoundCalc;
  var indexOfFirstPost = indexOfLastPost - RoundCalc;
  var currentPosts = DocumentsList.slice(indexOfFirstPost, indexOfLastPost);
  var howManyPages = Math.ceil(DocumentsList.length / RoundCalc);

  var indexOfLastList = currentPage * (RoundCalc * 2);
  var indexOfFirstList = indexOfLastList - RoundCalc * 2;
  var currentPostsList = DocumentsList.slice(indexOfFirstList, indexOfLastList);
  var howManyPagesList = Math.ceil(DocumentsList.length / (RoundCalc * 2));
  //---------------------------------- State End ----------------------------------//

  //----------------------------- Functionality Start -----------------------------//

  const handleChange = (value) => {
    dispatch(GetHomeWorkLists(value));
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const handleOpen = (item) => {
    setUrl(item.fileUrl);
    setDocTitle(item.title);
    setWatchedId(item.id);
    if (item.fileType === "Video") {
      setMyState({ ...myState, show: true, showdoc: false });
    } else if (item.fileType === "Worksheet") {
      setMyState({ ...myState, show: false, showdoc: true });
    }
  };

  //----------------------------- Functionality End -----------------------------//

  //------------------------------ UseEffectt Start ------------------------------//

  useEffect(() => {
    dispatch(GetHomeWorkLists(""));
  }, []);

  //------------------------------ UseEffectt End ------------------------------//

  return (
    <>
      <TopNav title="Home Work" />
      <BodyContainer paddingLeft="40px" paddingTop="4px" paddingBottom="10px">
        <Flexbetween margin="10px 16px -13px 16px">
          <FlexCenter>
            <Label color="#718096">Title / Description / Notes / Tag:</Label>
            <InputBox
              placeholder="search"
              value={myState.search}
              onChange={(e) => {
                setMyState({ ...myState, search: e.target.value });
                optimizedFn(e.target.value);
              }}
            />
          </FlexCenter>
          <ViewFlexBox>
            <IconStyle
              active={myState.gridIs}
              onClick={() => {
                setMyState({
                  ...myState,
                  listIs: false,
                  gridIs: true,
                  view: "Grid",
                });
              }}
            >
              <BsGrid />
              <Label>Grid</Label>
            </IconStyle>
            <IconStyle
              active={myState.listIs}
              onClick={() => {
                setMyState({
                  ...myState,
                  listIs: true,
                  gridIs: false,
                  view: "List",
                });
              }}
            >
              <BsListUl />
              <Label>List</Label>
            </IconStyle>
          </ViewFlexBox>
        </Flexbetween>
        {LoadingIS ? (
          <Loading />
        ) : (
          <>
            {myState.view === "List" ? (
              <>
                <ListView
                  data={currentPostsList}
                  setMyState={setMyState}
                  handleOpen={handleOpen}
                />
                {(currentPostsList.length !== 0 || howManyPagesList >= 1) && (
                  <Pagination
                    pages={howManyPagesList}
                    setCurrentPage={setCurrentPage}
                    view={myState.view}
                  />
                )}
              </>
            ) : (
              <>
                <GridView
                  data={currentPosts}
                  setMyState={setMyState}
                  handleOpen={handleOpen}
                  size={RoundCalc}
                />
                {(currentPosts.length !== 0 || howManyPages > 1) && (
                  <Pagination
                    pages={howManyPages}
                    setCurrentPage={setCurrentPage}
                    view={myState.view}
                  />
                )}
              </>
            )}
          </>
        )}
      </BodyContainer>

      {myState.show && (
        <Modal width="100%" height="100%" show={myState.show}>
          <VideoView
            myState={myState}
            setMyState={setMyState}
            url={url}
            clearId={setWatchedId}
            id={watchedId}
          />
        </Modal>
      )}

      {myState.showdoc && (
        <Modal width="100%" height="100%" show={myState.showdoc}>
          <DocumentView
            myState={myState}
            setMyState={setMyState}
            url={url}
            docTitle={docTitle}
            clearId={setWatchedId}
            id={watchedId}
          />
        </Modal>
      )}
    </>
  );
};

export default HomeWork;

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};
