import styled from "styled-components";

export const CardBody = styled.ul`
  background: #ffffff;
  margin-top: 24px;
`;
export const MedicineList = styled.li`
  display: flex;
  margin-bottom: 21px;
`;

export const MedicineDosage = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #7d8592;
  margin-top: 4px;
`;
