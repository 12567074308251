import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { changeRemoveID } from "../../../StateManagement/Reducers/DashboardState";
import { GetCardDetails } from "../../../StateManagement/Reducers/BillingState";
import RemoveCard from "../../StyledComponents/RemoveCard";
import AddCard from "../../StyledComponents/AddCard";
import CardDetail from "../../StyledComponents/CardDetails";
import Carousel, { CarouselItem } from "./Carousel/Carousel";

import ChipPin from "../../../Assets/images/Group.png";

import { PageSubTitle, Image, Button, Text } from "../../StyledComponents";
import {
  Div,
  Icon,
  CardContainer,
  CardNum,
  CardHoldTxt,
  HoldName,
  CardBrand,
  Curcle,
  InnerCurcle,
  Tooltip,
  TooltipText,
} from "../../Billing/MyCards/Styles";
import { Hrline } from "../NextAppointment/styles";
import { CardContent, ShadowCard, DivAddCard } from "./Styles";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import StripeAlert from "../../StyledComponents/StripeAlert";

const MyCards = () => {
  const dispatch = useDispatch();
  let accountType =
    localStorage.getItem("account_type") &&
    Decrypt_Value(localStorage.getItem("account_type"), "vozoportal");
  const [showValRm, setShowRm] = useState(false);
  const [AddShow, setAddShow] = useState(false);
  const [CardDetailShow, setCardDetailShow] = useState(false);
  const [CardData, setCardData] = useState({});

  const CardDetails = useSelector((state) => state.Billing.CardDetail);
  const [NumCard, setNumCard] = useState(CardDetails.length);
  const removeObject = useSelector((state) => state.Dashboard.removeObject);
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);

  const handleModalClick = () => {
    setModalAlerShow(false);
  };

  useEffect(() => {
    dispatch(GetCardDetails());
    setNumCard(CardDetails.length);
  }, [dispatch]);

  useEffect(() => {
    CardDetails.filter((x, i) => {
      return i === removeObject["buttonIndex"];
    }).forEach((x) => {
      dispatch(changeRemoveID({ name: "cardIndex", value: x.id }));
    });
  }, [removeObject["buttonIndex"], CardDetails, dispatch]);

  return (
    <>
      <CardContent>
        <PageSubTitle padding="12px 0 12px 16px">My Cards</PageSubTitle>

        {CardDetails.length !== 0 ? (
          <Carousel>
            {CardDetails.map((row, ind) => (
              <>
                <CarouselItem>
                  <CardContainer
                    cursor={accountType === -1 && "not-allowed"}
                    key={ind}
                    selected={ind}
                    marginRight="16px"
                    marginLeft="16px"
                    onClick={() => {
                      if (accountType !== -1) {
                        setCardDetailShow((prevState) => !prevState);
                        setCardData(row);
                      }
                    }}
                  >
                    <Div
                      margin="0px 0px 27px 0px"
                      display="flex"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Image loading="lazy" src={ChipPin} />
                      <CardBrand img={row.cardType} selected={ind} />
                    </Div>
                    <CardNum dashboard selected={ind}>
                      {row.number}
                    </CardNum>
                    <Div
                      display="flex"
                      margin="20px 0px 0px 0px"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Div justify="space-between" width="100px">
                        <CardHoldTxt selected={ind}>Card Holder</CardHoldTxt>
                        <HoldName
                          selected={ind}
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          overflow="hidden"
                        >
                          {row.name && row.name.length > 12 ? (
                            <Tooltip>
                              {row.name}
                              <TooltipText className="TooltipText">
                                {row.name}
                              </TooltipText>
                            </Tooltip>
                          ) : (
                            row.name
                          )}
                        </HoldName>
                      </Div>

                      <Div margin="0px 0px 0px 16px" zIndex="2">
                        <CardHoldTxt selected={ind}>Valid</CardHoldTxt>
                        <HoldName selected={ind}>{row.valid}</HoldName>
                      </Div>
                    </Div>

                    <Curcle selected={ind} dashboard />
                    <InnerCurcle selected={ind} dashboard />
                  </CardContainer>
                  {ind === 0 ? <ShadowCard /> : ""}
                </CarouselItem>
              </>
            ))}
            <CarouselItem>
              {CardDetails.length < 3 && (
                <DivAddCard
                  cursor={accountType === -1 && "not-allowed"}
                  onClick={() => {
                    if (accountType !== -1) {
                      if (stripeAllowed === false) {
                        setModalAlerShow(true);
                      } else {
                        setAddShow(true);
                      }
                    }
                  }}
                >
                  <Icon />
                  Add Card
                </DivAddCard>
              )}
            </CarouselItem>
          </Carousel>
        ) : (
          <DivAddCard
            cursor={accountType === -1 && "not-allowed"}
            nocard
            onClick={() => {
              if (accountType !== -1) {
                if (stripeAllowed === false) {
                  setModalAlerShow(true);
                } else {
                  setAddShow(true);
                }
              }
            }}
          >
            <Icon />
            Add Card
          </DivAddCard>
        )}
        <Hrline mt="12.5px" mb="21px" margin="12.5px 20px 21px 20px" />

        <Button
          marginLeft="auto"
          marginRight="auto"
          marginTop="-1.5px"
          background="none"
          display="flex"
          cursor={
            (CardDetails.length === 3 || accountType === -1) && "not-allowed"
          }
          onClick={() => {
            if (accountType !== -1) {
              if (stripeAllowed === false) {
                setModalAlerShow(true);
              } else {
                CardDetails.length !== 3 && setAddShow(true);
              }
            }
          }}
        >
          <Text
            fontSize="16px"
            clr="#407BFF"
            mt="0px"
            mb="0px"
            lineHeight="20px"
            color="#407BFF"
            fontWeight="500"
            letterSpacing="0.0025em"
            id="dbrd_adCrd-p_crdAdd"
          >
            Add Card
          </Text>
        </Button>
      </CardContent>

      <CardDetail
        show={CardDetailShow}
        HandleClose={() => setCardDetailShow(false)}
        row={CardData}
      />

      <AddCard
        show={AddShow}
        HandleModal={() => setAddShow(false)}
        numcard={NumCard}
      />

      <RemoveCard
        show={showValRm}
        ModalHide={() => setShowRm(false)}
        card_id={removeObject.cardIndex}
      />
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </>
  );
};

export default MyCards;
