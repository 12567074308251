import React from "react";
import { Div } from "./Styles";
import { Container } from "./StyledComponents";
import LoginRight from "./LoginRight";
import LoginLeft from "./LoginLeft";

const Login = () => {
  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" bg_color="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" bg_color="#FFFFFF">
        <LoginRight />
      </Div>
    </Container>
  );
};

export default Login;
