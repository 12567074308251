import React, { useState, useEffect } from "react";

import { Image } from "../StyledComponents";
import { CarouselContainer } from "./styles";

const SlideImage = ({ ImageUrl }) => {
  const [Image_1, setImage] = useState("");

  useEffect(() => {
    setImage(ImageUrl);
  }, [ImageUrl]);

  return (
    <>
      {Image_1 === ImageUrl && (
        <CarouselContainer>
          <Image loading="lazy" src={ImageUrl} />
        </CarouselContainer>
      )}
    </>
  );
};

export default SlideImage;
