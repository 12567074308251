import styled from "styled-components";
// import Modal from '../../StyledComponents'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`;

export const Label = styled.div`
  width: 40%;
  margin-right: 5%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
`;

export const Value = styled.div`
  width: 30%;
  margin-bottom: 10px;
  font-size: 14px;
  margin-right: 5%;
`;

export const Modal = styled.div`
  display: ${(p) => (p.show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.6);
  transition: all 0.5s;
`;

export const ModalInfo = styled(Modal)`
  width: 100%;
  height: 100%;
  display: ${(p) => (p.show ? "flex" : "none")};
`;

export const ModalDialog = styled.div`
  width: ${(p) => (p.width ? p.width : "544px")};
  min-height: ${(p) => p.mheight && p.mheight};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  transition: transform 3s;
  animation: ${(p) =>
    p.animattion ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;

export const Select = styled.select`
  padding: 4px 16px;
  /* background: #ecedef; */
  border-radius: 4px;
  /* border: none; */
  height: 32px;
  /* border: 1px solid rgba(46, 46, 46, 0.25); */
  outline: none;
  border: ${(p) => (p.border ? p.border : "1px solid rgba(46, 46, 46, 0.25)")};
`;

export const Option = styled.option``;

export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
`;
