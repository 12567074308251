import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const HomeWorkListApi = async (article) =>
  await axiosInst.get(
    `/vozoportal/homeworklist?id=${Pat_ID}&search=` + article
  );

export const UpdateWatchedApi = async (credential) =>
  await axiosInst.put(`/vozoportal/homework/watchedUpdate`, credential);
