import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: "login",
  initialState: {
    LoginRes: {},
    ImageInd: 0,
  },
  reducers: {
    getCredentials: (user) => {
      return user;
    },

    LoginPass: (state, action) => {
      state.LoginRes = action.payload;
    },

    SetImageInd: (state, { payload }) => {
      if (payload === 0) {
        state.ImageInd = 1;
      } else if (payload === 1) {
        state.ImageInd = 2;
      } else if (payload === 2) {
        state.ImageInd = 0;
      }
    },
  },
});

export const { LoginPass, getCredentials, SetImageInd } = LoginSlice.actions;

export default LoginSlice.reducer;
