import React, { useState } from "react";

import InvoiceBalance from "./InvoiceBalance";
import PaymentHistory from "./PaymentHistory";

import { PageSubTitle } from "../../StyledComponents";
import { Div } from "../Styles";
import { Container, TabPanel, Tabs } from "./styles";

const Group = () => {
  const [opt, setOpt] = useState(1);
  const [invoOpt, setInvOpt] = useState(true);
  const [payOpt, setPayOpt] = useState(false);

  const handleTabs = (e) => {
    if (e === 1) {
      setOpt(1);
      setInvOpt(true);
      setPayOpt(false);
    } else {
      setOpt(2);
      setPayOpt(true);
      setInvOpt(false);
    }
  };

  return (
    <>
      <Container>
        <PageSubTitle padding="20px">
          {opt === 1 ? "Invoice List" : "Payment History"}
        </PageSubTitle>
        <TabPanel>
          <Tabs active={invoOpt} onClick={() => handleTabs(1)}>
            Invoice Balance
          </Tabs>
          <Tabs
            active={payOpt}
            Line={payOpt}
            onClick={() => handleTabs(2)}
            id="bil_invLs-pyHis"
          >
            Payment History
          </Tabs>
        </TabPanel>
        <Div marginTop="25px">
          {opt === 1 ? <InvoiceBalance /> : <PaymentHistory />}
        </Div>
      </Container>
    </>
  );
};

export default Group;
