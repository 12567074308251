import React, { useEffect } from "react";
import InsuranceCard from "./InsuranceCard";
import { Div, NormalImage, Text } from "../../StyledComponents";
import {
  Displayerror,
  CurveImg,
  Display,
  DisplayerrorText,
} from "../EditProfile/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlueCurve from "../../../Assets/images/Ellipse_2.png";
import { useRef } from "react";
import { toast } from "react-toastify";
import Error from "../../StyledComponents/Toastify/Error";
import "react-image-crop/dist/ReactCrop.css";
import { DeviceResponse } from "../../../StateManagement/Reducers/InsuranceState";
import Success from "../../StyledComponents/Toastify/Success";
import { Getprofileupdatestatus } from "../../../StateManagement/Reducers/SettingState";

const Insurance = () => {
  //--------------------------State and  Variables Start---------------------------- //

  const [ShowOptions3, setShowOptions3] = useState(false);

  const [disabledsave, setdisabledsave] = useState(false);
  const [patimg, setpatimg] = useState([]);

  const [fname, setfname] = useState("");
  const [lname, setlname] = useState([]);
  const [UpdateStatus, setupdateprofilestatus] = useState(0);
  const [revertReason, setRevertReason] = useState("");
  const dispatch = useDispatch();
  const File_URL =
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documentimg/";
  const addDeviceResponse = useSelector((state) => state.Insurance?.DeviceRes);
  const vitalsRef = useRef(null);
  const PatDetails = useSelector((state) => state.TopNavbar.userDetails);

  //-----------------------------State and  Variables End----------------------------- //
  //-----------------------------Function Start--------------------------------------- //
  const updateprofilestatustimeout = (alertData) => {
    if (alertData !== "0" || alertData !== 0) {
      setupdateprofilestatus(0);
      dispatch(Getprofileupdatestatus("insurance"));
    }
  };
  //-----------------------------Function End----------------------------------- //

  //-----------------------------UseEffect Start--------------------------------- //
  useEffect(() => {
    setpatimg(PatDetails.img_url);
    setfname(PatDetails.fname);
    setlname(PatDetails.lname);
    setupdateprofilestatus(PatDetails.update_insurance_status);
    setRevertReason(PatDetails.insurance_revert_reason);

    if (
      PatDetails.update_insurance_status !== "0" &&
      PatDetails.update_insurance_status !== 0
    ) {
      const timeoutId = setTimeout(() => {
        updateprofilestatustimeout(PatDetails.update_insurance_status);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [PatDetails]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (vitalsRef.current && !vitalsRef.current.contains(event.target)) {
        if (ShowOptions3 === true) {
          setShowOptions3(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ShowOptions3]);

  useEffect(() => {
    if (addDeviceResponse === "Details added sucessfully") {
      setdisabledsave(false);
      toast(<Success msg="Details added sucessfully" />, {
        containerId: "A",
        className: "success_badge",
      });
      dispatch(DeviceResponse(""));
    }

    if (addDeviceResponse !== "") {
      setdisabledsave(false);
      if (addDeviceResponse === "Unable to add Details") {
        toast(<Error msg="Unable to add Details" />, {
          containerId: "A",
          className: "error_badge",
        });
        dispatch(DeviceResponse(""));
      }
    }
  }, [addDeviceResponse]);

  //-----------------------------UseEffect End---------------------------- //

  return (
    <>
      <Display display="flex" alignItems="center" mb="24px" mt="30px" ml="30px">
        {PatDetails.img_url === null ? (
          <Div
            border="1.5px solid #e9eef9"
            position="relative"
            height="70px"
            width="70px"
            minWidth="70px"
            minHeight="70px"
            borderRadius="50%"
            marginRight="20px"
            padding="4px"
          >
            <CurveImg
              position="absolute"
              top="-0.5px"
              left="38px"
              width="30px"
              height="30px"
              src={BlueCurve}
            />
            <Div
              background="#A8AEBE"
              height="100%"
              width="100%"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#FFFFFF"
              fontSize="24px"
              fontWeight="600"
            >
              {PatDetails.initials}
            </Div>
          </Div>
        ) : (
          <Div
            border="1.5px solid #e9eef9"
            position="relative"
            height="70px"
            width="70px"
            minWidth="70px"
            minHeight="70px"
            borderRadius="50%"
            marginRight="20px"
            padding="4px"
          >
            <CurveImg
              position="absolute"
              top="-0.5px"
              left="38px"
              width="30px"
              height="30px"
              src={BlueCurve}
            />
            <NormalImage
              loading="lazy"
              height="100%"
              width="100%"
              borderRadius="50%"
              src={File_URL + patimg}
            />
          </Div>
        )}
        <Display display="inline">
          <Text
            fontSize="24px"
            fontWeight="700"
            color="#000000"
            mb="12px"
            lineHeight="33px"
            textAlign="left"
          >
            {fname} {lname}
          </Text>
          <Text
            fontFamily="Inter"
            fontWeight="400"
            fontSize="14px"
            color="#000000"
            lineHeight="23px"
            textAlign="left"
            mb="0px"
          >
            Set up your Patient Portal
          </Text>
        </Display>
      </Display>

      {UpdateStatus === "1" || UpdateStatus === 1 ? (
        <Displayerror
          display="flex"
          background="#77E0B5"
          width="100%"
          alignItems="center"
          pt="10px"
          pb="10px"
          justifyContent="center"
          color="#FFFFFF"
          mb="24px"
        >
          Insurance Data Updated Successfully
        </Displayerror>
      ) : (
        <>
          {UpdateStatus === "2" || UpdateStatus === 2 ? (
            <Displayerror
              background="#FF7676"
              width="100%"
              alignItems="center"
              pt="10px"
              pb="10px"
              justifyContent="center"
              mb="24px"
            >
              <DisplayerrorText
                fontFamily="'Inter'"
                fontWeight="600"
                fontSize="16px"
                lineHeight="19px"
                textAlign="center"
                color="#ffffff"
              >
                Insurance Data Updated was Revert
              </DisplayerrorText>
              <DisplayerrorText
                fontFamily="Work Sans"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center"
                color="#ffffff"
                mt="4px"
              >
                {revertReason}
              </DisplayerrorText>
            </Displayerror>
          ) : (
            <></>
          )}
        </>
      )}

      <InsuranceCard />
    </>
  );
};

export default Insurance;
