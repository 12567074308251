import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GetLoginStatus,
  GetPortalEnable,
} from "../StateManagement/Reducers/PreLoginState";

import Welcome from "../Modules/Login/Welcome";
import SignUp from "../Modules/Login/SignUp";
import SetupPassword from "../Modules/Login/SetupPassword";
import InstantSignIn from "../Modules/Login/InstantSignIn";
import VerifyAccount from "../Modules/Login/VerifyAccount";

import ResetPassword from "../Modules/Login/ResetPassword";
import ForgetPassword from "../Modules/Login/ForgetPassword";
import ResetVerify from "../Modules/Login/ResetVerify";

import Login from "../Modules/Login";
import LeftNav from "../Modules/LeftNav";
import DashBoard from "../Modules/DashBoard";
import Appointment from "../Modules/Appointment";
import Billing from "../Modules/Billing";
import Document from "../Modules/Document";
import DocumentViewEdit from "../Modules/Document/ViewEdit";
import HealthProfile from "../Modules/HealthProfile";
import HomeWork from "../Modules/HomeWork";
import Messenger from "../Modules/Message";
import Profile from "../Modules/Profile";
import AccessDenied from "../Modules/Login/AccessDenied";
import useAutoLogout from "./useAutoLogout";
import { MainContainer, MainContainerLeft, MainContainerRight } from "./styles";
import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";
import Settings from "../Modules/Settings";
import ChangePassword from "../Modules/Settings/ChangePassword";
import FeedBack from "../Modules/Settings/Feedback";
import HelpSupport from "../Modules/HelpSupport";
import DeleteAccount from "../Modules/Settings/DeleteAccount";
const Router = () => {
  let idle =
    localStorage.getItem("idle") &&
    Decrypt_Value(localStorage.getItem("idle"), "vozoportal");
  const idleinMin = parseInt(idle) / 60;

  let timer = useAutoLogout(parseInt(idleinMin));

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  const accountType = localStorage.getItem("account_type")
    ? Decrypt_Value(localStorage.getItem("account_type"), "vozoportal")
    : true;

  const trial = localStorage.getItem("trial_period")
    ? Decrypt_Value(localStorage.getItem("trial_period"), "vozoportal")
    : true;

  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");
  const PortalEnable = useSelector((state) => state.PreLogin.PortalEnable);
  const ClientStatus = useSelector((state) => state.PreLogin.Client);
  const CurrTime = moment().format("YYYY-MM-DD HH:mm A");
  const token_exp_time = moment(
    localStorage.getItem("token_exp")
      ? Decrypt_Value(localStorage.getItem("token_exp"), "vozoportal")
      : ""
  ).format("YYYY-MM-DD HH:mm A");

  if (timer === 0) {
    localStorage.clear();
    localStorage.setItem("timeout", true);
    window.location.href = "/";
  } else {
    if (token_exp_time < CurrTime) {
      localStorage.clear();
      localStorage.setItem("timeout", true);
      window.location.href = "/";
    }
  }

  const checkSubscription = (type) => {
    if (accountType.length === 0 || trial.length === 0) {
      if (type === "allowed") {
        return true;
      } else if (type === "denied") {
        return false;
      }
    } else if (type === "allowed") {
      if (accountType === -1 || trial) {
        return true;
      } else {
        return false;
      }
    } else if (type === "denied") {
      if (accountType !== -1 && !trial) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    dispatch(GetPortalEnable(token));
  }, [dispatch]);

  useEffect(() => {
    if (Pat_ID > 0) {
      dispatch(GetLoginStatus({ pid: Pat_ID, uid: 0, type: "login" }));
    }
  }, []);
  if (ClientStatus === "fail")
    return (
      <Routes>
        <Route exact path="/" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  else if (!Pat_ID && PortalEnable === 1 && checkSubscription("allowed"))
    return (
      <>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/SetupPassword" element={<SetupPassword />} />
          <Route
            exact
            path="/InstantSignIn/:OldOrNew"
            element={<InstantSignIn />}
          />
          <Route exact path="/VerifyAccount" element={<VerifyAccount />} />
          <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
          <Route exact path="/ReVerifyAccount" element={<ResetVerify />} />
          <Route exact path="/ReSetupPassword" element={<ResetPassword />} />
          <Route exact path="/AccessDenied" element={<AccessDenied />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          autoClose={3000}
        />
      </>
    );
  else if (
    (Pat_ID === null && PortalEnable === 0) ||
    checkSubscription("denied")
  )
    return (
      <Routes>
        <Route exact path="/" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  else if (Pat_ID && PortalEnable === 1 && checkSubscription("allowed"))
    return (
      <>
        <MainContainer>
          <MainContainerLeft>
            <LeftNav />
          </MainContainerLeft>
          <MainContainerRight>
            <Routes>
              <Route exact path="/dashboard" element={<DashBoard />} />
              <Route exact path="/" element={<DashBoard />} />
              <Route
                exact
                path="/appointment/:selector"
                element={<Appointment />}
              />
              <Route exact path="/billing" element={<Billing />} />
              <Route exact path="/documents" element={<Document />} />
              <Route
                exact
                path="/documentsEdit"
                element={<DocumentViewEdit />}
              />
              <Route exact path="/healthProfile" element={<HealthProfile />} />
              <Route exact path="/homework" element={<HomeWork />} />
              <Route exact path="/messenger" element={<Messenger />} />
              <Route exact path="/profile/editProfile" element={<Profile />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/helpandsupport" element={<HelpSupport />} />
              <Route
                exact
                path="/settings/changepassword"
                element={<ChangePassword />}
              />
              <Route
                exact
                path="/settings/deleteaccount"
                element={<DeleteAccount />}
              />
              <Route exact path="/settings/feedback" element={<FeedBack />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <ToastContainer
              enableMultiContainer
              containerId={"A"}
              autoClose={2000}
            />
            <ToastContainer
              enableMultiContainer
              containerId={"B"}
              autoClose={3000}
            />
          </MainContainerRight>
        </MainContainer>
      </>
    );
};

export default Router;
