import styled from "styled-components";
import { Image } from "../../StyledComponents";

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  grid-area: Group;
`;

export const TabPanel = styled.div`
  width: 100%;
  height: 37px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #d5ddec;
  display: flex;
  justify-content: flex-start;
`;

export const Tabs = styled.div`
  width: 52px;
  height: 16px;
  font-family: ${(props) => (props.active ? "manrope" : "urbanist")}, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 36px;
  margin-top: 11px;
  margin-bottom: 7px;
  color: ${(props) => (props.active ? " #407bff" : "#8D98AF")};
  cursor: pointer;
  ${(props) => {
    if (props.line === "1") {
      return `
              &::after {
                        content: "";
                        width: 20px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(18px);
                        transition: transform 1s;
                         }
               `;
    }

    if (props.line === "2") {
      return `
              &::after {
                        content: "";
                        width: 20px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(99px);
                        transition: transform 1s;
                         }
               `;
    }
    if (props.line === "3") {
      return `
              &::after {
                        content: "";
                        width: 20px;
                        height: 2px;
                        background: #407bff;
                        display:  block;
                        margin-top: 8px;
                        transform : translateX(177px);
                        transition: transform 1s;
                         }
               `;
    }
  }}
`;

export const AvatarFrame = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #d8e0f0;
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #a8aebe;
  display: ${(p) => (p.display ? p.display : "inline-block")};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  margin-right: 12px;
`;

export const AvatarLetter = styled.span`
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

export const AvatarImage = styled(Image)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  margin-right: ${(p) => (p.marginRight ? p.marginRight : "12px")};
`;
