import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import Success from "../../Modules/StyledComponents/Toastify/Success";
import Error from "../../Modules/StyledComponents/Toastify/Error";

export const SettingSlice = createSlice({
  name: "Setting",
  initialState: {
    Loading: false,
    PatGenderDetails: [],
    PatMariDetails: [],
    PatLangDetails: [],
    PatRaceDetails: [],
    MailCheck: [],
    profileupdatestatus: "",
    Refresh: "",
    RefreshFeedback: "",
    PatientState: [],
    ResetPassword: "",
    CheckPortalPassword: "",
    DeletionResponse: "",
  },
  reducers: {
    GetPatGenderDetails: (state) => {
      return state;
    },
    SetPatGenderDetails: (state, action) => {
      state.PatGenderDetails = action.payload;
      state.Loading = false;
      state.Refresh = "";
    },

    GetPatMaritalStatus: (state) => {
      return state;
    },
    SetPatMaritalStatus: (state, action) => {
      state.PatMariDetails = action.payload;
      state.Loading = false;
      state.Refresh = "";
    },

    GetPatLangDetails: (state) => {
      state.Loading = true;
    },
    SetPatLangDetails: (state, action) => {
      state.PatLangDetails = action.payload;
      state.Loading = false;
      state.Refresh = "";
    },

    GetPatRaceDetails: (state) => {
      return state;
    },
    SetPatRaceDetails: (state, action) => {
      state.PatRaceDetails = action.payload;
      state.Loading = false;
      state.Refresh = "";
    },

    GetPatientProfileUpdate: (state) => {
      state.Refresh = "";
    },
    GetPatientProfileRemove: (state) => {
      state.Refresh = "";
    },

    GetPatientDetailUpdate: (state) => {
      state.Refresh = "";
    },

    GetEmailExist: (state) => {
      state.Refresh = "";
    },
    SetEmailExist: (state, action) => {
      state.MailCheck = action.payload;
    },
    Getprofileupdatestatus: (filename) => {
      return filename;
    },
    Setprofileupdatestatus: (state, action) => {
      state.profileupdatestatus = action.payload;
    },

    GetPatientState: (state) => {
      return state;
    },
    SetPatientState: (state, action) => {
      state.PatientState = action.payload;
      state.Loading = false;
    },
    GetFeedback: (state) => {
      state.RefreshFeedback = "";
    },

    Refresh: (state, action) => {
      if (isNaN(action.payload)) {
        toast(<Error msg={action.payload} />, {
          containerId: "B",
          className: "error_badge",
        });
      } else {
        let message = "";
        if (action.payload === 1)
          message = "Profile Picture Updated Successfully";
        if (action.payload === 2)
          message = "Profile Picture Deleted Successfully";

        if (action.payload === 3)
          message = "Details Sent to Review Successfully";

        toast(<Success msg={message} />, {
          containerId: "A",
          // progressClassName: "Progressbg",
        });
      }

      state.Refresh = action.payload;
    },

    GetResetPassword: (data) => {
      return data;
    },

    SetResetPassword: (state, action) => {
      state.ResetPassword = action.payload;
    },

    GetCheckPortalPassword: (data) => {
      return data;
    },

    SetCheckPortalPassword: (state, action) => {
      state.CheckPortalPassword = action.payload;
    },
    GetAccountDeletion: (data) => {
      return data;
    },

    SetDeletionResponse: (state, action) => {
      state.DeletionResponse = action.payload;
    },
    RefreshFeedback: (state, action) => {
      if (action.payload !== "") {
        if (isNaN(action.payload)) {
          toast(<Error msg={action.payload} />, {
            containerId: "B",
            className: "error_badge",
          });
        } else {
          let message = "";

          if (action.payload === 1) message = "Feedback sent successfully";

          toast(<Success msg={message} />, {
            containerId: "A",
          });
        }

        state.RefreshFeedback = action.payload;
      }
    },
  },
});

export const {
  GetPatGenderDetails,
  SetPatGenderDetails,
  GetPatMaritalStatus,
  SetPatMaritalStatus,
  GetPatLangDetails,
  SetPatLangDetails,
  GetPatRaceDetails,
  SetPatRaceDetails,
  GetPatientProfileUpdate,
  GetPatientProfileRemove,

  GetPatientDetailUpdate,

  GetEmailExist,
  SetEmailExist,

  Setprofileupdatestatus,
  Getprofileupdatestatus,

  Refresh,
  RefreshFeedback,
  GetPatientState,
  SetPatientState,
  GetFeedback,
  GetResetPassword,
  SetResetPassword,
  GetCheckPortalPassword,
  SetCheckPortalPassword,
  SetDeletionResponse,
  GetAccountDeletion,
} = SettingSlice.actions;
export default SettingSlice.reducer;
