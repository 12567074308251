import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetPatGenderDetails,
  GetPatMaritalStatus,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPatientState,
} from "../../StateManagement/Reducers/SettingState";

import TopNav from "../TopNav";
import EditProfile from "./EditProfile";
import Insurance from "./Insurance/Insurance";
import Loading from "../StyledComponents/Loading";

import { Div } from "../StyledComponents";
import { Container1, TabPanel, Tabs } from "./styles";

const Settings = () => {
  //--------------------------State and  Variables Start---------------------------- //

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [value, setValue] = useState("Personal");
  const [value1, setValue1] = useState(false);
  const Refresh = useSelector((state) => state.Setting.Refresh);
  const ProfileLoading = useSelector((state) => state.Setting.Loading);
  const TopLoading = useSelector((state) => state.TopNavbar.UserLoading);

  //-----------------------------State and  Variables End----------------------------- //

  //-----------------------------Function Start--------------------------------------- //
  const handleTabs = (tabIndex, value) => {
    setValue(value);
    setActiveTab(tabIndex);
    setValue1(true);
  };

  const tabComponent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Div>
            <EditProfile value={value} value1={value1} />
          </Div>
        );
      case 2:
        return (
          <Div>
            <Insurance
              value={value}
              value1={value1}
              setActiveTab={setActiveTab}
            />
          </Div>
        );

      default:
        return null;
    }
  };
  //-----------------------------Function End----------------------------------- //

  //-----------------------------UseEffect Start--------------------------------- //
  useEffect(() => {
    dispatch(GetPatGenderDetails());
    dispatch(GetPatMaritalStatus());
    dispatch(GetPatLangDetails());
    dispatch(GetPatRaceDetails());
    dispatch(GetPatientState());
  }, [dispatch, Refresh]);
  //-----------------------------UseEffect End---------------------------- //

  return (
    <>
      <TopNav title="Edit Profile" />
      {TopLoading && ProfileLoading ? (
        <Loading />
      ) : (
        // <BodyContainer paddingLeft="40px" paddingTop="13px">
        //   <EditProfile />
        // </BodyContainer>

        <Div background="#FFFFFF">
          <Container1>
            <TabPanel
              width="100%"
              background="#ffffff"
              borderRadius="8px 8px 0px 0"
              borderBottom="1px solid rgba(46, 46, 46, 0.25);"
              display="flex"
              justifyContent="flex-start"
              padding="30px 25px 0 0px"
            >
              <Tabs
                width="130px"
                fontWeight="600"
                fontFamily="'Open Sans'"
                lineHeight="2px"
                color="rgba(46, 46, 46, 0.5)"
                padding="0px 0px 0 24px"
                fontSize="16px"
                isActive={activeTab === 1}
                marginTop="8px"
                onClick={() => handleTabs(1, "myprofile")}
                line={activeTab}
              >
                My Profile
              </Tabs>
              <Tabs
                width="98px"
                fontWeight="600"
                fontFamily="'Open Sans'"
                lineHeight="2px"
                fontSize="16px"
                color="rgba(46, 46, 46, 0.5)"
                padding="0px 0px 0 0px"
                marginTop="8px"
                marginLeft="-10px"
                isActive={activeTab === 2}
                onClick={() => handleTabs(2, "insurance")}
              >
                Insurance
              </Tabs>
            </TabPanel>
            {tabComponent()}
          </Container1>
        </Div>
      )}
    </>
  );
};

export default Settings;
