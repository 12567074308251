import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  SetUpcomingAppointment,
  GetUpcomingAppointment,
  setProvidersList,
  setFacilityList,
  appointmentSaved,
  clearAppointmentData,
  setProviderAvailablity,
  SetPastAppt,
  SetCancelAppt,
  setPortalAppAvailablity,
  setOnsiteAppReq,
  setBillingFacility,
} from "../../StateManagement/Reducers/AppointmentsState";

import {
  upcomingAppointmentsApi,
  providersApi,
  facilityApi,
  addAppointmentApi,
  EditAppointmentApi,
  AvailablityApi,
  CancelAppointment_Api,
  PastAppointment_Api,
  PortalAppAvailablityApi,
  OnsiteAppReqApi,
  BillingFacilityApi,
} from "../Apis/AppointmentsApi";

const Delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* upcomingAppointments({ payload }) {
  try {
    const res = yield call(upcomingAppointmentsApi, payload);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetUpcomingAppointment(decrptedResponse));
    }
  } catch (e) {
    yield put(SetUpcomingAppointment([]));
    console.log(e.message);
  }
}

export function* CancelApptApi({ payload }) {
  const credential = payload;
  let encryptdata = Encrypt_Value(credential, "vozoportal");
  try {
    const res = yield call(CancelAppointment_Api, encryptdata);
    if (res) {
      yield put(SetCancelAppt());
      yield put(GetUpcomingAppointment());
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PastApptAPi() {
  try {
    const res = yield call(PastAppointment_Api);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(SetPastAppt(decrptedResponse));
    }
  } catch (e) {
    yield put(SetPastAppt([]));
    console.log(e.message);
  }
}

export function* providersWorker({ payload }) {
  let text = payload;
  let text_encrpt = Encrypt_Value(text, "vozo_portal");
  try {
    const res = yield call(providersApi, text_encrpt);

    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo_portal");
      yield put(setProvidersList(data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setProvidersList([]));
  }
}

export function* facilityWorker({ payload }) {
  let find = payload;
  let find_encrpt = Encrypt_Value(find, "vozo_portal");
  try {
    const res = yield call(facilityApi, find_encrpt);
    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo_portal");
      yield put(setFacilityList(data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setFacilityList([]));
  }
}

export function* AddAppointmentWorker({ payload }) {
  let data = payload;
  let data_encrpt = Encrypt_Value(data, "vozo_portal");

  try {
    const res = yield call(addAppointmentApi, data_encrpt);
    if (res.status === 200) {
      yield put(
        appointmentSaved({
          color: "success",
          message: "Appointment added successfully !",
        })
      );
      yield put(GetUpcomingAppointment());
      yield Delay(1500);
      yield put(clearAppointmentData("/appointment/past_appointment"));
    }
  } catch (e) {
    console.log(e.message);
    yield put(
      appointmentSaved({
        color: "danger",
        message: "Failed to add Appointment",
      })
    );
    yield Delay(1000);
    yield put(clearAppointmentData("/appointment/past_appointment"));
  }
}

export function* EditAppointmentWorker({ payload }) {
  let data = payload;
  let id = data.eid;
  let data_encrpt = Encrypt_Value(data, "vozo_portal");

  try {
    let res = yield call(EditAppointmentApi, { article: data_encrpt, eid: id });
    if (res.status === 200) {
      yield put(
        appointmentSaved({
          color: "success",
          message: "Appointment edited successfully !",
        })
      );
      yield put(GetUpcomingAppointment());
      yield Delay(1000);
      yield put(clearAppointmentData("/appointment/past_appointment"));
    }
  } catch (e) {
    console.log(e.message);
    yield put(
      appointmentSaved({
        color: "danger",
        message: "Failed to edit Appointment",
      })
    );
    yield Delay(1000);
    yield put(clearAppointmentData("/appointment/past_appointment"));
  }
}

export function* SetAvailablityWorker({ payload }) {
  let encrpyted = Encrypt_Value(payload, "vozo_portal");
  try {
    let res = yield call(AvailablityApi, encrpyted);
    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo_portal");
      yield put(setProviderAvailablity(data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SetPortalAppAvailablityWorker({ payload }) {
  let encrpyted = Encrypt_Value(payload, "vozo_portal");
  try {
    let res = yield call(PortalAppAvailablityApi, encrpyted);
    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo_portal");
      yield put(setPortalAppAvailablity(data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SetOnsiteAppReqWorker({ payload }) {
  let encrpyted = Encrypt_Value(payload, "vozo_portal");
  try {
    let res = yield call(OnsiteAppReqApi, encrpyted);
    if (res.status === 200) {
      let data = Decrypt_Value(res.data, "vozo_portal");
      yield put(setOnsiteAppReq(data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* BillingFacilityWorker() {
  try {
    const res = yield call(BillingFacilityApi);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse === false) {
        yield put(setBillingFacility(null));
      } else {
        yield put(setBillingFacility(decryptResponse.id));
      }
    }
  } catch (e) {
    yield put(setBillingFacility(null));
    console.log(e.message);
  }
}
