import styled from "styled-components";
import { Image } from "../index";

export const LoaderContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: ${(p) => (p.height ? p.height : "89vh")};
  position: relative;
`;

export const LoaderSubContainer = styled.div`
  width: 4rem;
  height: 4rem;
  background: #cbd8fb;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;
  -webkit-animation: spin2 1s linear infinite;
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = styled.div`
  ${({ one, two, three, blocker }) => {
    if (one || two || three) {
      return `width: 50%;
   height: 50%;
   z-index: -1;`;
    } else if (blocker) {
      return `width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;  
  background: white;  
  border-radius: 50%;`;
    }
  }}

  ${({ one }) =>
    one &&
    `
  top: 50%;
left: 0;
background: -webkit-linear-gradient(
  top left,
  rgba(85, 127, 245, 1) 0%,
  rgb(85, 127, 245) 35%
);
background: linear-gradient(
  to bottom right,
  rgba(85, 127, 245, 1) 0%,
  rgb(85, 127, 245) 35%
);
  `}
${({ two }) =>
    two &&
    `
  top: 50%;
  left: 50%;
  background: -webkit-linear-gradient(
    to top right,
    rgb(85, 127, 245) 15%,
    rgb(85 127 245 / 35%) 100%
  );
  background: linear-gradient(
    to top right,
    rgb(85, 127, 245) 15%,
    rgb(85 127 245 / 35%) 100%
  );
  `}
${({ three }) =>
    three &&
    `
  top: 0;
  left: 50%;
  background: -webkit-linear-gradient(
    to bottom right,
    rgb(85 127 245 / 0%) 0%,
    rgb(110 135 245 / 42%) 100%
  );

  background: linear-gradient(
    to bottom right,
    rgb(85 127 245 / 0%) 0%,
    rgb(110 135 245 / 42%) 100%
  );
  `}
  position: absolute;
`;

export const LoaderImage = styled(Image)`
  width: 25px;
  height: 25px;
  display: flex;
  z-index: 1;
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
`;
