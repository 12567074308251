import React from "react";
import { Div, NormalImage } from "../../StyledComponents";

const NoDataImage = ({
  image,
  height,
  width,
  imageHeight,
  imageWidth,
  margin,
}) => {
  let h = height !== undefined ? height : "100%";
  let w = width !== undefined ? width : "100%";
  let m = margin !== undefined ? margin : "20px";

  return (
    <Div height={h} width={w} flexCenterAll margin={m}>
      <NormalImage
        loading="lazy"
        src={image}
        height={imageHeight}
        width={imageWidth}
      />
    </Div>
  );
};

export default NoDataImage;
