import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  align-items: center;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  color: #000000;
  margin-bottom: 14px;
`;
export const SubTitle = styled.h4`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  color: #667085;
  margin-bottom: 32px;
`;

export const FormGroupInline = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 1200px) {
    display: unset;
  }
`;
