import styled from "styled-components";
import visa from "../../../Assets/images/visaindex0.png";
import master from "../../../Assets/images/masterindex0.png";
import discover from "../../../Assets/images/discoverindex0.png";
import diners from "../../../Assets/images/dinersindex0.png";
import amex from "../../../Assets/images/amexindex0.png";
import visa1 from "../../../Assets/images/visaindex1.png";
import master1 from "../../../Assets/images/masterindex1.png";
import discover1 from "../../../Assets/images/discoverindex1.png";
import diners1 from "../../../Assets/images/dinersindex1.png";
import amex1 from "../../../Assets/images/amexindex1.png";

import AddCardIcon from "../../../Assets/images/AddCardIcon.png";

const backgroundUrl = (cardType, i) => {
  let cardType1 = cardType.replace(/ /g, "").toLowerCase();
  switch (cardType1) {
    case "visa":
      if (i !== 0) {
        return visa;
      } else {
        return visa1;
      }
      break;
    case "americanexpress":
    case "americanexpresscorporate":
      if (i !== 0) {
        return amex;
      } else {
        return amex1;
      }
      break;
    case "mastercard":
    case "mastercard2":
      if (i !== 0) {
        return master;
      } else {
        return master1;
      }
      break;
    case "discover":
    case "disco2":
    case "disco3":
      if (i !== 0) {
        return discover;
      } else {
        return discover1;
      }
      break;
    case "dinersclub":
      if (i !== 0) {
        return diners;
      } else {
        return diners1;
      }
      break;
    default:
      return "none";
  }
};

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.direction && p.direction};
  justify-content: ${(p) => p.justify && p.justify};
  margin: ${(p) => p.margin && p.margin};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  background: ${(p) => p.backgroudColor && p.backgroudColor};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  cursor: ${(p) => p.cursor && p.cursor};
  z-index: ${(p) => p.zIndex && p.zIndex};
`;

export const Card = styled.div`
  width: 320px;
  height: 194px;
  background: #ffffff;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: #363636;
  cursor: pointer;
`;

export const Icon = styled.img.attrs(() => ({
  src: AddCardIcon,
}))`
  width: max-content;
  height: max-content;
  object-fit: cover;
  margin-bottom: 10.5px;
`;

export const CardGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const CardContainer = styled.div`
  background: ${(props) =>
    props.selected
      ? "#FFFFFF"
      : "linear-gradient(104deg, #194bfb 6.07%, #3a49f9 93.22%)"};
  border-radius: 8px;
  margin-right: ${(p) => (p.marginRight ? p.marginRight : "24px")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  width: ${(p) => (p.width ? p.width : "320px")};
  border: ${(props) => props.selected && "1px solid #3f8cff"};
  padding: ${(p) => (p.padding ? p.padding : "20px 16px")};
  position: relative;
  overflow: hidden;
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
`;

export const CardNum = styled.p`
  line-height: 19px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  color: ${(props) => (props.selected !== 0 ? "#2D3748" : "#FFFFFF")};
  z-index: 1;
  ${(p) =>
    p.dashboard &&
    `
    @media only screen and (min-width: 1250px) and (max-width: 1432px) {
      z-index: 100;
      position: relative;
      }
      
    `}
`;

export const CardHoldTxt = styled.span`
  font-weight: 500;
  line-height: 16px;
  font-size: 12px;
  color: ${(props) => (props.selected !== 0 ? "#2D3748" : "#FFFFFF")};
`;

export const HoldName = styled.div`
  width: ${(p) => p.width && p.width};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
  margin-top: 8px;
  color: ${(props) => (props.selected ? "#2D3748" : "#ffff")};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const CardBrand = styled.img.attrs((props) => ({
  src: backgroundUrl(props.img, props.selected),
}))`
  width: max-content;
  height: max-content;
  object-fit: cover;
  margin-left: 16px;
  z-index: 2;
`;

export const Curcle = styled.div`
  width: 275.72px;
  aspect-ratio: 1;
  position: absolute;
  display: inline-block;
  inset: 0;
  top: -42px;
  left: 205px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(96.77deg, #9bbaff -58.89%, #ffffff 40.03%)"
      : "linear-gradient(96.77deg, #FFFFFF -58.89%, rgba(255, 255, 255, 0) 40.03%)"};
  border-radius: 50%;
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - 29px),
    #000 calc(100% - 28px)
  );
  ${(p) =>
    p.dashboard &&
    `
    @media only screen and (min-width: 1250px) and (max-width: 1432px) {
      left: 58%;
      z-index:1;
      }
      
    `}
`;

export const InnerCurcle = styled.div`
  content: "";
  width: 158.99px;
  aspect-ratio: 1;
  position: absolute;
  inset: 0;
  display: block;
  top: 16px;
  left: 258px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(96.77deg, #9bbaff -58.89%, #ffffff 40.03%)"
      : "linear-gradient(96.77deg, #FFFFFF -58.89%, rgba(255, 255, 255, 0) 40.03%)"};
  border-radius: 50%;
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - 29px),
    #000 calc(100% - 28px)
  );
  ${(p) =>
    p.dashboard &&
    `
    @media only screen and (min-width: 1250px) and (max-width: 1432px) {
      left: 76%;
      }
      
    `}
`;
export const Tooltip = styled.span`
  cursor: pointer;
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  &:hover .TooltipText {
    opacity: 1;
    visibility: visible;
  }
`;
export const TooltipText = styled.span`
  position: absolute;
  left: 5%;
  bottom: 10px;
  word-break: break-word;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 1002;
  background-color: #f5f5f5;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: rgb(45, 55, 72);
`;
