import { call, put } from "redux-saga/effects";
import {
  ChangedStatus_Api,
  DeleteInsurance_Api,
  Relation_Api,
  getInsuranceApi,
  getPayerName_Api,
  getSingleInsuranceDetail_Api,
  postInsuranceApi,
} from "../Apis/InsuranceApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import {
  DeviceResponse,
  SetChangedStatus,
  SetDeleteInsurance,
  SetInsuranceDetail,
  SetInsuranceName,
  SetRelationTypes,
  SetSingleInsurance,
  // SetSingleInsDetail,
} from "../../StateManagement/Reducers/InsuranceState";

export function* GetAllInsuranceDetails({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozoportal");
  try {
    const res = yield call(getInsuranceApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozoportal");
      yield put(SetInsuranceDetail(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetInsuranceDetail([]));
  }
}

export function* postInsurancedata({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozoportal");
  try {
    const res = yield call(postInsuranceApi, ecrypted);
    if (res.status === 200) {
      const res1 = yield call(getInsuranceApi, ecrypted);

      if (res1.status === 200) {
        try {
          let Decrypted = Decrypt_Value(res1.data, "vozoportal");
          yield put(SetInsuranceDetail(Decrypted));
        } catch (e) {
          console.log(e.message);
        }
      }
      yield put(DeviceResponse(res.data));
    }
  } catch (e) {
    console.log(e.message);
    yield put(DeviceResponse("Unable to add Details"));
  }
}

export function* GetAllInsuranceName({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozo");
  try {
    const res = yield call(getPayerName_Api, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozo");
      yield put(SetInsuranceName(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetInsuranceName([]));
  }
}

export function* GetSingleInsurancedata({ payload }) {
  const credential = payload.type;
  try {
    const res = yield call(getSingleInsuranceDetail_Api, credential);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozoportal");
      yield put(SetSingleInsurance(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetSingleInsurance([]));
  }
}

export function* InsuranceChangedStatus({ payload }) {
  const credential = payload.type;
  try {
    const res = yield call(ChangedStatus_Api, credential);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozoportal");
      yield put(SetChangedStatus(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetChangedStatus([]));
  }
}

export function* DeleteResponseWork({ payload }) {
  const credential = payload.type;
  try {
    const res = yield call(DeleteInsurance_Api, credential);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozoportal");
      yield put(SetDeleteInsurance(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetDeleteInsurance([]));
  }
}

export function* Relationships() {
  try {
    const res = yield call(Relation_Api);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozo");
      yield put(SetRelationTypes(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetRelationTypes([]));
  }
}
