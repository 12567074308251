import styled from "styled-components";

export const Modal = styled.div`
position: fixed;
left: 0;
right: 0;
top: 0;
bottom: 0;
transition: all ease 10s;
animation: fade 0.5s linear;
background-color: rgb(203, 209, 217, 0.5);
display: flex;
align-items: center;
justify-content: center;
@keyframes fade {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
z-index: 1;
}
`;

export const OuterContainerbox = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 8px;
  margin: 32px 0px 0px 0px !important;
  grid-area: Bill;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop && props.marginTop};
  justify-content: space-between;
  width: 100%;
`;

export const PaymentsText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.color && props.color};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  position: relative;
  &::before {
    content: "";
    height: 8px;
    display: block;
    width: 8px;
    background: ${(props) => props.background && props.background};
    border-radius: 50%;
    position: absolute;
    left: -12px;
    top: 6px;
  }
`;

export const PaymentBalanceData = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.fontSize && props.fontSize};
  line-height: 27px;
  color: #232934;
  margin-left: 2px;
`;

export const BorderData = styled.hr`
  margin: 13.5px 20px 21px 20px;
  width: auto;
  height: 0px;
  border: 1px solid #d8e0f0;
  border-bottom: none;
`;

export const ChartBox = styled.div`
  height: 138px;
  width: 138px;

  span {
    display: none;
  }
  overflow: hidden;
  border-radius: 50%;
  .CircularProgress {
    cursor: pointer;
    overflow: hidden;
  }
`;

export const ChartBox2 = styled.div`
  margin-top: -128px;
  margin-left: 10px;
  height: 118px;
  width: 118px;
  span {
    display: none;
  }
  overflow: hidden;
  border-radius: 50%;
  .CircularProgress {
    overflow: hidden;
    cursor: pointer;
  }
`;

export const PaymentSpan = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #407bff;
  cursor: pointer;
`;

export const ChartBoxspan = styled.div`
  display: ${(props) => props.display && props.display};
  font-weight: 700;
  position: relative;
  top: -70px;
  font-size: ${(props) => props.fontSize && props.fontSize};
  left: 23px;
  width: 95px;
  text-align: center;
`;

export const ModalDialog = styled.div`
  background: ${(props) => (props.color === true ? "#fc6a68" : "#b5f5c8")};
  height: 42px;
  border-radius: 23px;
  text-align: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  svg {
    margin-right: 10px;
  }
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;
