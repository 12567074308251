import React, { useState } from "react";
import { useSelector } from "react-redux";

import EmptyData from "../../StyledComponents/EmptyData";
import Pagination from "../../StyledComponents/Pagination";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  FlexJustCenter,
} from "../../StyledComponents";
import { Container, Avatar, AvatarLetter, AvatarImage, Div } from "./styles";
import { TitleLabel } from "../../HomeWork/Styles";

const Lists = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const ProfileLists = useSelector((state) => state.HealthProfile.List);

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentList = ProfileLists.slice(indexOfFirstRow, indexOfLastRow);
  const howManyPages = Math.ceil(ProfileLists.length / rowsPerPage);

  return (
    <Container minWidth="650px">
      <Table>
        <TableHead>
          <TableRow background="#F7F7F7">
            <TableHeadCell width="33.33%">{props.list}</TableHeadCell>
            <TableHeadCell width="33.33%">Date Diagnosed</TableHeadCell>
            <TableHeadCell width="33.33%">Provider</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentList.length !== 0 ? (
            <>
              {currentList.map((row, ind) => (
                <TableRow background="#FFFFF" key={ind}>
                  <TableBodyCell width="33.33%">
                    <FlexJustCenter>
                      <TitleLabel
                        fontFamily="'manrope',sans-serif"
                        fontSize="14px"
                        fontHeight="19.12px"
                        fontWeight="500"
                        color="#263446"
                        paddingTop="0px"
                        overflowWrap="break-word"
                        whiteSpace="unset"
                        className="problem_label"
                      >
                        {row.title}
                      </TitleLabel>
                    </FlexJustCenter>
                  </TableBodyCell>
                  <TableBodyCell width="33.33%">
                    {props.list === "Allergies" ? (
                      <> {row.dateonly ? row.dateonly : "-"} </>
                    ) : props.list === "Medication" ? (
                      <> {row.moddate ? row.moddate : "-"} </>
                    ) : (
                      <> {row.date ? row.date : "-"} </>
                    )}
                  </TableBodyCell>
                  <TableBodyCell width="33.33%" padding="16px 40px 16px 36px">
                    <Div
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {row.providerImage === null ? (
                        <Avatar>
                          <AvatarLetter>
                            {row.providerName.charAt(0).toUpperCase()}
                          </AvatarLetter>
                        </Avatar>
                      ) : (
                        <AvatarImage
                          src={File_URL + row.providerImage}
                          alt="image-failed"
                          loading="lazy"
                        />
                      )}

                      {row.providerName}
                    </Div>
                  </TableBodyCell>
                </TableRow>
              ))}
            </>
          ) : (
            <>
              <TableRow background="#FFFFF">
                <TableBodyCell colSpan={3} textAlign="center">
                  <EmptyData />
                </TableBodyCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      {ProfileLists.length > 10 && (
        <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
      )}
    </Container>
  );
};

export default Lists;
