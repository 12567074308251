import { takeLatest } from "redux-saga/effects";
import {
  GetPayBal,
  GetMedication,
  GetWelcome,
  GetLogin,
  GetLoginExist,
} from "../../StateManagement/Reducers/DashboardState";
import {
  PayBalApi,
  Medication,
  Welcome,
  Login,
  LoginExist,
} from "./DashboardSagaWorkers";

export function* PayBalSaga() {
  yield takeLatest(GetPayBal.type, PayBalApi);
}

export function* Medications() {
  yield takeLatest(GetMedication.type, Medication);
}

export function* WelcomePop() {
  yield takeLatest(GetWelcome.type, Welcome);
}

export function* FirstLogin() {
  yield takeLatest(GetLogin.type, Login);
}
export function* FirstUserLogin() {
  yield takeLatest(GetLoginExist.type, LoginExist);
}
