import styled from "styled-components";
import { BsCheckCircle } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";

export const BsCheck = styled(BsCheckCircle)`
  font-size: 25px;
  color: #155724;
`;

export const IoClose = styled(IoCloseSharp)`
  font-size: 25px;
  color: #b00020;
`;
export const RiError = styled(RiErrorWarningFill)`
  font-size: 25px;
  color: #b00020;
`;
