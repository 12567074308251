import React from "react";
import { Div, Image } from "../index";
import Empty_Image from "../../../Assets/images/Empty_Data.png";

const EmptyData = (props) => {
  return (
    <Div>
      <Image
        loading="lazy"
        height={props.width ? props.width : "235px"}
        width={props.width ? props.width : "235px"}
        src={Empty_Image}
        alt="No Data Found ..!"
      />
    </Div>
  );
};

export default EmptyData;
