import React, { useState, useEffect } from "react";

const AudioPreview = ({ audioBlob }) => {
  const [reblob, setReblob] = useState([]);

  useEffect(() => {
    setReblob(audioBlob);
  }, [audioBlob]);

  return (
    <div>
      {reblob.length > 0 && (
        <audio
          controls
          src={reblob && reblob[0]}
          style={{ height: "40px", width: "100%" ,marginTop:"4px"}}
        />
      )}
    </div>
  );
};

export default AudioPreview;
