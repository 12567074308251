import { call, put } from "redux-saga/effects";
import {
  SetGetMsgList,
  setMessageList,
  setPinchat,
  setPreviewDownload,
} from "../../StateManagement/Reducers/MessengerState";

import {
  MessageListApi,
  MessageSendApi,
  PinchatApi,
  PreviewDownloadApi_1,
  ProviderListApi,
  UnreadCountUpdateApi,
} from "../Apis/MessengerApi";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

const revId =
  localStorage.getItem("Prov_Id") &&
  Decrypt_Value(localStorage.getItem("Prov_Id"), "vozoportal");

export function* ProviderList() {
  try {
    const res = yield call(ProviderListApi);
    if (res.status === 200) {
      if (res.data === "no provider") {
        yield put(SetGetMsgList([]));
      } else {
        let Decrypted_data = Decrypt_Value(res.data, "vozoportal");
        yield put(SetGetMsgList(Decrypted_data));
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* MessageList({ payload }) {
  const credential = payload;
  let ecrypted = Encrypt_Value(credential, "vozo_portal");
  try {
    const res = yield call(MessageListApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozo_portal");
      yield put(setMessageList(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setMessageList([]));
  }
}

export function* MessageSend({ payload }) {
  const credential = payload;
  try {
    const res = yield call(MessageSendApi, credential);
    if (res.status === 200) {
      let dec = credential.get("message");
      let Decr = Decrypt_Value(dec, "vozo_portal");
      const getData = {
        Pid: Decr.sender_id,
        Provider_Id: Decr.receive_id,
      };
      let ecrypted = Encrypt_Value(getData, "vozo_portal");
      const resL = yield call(MessageListApi, ecrypted);
      if (resL.status === 200) {
        let Decrypted_data = Decrypt_Value(resL.data, "vozo_portal");
        yield put(setMessageList(Decrypted_data));
        const resP = yield call(ProviderListApi);
        if (resP.status === 200) {
          if (resP.data === "no provider") {
            yield put(SetGetMsgList([]));
          } else {
            let Decrypted_data = Decrypt_Value(resP.data, "vozoportal");
            yield put(SetGetMsgList(Decrypted_data));
          }
        }
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* PreviewDownloadApi({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozoportal");
  try {
    const res = yield call(PreviewDownloadApi_1, credential);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(setPreviewDownload(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* UnreadCountUpdate({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozoportal");
  try {
    const res = yield call(UnreadCountUpdateApi, credential);
    if (res.status === 200) {
      try {
        const res = yield call(ProviderListApi);
        if (res.status === 200) {
          if (res.data === "no provider") {
            yield put(SetGetMsgList([]));
          } else {
            let Decrypted_data = Decrypt_Value(res.data, "vozoportal");
            yield put(SetGetMsgList(Decrypted_data));
          }
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* Pinchatworker({ payload }) {
  const credential = payload;
  let encrypted = Encrypt_Value(credential, "vozoportal");
  try {
    const res = yield call(PinchatApi, encrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozoportal");
      yield put(setPinchat(Decrypted));
    }
  } catch (e) {
    console.log(e.message);
    yield put(setPinchat([]));
  }
}
