import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const PatGenderDetails_Api = async () =>
  await axiosInst.get(`/vozoportal/list?list_name=sex`);

export const PatMaritalStatus_Api = async () =>
  await axiosInst.get(`/vozoportal/list?list_name=marital`);

export const PatLangDetails_Api = async () =>
  await axiosInst.get(`/vozoportal/list?list_name=language`);

export const PatRaceDetails_Api = async () =>
  await axiosInst.get(`/vozoportal/list?list_name=ethrace`);

export const PatientProfileUpdate_Api = async (dataArray) =>
  await axiosInst.post(
    `vozoportal/patientlogo/update?pid=${Pat_ID}`,
    dataArray
  );

export const PatientProfileRemove_Api = async () =>
  await axiosInst.delete(`/vozoportal/patient/profileremove?pid=${Pat_ID}`);

export const PatientDetailUpdate_Api = async (payload) =>
  await axiosInst.put(
    `/vozoportal/patient/pendinginsert?pid=${Pat_ID}`,
    payload
  );

export const EmailExist_Api = async (data) =>
  await axiosInst.get(
    `/vozoportal/patientemailcheck?pid=${Pat_ID}&search=` + data
  );

export const ProfileUpdateStatus_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/vozoportal/UpdateProfileStatus?pid=${Pat_ID}`,
    Doc_Data
  );

export const State_Api = async () =>
  await axiosInst.get(`/vozoportal/list?list_name=state`);

export const Feedback_Api = async (data) =>
  await axiosInst.post(`/vozoportal/feedback`, data);
export const Password_Api = async (data) =>
  await axiosInst.post(`/vozoportal/patient/ResetPassword`, data);

export const CheckPortalPassword_Api = async (pid, curPass) =>
  await axiosInst.get(
    `/vozoportal/patient/CheckPortalPassword?curPass=${curPass}&pid=${pid}`
  );

export const PortalAccountDeletionApi = async (data) =>
  await axiosInst.post(`/vozoportal/patient/clientportalaccess/revoke`, data);
